export default {
  // 公共的key
  common: {
    unrealized: 'ยังไม่สมบูรณ์',
    all: 'ทั้งหมด',
    distributionTip: '0 ดอลลาร์สำหรับค่าส่ง 0',
    easeBack: 'ไม่ต้องห่วง',
    addCarSuccess: 'ประสบความสำเร็จในการเพิ่มรถเข็น',
    deleteConfirm: 'คุณแน่ใจ หรือว่า ต้องการลบรายการ ที่เลือกไว้?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: 'รายละเอียดสินค้า',
    shopping: 'กำลังเข้าไป',
    end: 'สิ้นสุดระยะทาง',
    sold: 'ขายแล้ว',
    goodsTips: 'รายการนี้ มีมูลค่า 500 กรัม/รายการ ที่มีมูลค่าต่ำกว่า 500 กรัม และราคา ที่แตกต่างกัน',
    quickTime: 'เร็ว ที่สุด ที่จะไปถึงใน 29 นาที',
    specifications: 'รายละเอียด',
    preservationConditions: 'บันทึกเงื่อนไข',
    coldStorage: 'แช่เย็น',
    shelfLife: 'วันหมดอายุ',
    day: '15 วัน',
    addToCar: 'เข้าร่วมซื้อรถเข็น'
  },
  //购物车
  car: {
    title: 'รถเข็น',
    delete: 'ลบ',
    tip: 'รถเข็น ที่ว่า งเปล่า~',
    walk: 'ไปปาร์ตี้กัน',
    all: 'การเลือก',
    guess: 'เดาว่า คุณคงชอบ'
  },
  // 首页
  home: {
    HallofFame: 'หอเกียรติยศ',
    chooseLocation: 'โปรดเลือกตำแหน่ง..',
    more: 'เพิ่มเติม',
    home: 'หน้าแรก',
    category: 'การเรียงลำดับ',
    eat: 'กินอะไร?',
    cart: 'รถเข็น',
    mine: 'ของฉัน',
    inputGoodsName: 'ป้อนชื่อรายการ',
    vipDesc: 'การเข้าร่วมสมาชิกคาดว่า จะประหยัดประมาณ 806 ดอลลาร์ต่อปี',
    vipSubDesc: 'ลด 50 %>',
    specialZone: 'พื้น ที่พิเศษ',
    foodie: 'วันกินของ',
    dinner: 'อาหารค่ำ',
    popularity: 'คะแนนนิยม',
    goodChoose: 'การเลือก',
    limitBuy: 'ถูก จำกัด',
    bottomTip: 'ฉันมีขีด จำกัด',
    chooseAddress: 'โปรดเลือก ที่อยู่',
    currentLocation: 'ตำแหน่งปัจจุบันของคุณ',
    nearbyAddress: 'ที่อยู่ใกล้ ๆ'
  },
  // 分类
  category: {
    tip: 'ป้อนชื่อรายการ',
    bottomTip: 'เอาล่ะมาดูประเภทอื่นกัน'
  },
  // 吃什么
  eat: {
    all: 'ทั้งหมด',
    close: 'ลาก่อน',
    pleaseHolder: 'ค้นหาสูตรอาหารส่วนผสม',
    menu: 'เมนูวันนี้',
    menuDevide: 'การเรียงลำดับเมนู',
    recent: 'เพิ่งหามา',
    tip_bottom: 'มันจบแล้วหยุดดึงได้แล้ว!',
    tip: 'ยังไม่มีการจัดเก็บคอลเลกชัน~'
  },
  // 我的
  mine: {
    title: 'ของฉัน',
    phone: 'หมายเลขโทรศัพท์',
    login: 'ล็อกอินทันที',
    myOrder: 'คำสั่งของฉัน',
    allOrder: 'ดูรายการ ที่สั่งทั้งหมด',
    myBill: 'คูปองของฉัน',
    myLocation: 'ที่อยู่การรับของฉัน',
    myCar: 'กรีนการ์ดของฉัน',
    servier: 'ติดต่อฝ่ายบริการลูกค้า',
    servierTime: 'เวลาการบริการลูกค้า 07.00-22 : 00 น.',
    feedback: 'ความคิดเห็น',
    switchLanguage: 'ภาษา',
    chinese: 'ภาษาจีน',
    english: 'ภาษาอังกฤษ',
    version: 'รุ่นปัจจุบัน',
    waitingPay: 'ค่าใช้จ่ายในภายหลัง',
    waitingReceive: 'กำลังรับของ',
    waitingComment: 'ที่จะให้คะแนน',
    waitingFeedback: 'หลังจากการขาย/คืนเงิน',
    unrealized: 'หน้าคืนเงินยังไม่เปิดเลย!',
    tip2: 'อย่าลืมสั่งดาวดวงเล็ก ๆ นะคะ❤️~',
    tip3: '💘ขอบคุณสำหรับความสนใจ💘',
    tip4: 'ค้นหาในคลับ \nGeek-James/ddBuy \n🦉ยินดีต้อนรับสู่ข้อเสนอการปรับ แต่ง🙉',
    feedbackTip: 'ตอนนี้ ยังไม่มีการสั่งซื้อ',
    cardPrise: 'ราคากรีนการ์ด',
    moreMessage: 'ยินดี ที่ได้รู้จักค่ะ...',
    openCard: 'เปิดกรีนการ์ด',
    recomend: 'กรีนการ์ดทั้งหมดอยู่ ที่ 6.66 ดอลลาร์สหรัฐ',
    cardTip1: 'บัตรประจำตัว',
    cardTip2: 'พิเศษเฉพาะ',
    cardTip3: 'อินทิกรัลสองเท่า',
    cardTip4: 'สิทธิพิเศษ',
    vipType: 'ลด 50 %',
    vipTime: 'ปีค.ศ. 365 วัน',
    vipRecommend: 'เท่ากับ 0.24 ดอลลาร์ต่อวัน',
    prise1: '¥88',
    prise2: '¥188',
    vipType1: 'ลด 70 %',
    vipTime1: 'การ์ดซีซั่น 90 วัน',
    vipRecommend1: 'ประมาณ 0.33 ดอลลาร์ต่อวัน',
    prise11: '¥30',
    prise12: '¥45',
    payMethod: 'วิธีการจ่ายเงิน',
    wechatPay: 'การชำระเงินผ่านทางจดหมาย',
    aliPay: 'และเงิน ที่จ่ายไป',
    huabeiPay: 'ก็จ่ายไปสิ',
    payImmeatally: 'จ่ายทันที',
    cardUeless: 'กรีนการ์ดยังไม่เปิด',
    tip: 'นั่นคือ ทั้งหมด ที่! ข้าทำไม่ได้หรอก~~',
    changeNickName: 'แก้ไขชื่อเล่น',
    confirm: 'บันทึก',
    nickName: 'โปรดตั้งชื่อเล่นใหม่',
    personal: 'แก้ไขข้อมูลส่วนบุคคลสำเร็จ!',
    message: 'ชื่อเล่นครับ',
    myBills: 'คูปองของฉัน',
    persent: 'กรุณากดเลข ที่ 520...',
    bill: 'คูปอง',
    condition: 'ไม่มีระดับการใช้งาน\nส่วนลดพิเศษ 1.5 ดอลลาร์',
    rmb: 'หยวน',
    myOrders: 'คำสั่งของฉัน',
    itemsTitle: ['ทั้งหมด', 'ค่าใช้จ่ายในภายหลัง', 'กำลังรับของ', 'ที่จะให้คะแนน'],

    personalInfo: 'ข้อมูลส่วนบุคคล',
    head: 'หัว',
    user_name: 'ชื่อเล่น',
    userSex: 'เพศ',
    Brithday: 'วันเกิด',
    phoneNumber: 'หมายเลขโทรศัพท์',
    noInput: 'ไม่ต้องกรอก',
    logout: 'ออกจากการล็อกอิน',
    infoTip: 'แก้ไขข้อมูลส่วนบุคคลสำเร็จ',
    loginInfo: 'แน่ใจนะว่า จะออกจากระบบ?',
    infoSuccess: 'ถอนตัว!',
    year: 'ปี',
    month: 'เดือน',
    day: 'วัน',
    man: 'รูปหล่อ',
    woman: 'คนสวย',

    greenCard: 'กรีนการ์ด',
    greenCardPower: 'สิทธิ์ของกรีนการ์ด',
    fiftypecert: 'ลด 50 % สำหรับกรีนการ์ด',
    cardRecoamnd: 'กรีนการ์ดจะได้คูปองส่วนลดทุกวัน',
    TodayExclusivecoupon: 'วันนี้ เอาไปให้หมดเลย',
    everyUpadate: 'ปรับปรุงทุกวันตอน 0 โมง',
    to: 'เติมเต็ม',
    toUse: 'ตั้งค่าโครงการ',
    immeatallyGet: 'รับทันที',
    weekBill: 'อาทิตย์นี้ จะได้คะแนน',
    cardTip7: 'รับทันที',
    billTip: 'กรีนการ์ดจะได้คูปองส่วนลดทุกวัน',
    currentGoods: 'การช้อปปิ้งปัจจุบันของคุณ',
    cardShop: 'เปิดกรีนการ์ดช้อปปิ้ง',
    cardBack: 'อินทิกรัลย้อนกลับเป็น',
    cardSpecialTip: 'เริ่มอินทิกรัลได้ในทันที',
    cardSpecial: 'กรีนการ์ดพิเศษพิเศษ',
    cardPriseb: '180 ดอลลาร์',
    cardPrisea: '88 ดอลลาร์',
    yearCard: 'การ์ดปี',
    openCards: 'เปิดกรีนการ์ด',
    onece: 'หนึ่งครั้ง',
    twice: 'สองเท่า'
  },
  // 订单
  order: {
    outTimeGetMoney: 'ได้รับการชดเชยมากกว่า 10 นาที',
    inputForm: 'เติมคำสั่ง',
    location: 'เลือก ที่อยู่ ที่จะรับ',
    arrivalTime: 'ถึงเวลา',
    total: 'เอาเลย',
    thing: 'บางอย่าง',
    wechatPay: 'การชำระเงินผ่านทางจดหมาย',
    aliPay: 'และเงิน ที่จ่ายไป',
    huabeiPay: 'ก็จ่ายไปสิ',
    use: 'ใช้',
    order12: 'อินทิกรัล',
    mark: 'หมายเหตุ',
    tip: 'การกรอกข้อมูลจะบอกเราได้ว่า คุณต้องการอะไรเป็นพิเศษ',
    goodsList: 'รายการสินค้า',

    totalMoney: 'จำนวนรายการ',

    sendMoney: 'ค่าจัดส่ง',
    point: 'อินทิกรัล',
    order19: 'จ่ายจริง',

    pay: 'จ่ายจริง',
    sendForm: 'ส่งคำสั่ง',

    selectArrivalTime: 'เลือกเวลาส่งมอบ',
    comfirm: 'แน่ใจ',
    goods: 'รายการ',
    all: 'เอาเลย',
    things: 'บางอย่าง',
    sigalPrice: 'ราคาเท่านั้น:',
    numbers: 'จำนวน:',
    addTip: 'ยังไม่มี ที่อยู่ให้เพิ่มเลยค่ะ😄',

    addLocation: 'เพิ่ม ที่อยู่',
    comfirmAndUse: 'บันทึก และใช้งาน',
    editLocation: 'แก้ไข ที่อยู่',
    myAddress: 'ที่อยู่ของฉัน',
    settlement: 'ตกลง',
    chooseGoods: 'กรุณาเลือกรายการ ที่ต้องการคำนวณ',
    deliveryTime: 'โปรดเลือกเวลาสำหรับการส่งมอบ',
    today: 'วันนี้',
    tomorrow: 'พรุ่งนี้'
  },
  Login: {
    indexTitle: 'ล็อกอิน',
    Daftar: {
      title: 'ล็อกอิน',
      phone: 'กรุณาใส่เบอร์มือถือของคุณ',
      code: 'โปรดป้อนรหัสการตรวจสอบ',
      password: 'โปรดป้อนรหัสผ่าน',
      againPassword: 'โปรดป้อนรหัสผ่านของคุณอีกครั้ง',
      remenber: 'จดจำรหัสผ่าน'
    },
    Forgot: {
      sendCode: 'ส่งแล้ว',
      confirm: 'ส่งข้อมูล',
      goBack: 'กลับ'
    },
    nameInput: 'กรุณาใส่เบอร์มือถือของคุณ',
    pwdInput: 'โปรดป้อนรหัสผ่าน',
    remenber: 'จดจำรหัสผ่าน',
    loginButton: 'ล็อกอิน',
    registrasi: 'ลงทะเบียน'
  },
  // 登录
  login: {
    title: 'ล็อกอิน',
    phoneNumber: 'หมายเลขโทรศัพท์',
    phoneNumberNotCorrect: 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง',
    phoneNumberNotEmpty: 'หมายเลขโทรศัพท์ไม่ว่า ง',
    passNumberNotEmpty: 'รหัสผ่านไม่สามารถว่า งได้',
    phoneInput: 'กรุณาพิมพ์หมายเลขโทรศัพท์ของคุณ',
    pass: 'รหัสผ่าน',
    passTip: 'โปรดป้อนรหัสผ่าน',
    varify: 'โปรดป้อนรหัสการตรวจสอบ',
    otherMethods: 'วิธีการล็อกอินอื่น ๆ',
    wechat: 'เข้าสู่ระบบอ่านจดหมาย',
    qqchant: 'คิวคิวเข้าสู่ระบบ',
    tipTile: 'คำแนะนำ ที่ดี：',
    tipContent: 'หมายเลขโทรศัพท์ ที่ไม่ได้ลงทะเบียนจะลงทะเบียนอัตโนมัติเมื่อเข้าสู่ระบบด้วยตัวแทนยินยอม',
    tip: 'โปรโตคอลผู้ใช้',
    tipProcy: 'กลยุทธ์ความเป็นส่วนตัว',
    login: 'ล็อกอิน',
    resgin: 'ลงทะเบียน',
    passTip2: 'โปรดป้อนรหัสผ่าน (ไม่น้อยกว่า 6 บิต)',
    sendVerify: 'ส่งรหัสการตรวจสอบ',
    hasSend: 'ส่งแล้ว',
    phoneVerify: 'หมายเลขบัญชีเข้าสู่ระบบ',
    smsMessage: 'เข้ารหัสการตรวจสอบข้อความ',
    switchLoginMsg: 'หมายเลขบัญชีเข้าสู่ระบบ',
    messageSuccess: 'ป้อนรหัสผ่านสำเร็จโปรดป้อนในกล่องใส่ข้อมูล',
    pleaseInputCorrectPhoneNumber: 'กรุณาใส่เลขโทรศัพท์ ที่ถูกต้อง',
    pleaseInputCorrectVerifyumber: 'โปรดป้อนรหัสการตรวจสอบ ที่ถูกต้อง',
    otherWechat: 'ล็อคอินเข้าสู่ระบบจดหมาย - ยังไม่เสร็จสมบูรณ์',
    otherQQ: 'คิว ที่ล็อกอิน - ยังไม่เสร็จสมบูรณ์',
    message: 'ตรวจสอบรหัสผ่าน:'
  },
  Hint: {
    notMore: 'ไม่มีอีกแล้ว',
    loading: 'กำลังโหลด',
    phoneNot: 'หมายเลขโทรศัพท์ หรือหมายเลขบัญชีไม่ว่า ง',
    SendCodeSuccess: 'ส่งรหัสยืนยันเรียบร้อยแล้ว!',
    pwdInconformity: 'สองรหัสผ่านไม่สอดคล้องกัน',
    notInput: 'ป้อนข้อมูล ที่ยังไม่สมบูรณ์',
    failed: 'ล้มเหลว'
  },
  newTab: {
    home: 'หน้าแรก',
    money: 'เติมเงิน',
    order: 'คำสั่ง',
    my: 'ของฉัน',
  },
  tab: {
    make_money: 'กำลังรับ'
  },
  Cart: {
    zzInfo: {
      buyIng: 'ในการซื้อ',
      buyFailed: 'การซื้อล้มเหลว',
      notLevel: 'คุณขาดคะแนนสมาชิก',
      buyJurisdiction1: 'หากจำเป็นต้องซื้อคำสั่งซื้อทองคำสำหรับสมาชิก',
      buyJurisdiction2: 'หากจําเป็นต้องซื้อคําสั่งแพลตตินัมของสมาชิก',
      buyJurisdiction3: 'หากจำเป็นต้องซื้อเพชร ที่สั่งซื้อ',
      invite1: 'เชิญเพื่อน 3 คนมาทำเงินด้วยกัน',
      invite4: 'เชิญเพื่อน 10 คนมาทำเงินด้วยกัน',
      invite3: 'เชิญเพื่อน 30 คน และทำเงิน',
      invite2: 'เชิญเพื่อน ๆ',
      myMoney: 'ยอดเงินในบัญชีของคุณ',
      under: 'ต่ำกว่า',
      notSendMoney: 'ไม่สามารถต่อรองได้',
      goTopUp: 'กรุณาดำเนินการกำลังเสริม',
      know: 'เข้าใจแล้ว',
      account: 'ตกลง'
    },
    hint: {
      p1: '1. ระดับสมาชิก ที่แตกต่างกันมีการสั่งซื้อ ที่แตกต่างกัน',
      p2: '2. ระดับสมาชิกสูงขึ้นค่านายหน้าสูงขึ้น',
      p3: '3. สำหรับการสั่งซื้อแล้วคุณสามารถตรวจสอบสถานะการสั่งซื้อได้ตลอดเวลา'
    },
    "title": "ร้านค้าออเดอร์",
    "explain": "คำอธิบาย",
    "grade": {
      "SliverMember": "สมาชิกเงินเขียว",
      "GoldMember": "สมาชิกทองคำ",
      "PlatinumMember": "สมาชิกแพลทินัม",
      "DiamondMember": "สมาชิกเพชร"
    },
    "info": {
      "allMoney": "สินทรัพย์รวมของฉัน",
      "dayAllOrder": "ออเดอร์ทั้งหมดในวันนี้",
      "dayTrustMoney": "ค่าคอมมิชชั่นในวันนี้",
      "yesterdayMoney": "รายได้เมื่อวานนี้"
    },
    "text": {
      "rebateAndPrincipal": "กำไรและเงินต้นที่คืน",
      "residue": "คงเหลือ",
      "one": "ออเดอร์",
      "rebate": "กำไรที่คืน",
      "buyBtn": "ซื้อเดี๋ยวนี้",
      "toGet": "กำลังจะได้รับ",
      "orderSuccess": "สั่งซื้อเรียบร้อยแล้ว",
      "orderGetMoney": "ชำระเงินเรียบร้อยแล้ว",
      "orderWaiting": "กำลังรอ",
      "orderOver": "เสร็จเรียบร้อยแล้ว"
    },
    "state": {
      "all": "ทั้งหมด",
      "going": "กำลังดำเนินการ",
      "paid": "จ่ายแล้ว",
      "frozen": "ถูกตรึง",
      "over": "เสร็จเรียบร้อยแล้ว"
    }
  },
  "My": {
    "download": "ดาวน์โหลดแอปพลิเคชัน",
    "personal": "ข้อมูลส่วนตัว",
    "preOrde": "บันทึกการสั่งจองล่วงหน้า",
    "account": "รายละเอียดบัญชี",
    "recharge": "บันทึกการเติมเงิน",
    "withdrawal": "ถอนเงิน",
    "invite": "เชิญเพื่อน",
    "announcement": "ประกาศ",
    "service": "บริการ",
    "team": "รายงานทีม",
    "quit": "ออกจากระบบ",
    "logoutEvent": "ยืนยันที่จะออกจากระบบหรือไม่?",
    "language": "ตั้งค่าภาษา",
    "languageButton": {
      "english": "ภาษาอังกฤษ",
      "indonesian": "ภาษาอินโดนีเซีย"
    },
    "logout": "ออกจากระบบ",
    "MemberInformation": {
      "SilverMember": "สมาชิกเงินเงิน",
      "Invitation": "รหัสพนักงาน",
      "balance": "ยอดเงินคงเหลือในบัญชี",
      "Recharge": "เติมเงิน",
      "Withdraw": "ถอนเงิน",
      "forzenAll": "เงินถูกล็อกทั้งหมด"
    },
    "PersonalInformation": {
      "Page": {
        "title": "ข้อมูลของฉัน",
        "Avatar": "อวตาร",
        "Nickname": "ชื่อเล่น",
        "PhoneNumber": "หมายเลขโทรศัพท์",
        "BankAccounts": "ที่อยู่กระเป๋าเงินของฉัน",
        "Password": "รหัสผ่าน",
        "TransactionPassword": "รหัสผ่านการทำธุรกรรม",
        "ReceivingAddress": "ที่อยู่ในการรับสินค้า"
      },
      "portrait": {
        "SetAvatar": "ตั้งรูปโปรไฟล์",
        "button": "ตั้งค่าเดี๋ยวนี้"
      },
      "Name": {
        "ModifyNickname": "แก้ไขชื่อเล่น",
        "title": "แก้ไขชื่อเล่นของคุณ",
        "name": "ชื่อเล่น",
        "Modify": "แก้ไข",
        "Please": "โปรดป้อนชื่อเล่น"
      },
      "BankAccounts": {
        "BankAccounts": "ผูกบัญชีธนาคาร",
        "YourInformation": "ข้อมูลของคุณ",
        "RealName": "ชื่อจริง",
        "RealNameNote": "โปรดตั้งชื่อจริงของคุณ",
        "PhoneNo": "หมายเลขโทรศัพท์",
        "PhoneNoNote": "โปรดตั้งหมายเลขโทรศัพท์",
        "YourBankCard": "ข้อมูลบัตรธนาคารของคุณ",
        "BankAccount": "บัญชีธนาคาร",
        "BankAccountNote": "โปรดตั้งบัญชีธนาคาร",
        "BankName": "ชื่อธนาคาร",
        "BankNameData": {
          "option1": "เลือกธนาคารบัญชี",
          "option2": "ธนาคารเซ็นทรัลอเชีย",
          "option3": "ธนาคารแห่งชาตินอกฟิลิปปินส์",
          "option4": "ธนาคารแห่งชาตินอกฟิลิปปินส์",
          "option5": "ธนาคารแห่งชาตินอกฟิลิปปินส์"
        },
        "BankBranch": "สาขาธนาคาร",
        "BankBranchNote": "โปรดตั้งสาขาธนาคาร",
        "MoreInformation": "ข้อมูลเพิ่มเติม",
        "WhatsApp": "WhatsApp",
        "WhatsAppNote": "โปรดตั้งหมายเลข WhatsApp",
        "Text": "คำเตือน: สวัสดีครับ เพื่อให้มั่นใจว่าคุณได้รับสิทธิ์ที่ถูกต้อง โปรดผูกหมายเลขบัตรและข้อมูลธนาคารที่ถูกต้อง หากบัญชีไม่สามารถรับได้เนื่องจากการกรอกไม่ถูกต้อง เว็บไซต์นี้ไม่รับผิดชอบใด ๆ!",
        "Settings": "การตั้งค่า"
      },
      "Password": {
        "title": "แก้ไขรหัสผ่านการเข้าสู่ระบบ",
        "PleaseEnter": "โปรดป้อนรหัสผ่านใหม่ของคุณ",
        "phone": "โทรศัพท์",
        "NewPhone": "โปรดป้อนหมายเลขโทรศัพท์มือถือของคุณ",
        "verificationCode": "รหัสยืนยัน",
        "verificationCodeNote": "โปรดป้อนรหัสยืนยัน",
        "oldPassword": "รหัสผ่านเก่า",
        "oldPasswordNote": "ป้อนรหัสผ่านเก่าของคุณ",
        "ConfirmPassword": "รหัสผ่านใหม่",
        "ConfirmPasswordNote": "ป้อนรหัสผ่านใหม่ของคุณ",
        "Text": "โปรดจำรหัสผ่านไว้ หากคุณลืมรหัสผ่าน โปรดติดต่อฝ่ายบริการลูกค้า",
        "ButtonCode": "รับรหัสยืนยัน",
        "Button": "ตกลงเพื่อแก้ไข",
        "verifyPhone": "โปรดป้อนหมายเลขโทรศัพท์มือถือของคุณ",
        "PhoneNew": "โปรดยืนยันว่าคุณเสร็จสิ้นแล้ว"
      },
      "Site": {
        "title": "การแก้ไขที่อยู่ในการรับสินค้า",
        "ReceivingIdentity": "ข้อมูลตัวตนในการรับสินค้า",
        "ReceivingAddress": "ข้อมูลที่อยู่ในการรับสินค้า",
        "DetailedAddress": "ที่อยู่ละเอียด",
        "DetailedAddressNote": "โปรดป้อนที่อยู่ละเอียด",
        "Set": "ตั้งค่าเป็นที่อยู่ทั่วไป",
        "Button": "ยืนยัน",
        "hint": "โปรดยืนยันว่าคุณเสร็จสิ้นแล้ว"
      },
      "AccountData": {
        "title": "รายละเอียดการทำธุรกรรม",
        "To": "ถึง",
        "search": "ค้นหา",
        "Cancel": "ยกเลิก",
        "Semua": "ทุกประเภท",
        "Penarikan": "บันทึกการถอนเงิน",
        "IsiUlang": "การเติมเงิน"
      },
      "RechargeRecord": {
        "title": "บันทึกการเติมเงิน",
        "Recharge": "เติมเงินแบบออฟไลน์",
        "Online": "เติมเงินออนไลน์",
        "Amount": "จำนวนเงินที่เติม :",
        "Order": "หมายเลขคำสั่งซื้อ:",
        "Status": "สถานะการเติมเงิน:"
      },
      "WithdrawalRecord": {
        "title": "บันทึกการถอนเงิน",
        "withdrawal": "การถอนเงินผ่านบัตรเครดิต"
      },
      "InviteFriends": {
        "Invite": "ลิงก์เชิญ : คลิกเพื่อคัดลอก",
        "InvitationCode": "รหัสเชิญ :",
        "Click": "คัดลอกลิงก์"
      },
      "Announcement": {
        "title": "ประกาศ",
        "System": "ประกาศระบบ"
      },
      "TheTeamReport": {
        "title": "รายงานทีม",
        "To": "ถึง",
        "search": "ค้นหา",
        "Cancel": "ยกเลิก",
        "balance": "ยอดเงินทีม",
        "turnover": "ยอดเติมเงินทีม",
        "recharge": "กำไรจากคำสั่งซื้อทีม",
        "withdrawal": "ถอนเงินทีม",
        "Commission": "กำไรจากคำสั่งซื้อทีม",
        "charge": "จำนวนคนทั้งหมด",
        "people": "จำนวนผู้ใช้ที่เติมเงิน",
        "members": "จำนวนผู้ใช้ใหม่",
        "NewPeople": "ผู้ใช้ใหม่ที่เติมเงิน",
        "Active": "จำนวนผู้ใช้ใหม่",
        "Level1": "ระดับ 1",
        "Level2": "ระดับ 2",
        "Level3": "ระดับ 3"
      }
    },
    "PlatformIntroduction": {
      "PlatformIntroduction": "แนะนำแพลตฟอร์ม",
      "Two": "แพลตฟอร์มนี้เป็นพันธมิตรที่ประกอบด้วยนักซื้อขายหลายล้านคน หลักการให้บริการของมันคือ: นักซื้อขายบนแพลตฟอร์มเพิ่มปริมาณการซื้อขายและสมาชิกของแพลตฟอร์มได้รับรายได้จากคำสั่งซื้อ",
      "Three": "คอมพิวเตอร์อัจฉริยะบุคคลที่สามควบคุมแพลตฟอร์มการจับคู่คำสั่งซื้อ แพลตฟอร์มร่วมงานกับร้านค้าบนแพลตฟอร์มช้อปปิ้งออนไลน์อื่น ๆ เพื่อจับคู่คำสั่งธุรกิจกับผู้ใช้แพลตฟอร์มผ่านระบบคลาวด์อัจฉริยะ เพื่อเพิ่มปริมาณการซื้อขายและชื่อเสียงของผู้ขายบนแพลตฟอร์มหลัก วิธีการจัดส่งคำสั่งซื้อถูกปรับปรุง คำสั่งซื้อจะถูกส่งโดยอัตโนมัติโดยเซิร์ฟเวอร์คลาวด์อัจฉริยะระยะไกล ผู้ใช้แพลตฟอร์มเพียงแค่ให้ที่อยู่ผู้รับสมบูรณ์ เบอร์โทรศัพท์ และข้อมูลการช้อปปิ้งบางส่วนโดยตรงบนแพลตฟอร์มเพื่อเข้าสู่โฮสต์คลาวด์อัจฉริยะ",
      "Four": "เพื่อช่วยเพิ่มประสิทธิภาพในการจับคู่คำสั่งซื้อของผู้ขายอย่างมีประสิทธิภาพและลดความเสี่ยงในการตรวจสอบคำสั่งซื้อเท็จของผู้ขาย แพลตฟอร์มจะปรับปรุงการจับคู่คำสั่งซื้อในแต่ละวันตามที่ปัจจุบันของคุณใช้งาน IP และประเภทอุปกรณ์ ดังนั้น การจับคู่คำสั่งซื้อจึงต้องสามารถทำได้โดยอัลกอริทึมคำนวณคลาวด์อัจฉริยะ โปรดรออย่างอดทน ผู้ใช้แพลตฟอร์มจะเป็นตัสารสนเทศของแพลตฟอร์มโดยการส่งเสริมให้ผู้คนใหม่เข้าร่วมแพลตฟอร์ม และพวกเขาสามารถได้รับกำไรเพิ่มเติมได้",
      "Five": "คุณเพียงแค่ใช้เวลาว่างของคุณในการเล่นอินเทอร์เน็ตและคุณสามารถรับกำไรที่มั่งคั่งได้ทุกวัน หลังจากคุณคลิกและส่งคำสั่งเรียบร้อยแล้ว จำนวนเงินต้นทุนและกำไรจะถูกคืน"
    },
    "RuleDescription": {
      "RuleDescription": "คำอธิบายกฎ",
      "One": "1. สมาชิกต้องกรอกที่อยู่ผู้รับและปรับปรุงข้อมูลส่วนบุคคลก่อนที่จะรับคำสั่งซื้อ ค่าคอมมิชชั่นของสมาชิกในแพลตฟอร์มแตกต่างกันตามงานต่าง ๆ ปริมาณคำสั่งซื้อที่ระบบสร้างขึ้นจากเงินในบัญชีของคุณ 30% - 70%",
      "Two": "2. เนื่องจากคำสั่งซื้อสินค้าใช้เวลาในการมีผลบังคับใช้ คำสั่งซื้อเท่านั้นที่สามารถรับได้โดยผู้ขายหลังจากคำสั่งซื้อมีผลบังคับใช้ ดังนั้น ค่าคอมมิชชั่นของแพลตฟอร์มและธนาคารสามฝ่ายและสมาชิกจะใช้ระบบ T + 1 นั่นคือวันนี้เพื่อถอนเงินและจะได้รับเงินก่อนวันถัดไปก่อนเวลา 23:59 หากคำสั่งซื้อไม่ถูกใช้งานหลังจากเวลาที่กำหนดแพลตฟอร์มจะจ่ายเป็นเงินล่วงหน้าเพื่อปิดบัญชีและออกเงินภายในเวลาที่กำหนด",
      "Three": "3. เงื่อนไขห้องที่เหมาะสมและอัตราค่าคอมมิชชั่น สมาชิกเงินสีเงิน: สมาชิกเงินสีเงินทั้งหมดสามารถรับคำสั่งซื้อ 10 รายการต่อวันและค่าคอมมิชชั่นสำหรับคำสั่งซื้อเหล่านี้คือ 0.30% ของจำนวนสินค้า",
      "Four": "สมาชิกทองคำ: เพื่อเปิดสมาชิกทองคำ คุณจำเป็นต้องเชิญสมาชิกได้สูงสุด 3 คนในห้องนี้ คุณสามารถรับคำสั่งซื้อได้ 35 รายการต่อวันและค่าคอมมิชชั่นสำหรับคำสั่งซื้อเหล่านี้คือ 0.35% ของจำนวนสินค้า",
      "Five": "สมาชิกแพลตินั่ม: เพื่อเปิดสมาชิกแพลตินั่ม คุณจำเป็นต้องเชิญสมาชิกได้สูงสุด 10 คนในห้องนี้ คุณสามารถรับคำสั่งซื้อได้ 40 รายการต่อวันและค่าคอมมิชชั่นสำหรับคำสั่งซื้อเหล่านี้คือ 0.40% ของจำนวนสินค้า",
      "Six": "สมาชิกแพลตินั่ม: เพื่อเปิดสมาชิกแพลตินั่ม คุณจำเป็นต้องเชิญสมาชิกได้สูงสุด 10 คนในห้องนี้ คุณสามารถรับคำสั่งซื้อได้ 40 รายการต่อวันและค่าคอมมิชชั่นสำหรับคำสั่งซื้อเหล่านี้คือ 0.40% ของจำนวนสินค้า",
      "Seven": "สมาชิกเพชร: เพื่อเปิดสมาชิกเพชร คุณจำเป็นต้องเชิญสมาชิกได้สูงสุด 30 คนในห้องนี้ คุณสามารถรับคำสั่งซื้อได้ 45 รายการต่อวันและค่าคอมมิชชั่นสำหรับคำสั่งซื้อเหล่านี้คือ 0.45% ของจำนวนสินค้า",
      "Eight": "4. โปรดตรวจสอบหมายเลขบัตรฝากของแพลตฟอร์มก่อนที่จะกรอกและแพลตฟอร์มจะเปลี่ยนหมายเลขบัญชีอย่างไม่เป็นประจำ แพลตฟอร์มจะไม่รับผิดชอบต่อความสูญเสียทรัพย์สินที่เกิดจากข้อผิดพลาดการดำเนินการส่วนบุคคล",
      "Nine": "หมายเหตุ: โปรดตรวจสอบข้อมูลบัตรธนาคารจากแพลตฟอร์มว่าเป็นของแพลตฟอร์มนี้หรือไม่ก่อนที่จะโอนเงินเพื่อป้องกันความเสียหานาทีของคุณ",
      "Ten": "5. เพื่อปกป้องกำไรระหว่างผู้ขายและแพลตฟอร์ม ดังนั้น ทุก IP, หมายเลขบัตรธนาคารและหมายเลขโทรศัพท์มือถือสามารถใช้ได้เพียงหนึ่งบัญชีเท่านั้น แพลตฟอร์มอีคอมเมิร์ซหลักทั้งหมดมีเครื่องจักรควบคุมความเสี่ยงหากมีผู้ใช้หลายคนในพื้นที่เดียวกันและ IP เดียวกันที่ช้อปปิ้งที่ร้านเดียวกัน จะทำให้สินค้าในร้านที่มีวัตถุดิบระเบิดถูกลบออกและมีผลต่อความน่าเชื่อถือและการขายของผู้ขายที่ไม่ถูกต้อง",
      "Eleven": "หากพบว่ามีบุคคลที่สมัครสมาชิกสำหรับบัญชีหลายบัญชี ผู้ละเมิดจะตัดบัญชีถาวรและล็อกยอดเงินเป็นเวลา 30 วัน เมื่อคุณต้องการถอนยอดเงินที่ถูกล็อก (สิ้นสุดระยะเวลา) โปรดติดต่อบริการลูกค้าออนไลน์ของแพลตฟอร์มเราเพื่อเปิดใช้งานบัญชีสำหรับการถอน",
      "OneData": {
        "One": "การแนะนำงาน:",
        "Two": "ประเภทงาน อัตราคอมมิชชั่น",
        "Three": "Lazada 0.30%",
        "Four": "Bukalapak 0.35%",
        "Five": "Shopee 0.40%",
        "Six": "Tokopedia 0.45%"
      },
      "TwoData": {
        "One": "รายละเอียดระดับสมาชิก:",
        "Two": "ประเภทสมาชิก สมาชิกเงินเงิน",
        "Three": "ขีดจำกัดการถอน 50,000,000",
        "Four": "จำนวนคำสั่งซื้อ 30/วัน",
        "Five": "อัตราคอมมิชชั่น 0.30%",
        "Six": "เชิญเพื่อน -"
      },
      "FourData": {
        "One": "ประเภทสมาชิก สมาชิกแพลตินั่ม",
        "Two": "ขีดจำกัดการถอน 50,000,000",
        "Three": "จำนวนคำสั่งซื้อ 40/วัน",
        "Four": "อัตราคอมมิชชั่น 0.40%",
        "Five": "เชิญเพื่อน 10"
      },
      "FiveData": {
        "One": "ประเภทสมาชิก สมาชิกเพชร",
        "Two": "ขีดจำกัดการถอน 50,000,000",
        "Three": "จำนวนคำสั่งซื้อ 45/วัน",
        "Four": "อัตราคอมมิชชั่น 0.45%",
        "Five": "เชิญเพื่อน 30"
      },
      "Twelve": "*ระดับที่แตกต่างกันสามารถได้รับตัวเลขคี่และอัตราคอมมิชชั่นที่แตกต่างกัน ระดับสมาชิกที่สูงขึ้นจะสามารถรับตัวเลขคี่ได้มากขึ้น ลำดับที่มากขึ้นจะมีอัตราคอมมิชชั่นที่สูงขึ้น"
    },
    "AgentCooperation": {
      "AgentCooperation": "ความร่วมมือของตัวแทน",
      "One": "เปิดตัวระดับมาตรฐานสามสำหรับพร็อกซี",
      "Two": "สมาชิกสามารถเป็นพร็อกซีของแพลตฟอร์มโดยแนะนำสมาชิกใหม่ที่เข้าร่วมเพื่อรับของขวัญพิเศษ",
      "Three": "โปรโมทของขวัญโดยตรงสำหรับผู้ใช้ระดับหนึ่งเพื่อรับคอมมิชชั่นโบนัส 9% ต่อวัน สำหรับของขวัญผู้ใช้ระดับสองเป็นคอมมิชชั่นโบนัส 6% ต่อวัน สำหรับของขวัญผู้ใช้ระดับสามเป็นคอมมิชชั่นโบนัส 3% ต่อวัน",
      "Four": "ตัวอย่างเช่น:",
      "Five": "พร็อกซีระดับหนึ่งของคุณได้เชิญ 10 คนให้เข้าร่วมเป็นสมาชิก พร็อกซีระดับสองได้เชิญ 20 คนให้เข้าร่วมเป็นสมาชิก และพร็อกซีระดับสามได้เชิญ 40 คนให้เข้าร่วมเป็นสมาชิก รายได้ต่อคนต่อวันคือ 200,000 INR ดังนั้นจินตนาการรายได้รายวันที่คุณจะได้รับ",
      "Six": "ระดับหนึ่ง: 200,000 x 10 x 9% = 180,000 INR",
      "Seven": "ระดับสอง: 200,000 x 20 x 6% = 240,000 INR",
      "Eight": "ระดับสาม: 200,000 x 40 x 3% = 240,000 INR",
      "Nine": "รวมระดับหนึ่ง + ระดับสอง + ระดับสาม = 660,000 INR",
      "Ten": "สมมติให้คุณเชิญสมาชิก A ให้ลงทะเบียน A เชิญ B และ B เชิญ C",
      "Eleven": "แล้วสมาชิก A คือผู้ใช้ระดับหนึ่งในทีมของคุณ B คือผู้ใช้ระดับสองของคุณและ C คือผู้ใช้ระดับสามของคุณ",
      "Twelve": "รางวัลรวม = ค่าคอมมิชชั่นคำสั่ง A x 9% + ค่าคอมมิชชั่นคำสั่ง B x 6% + ค่าคอมมิชชั่นคำสั่ง C x 3%",
      "Thirteen": "*Pengingat: Hadiah komisi untuk semua agen yang merupakan anggota offline dikeluarkan oleh platform ini, dan tidak akan memengaruhi komisi semua agen dan anggota offline!"
    },
    "CommonProblem": {
      "CommonProblem": "ปัญหาทั่วไป",
      "One": "คำถาม: ทำไมธุรกิจต้องการคำสั่งซื้อเสมือนจริง?",
      "Two": "ตามกฎระเบียบของแพลตฟอร์มอีคอมเมิร์ซหลัก ยิ่งมีผู้ซื้อสินค้ามากขึ้น เทรนด์ที่ดังขึ้น โอกาสที่จะได้รับการสนับสนุนและแสดงผลบนแพลตฟอร์มอีคอมเมิร์ซก็มากขึ้น ธุรกิจอีคอมเมิร์ซกำลังแข็งขันอยู่ ในการเพิ่มโอกาสให้สินค้าเกิดการเปิดเผยมากขึ้น ผู้ประกอบการจึงต้องเพิ่มปริมาณการซื้อขายสินค้า ดังนั้นพวกเขาเลือกที่จะสร้างคำสั่งซื้อเสมือนจริงบนแพลตฟอร์ม",
      "Three": "คำถาม: วิธีการดำเนินการคำสั่งซื้อ?",
      "Four": "เลือกช่องทางอีคอมเมิร์ซ คลิกเพื่อรับคำสั่งซื้อโดยอัตโนมัติ ระบบจะสอดคล้องกับสินค้าเพื่อรับคำสั่งซื้อ แพลตฟอร์มใช้ข้อมูลส่วนบุคคลจริงของคุณ ที่อยู่จัดส่งและ IP เครือข่ายเพื่อส่งคำสั่งซื้อไปยังแพลตฟอร์มอีคอมเมิร์ซเพื่อจำลองการช้อปปิ้งออนไลน์ อย่างไรก็ตาม ผู้ประกอบการไม่ได้ส่งสินค้าจริงๆ หลังจากคำสั่งซื้อสมบูรณ์แล้ว ทุนและค่าคอมมิชชั่นจะถูกคืนเข้าบัญชีของคุณทันที",
      "Five": "คำถาม: ทำไมฉันต้องเติมเงินก่อนรับคำสั่งซื้อ?",
      "Six": "เพื่อให้มั่นใจในคำสั่งซื้อที่แท้จริง แพลตฟอร์มการสั่งซื้อที่จำลองทุกแพลตฟอร์มต้องการการชำระเงินจริงผ่านเว็บไซต์อีคอมเมิร์ซ ในการปกป้องสิทธิ์ของแพลตฟอร์มและผู้ประกอบการ ยอดเงินในบัญชีของคุณจะถศูนย์ในขณะที่คำสั่งซื้อถูกส่ง และจะถูกคืนทันทีหลังจากคำสั่งซื้อเสร็จสมบูรณ์",
      "Seven": "คำถาม: วิธีเพิ่มจำนวนคำสั่งซื้อที่รับและเพิ่มคอมมิชชั่น?",
      "Eight": "โดยการเชิญเพื่อนให้สมัคร เรียกว่าระดับสูงขึ้น คำสั่งซื้อรวมทั้งหมดในแต่ละวันจะมีมากขึ้น และอัตราคอมมิชชั่นจะสูงขึ้น; ระบบจะจัดคู่จำนวนคำสั่งซื้อระหว่าง 30% ถึง 70% ของยอดเงินในบัญชี ดังนั้นยอดเงินในบัญชียิ่งมาก เงินต่อคำสั่งยิ่งมากขึ้น และคอมมิชชั่นยิ่งสูงขึ้น",
      "Nine": "คำถาม: วิธีเชิญเพื่อนให้สมัคร?",
      "Ten": "คลิกที่ “เชิญเพื่อน” คัดลอกลิงก์การแชร์หรือรหัส QR และส่งให้กับเพื่อน เพื่อนร่วมชั้น เพื่อนร่วมงาน และครอบครัวผ่านซอฟต์แวร์โซเชียลของคุณ คุณจะต้องกรอกรหัสเชิญเพื่อลงทะเบียนในแพลตฟอร์มนี้ และอีกฝ่ายจะกลายเป็นสมาชิกในทีมของคุณหลังจากลงทะเบียน",
      "Eleven": "คำถาม: วิธีถอนเงินสด?",
      "Twelve": "คุณสามารถขอถอนเงินสดหลังจากสอบถามเงื่อนไขการสั่งซื้อขั้นต่ำ หากไม่ตรงตามเงื่อนไขการสั่งซื้อขั้นต่ำให้ดำเนินการต่อจนสอบถามเงื่อนไขได้ หลังจากที่ร้องขอถูกอนุมัติแล้ว แพลตฟอร์มจะฝากเงินไปยังบัญชีธนาคารที่ผูกไว้กับคุณ และเวลาที่ได้รับจริงขึ้นอยู่กับธนาคารของคุณ"
    },
    "MemberUpgrade": {
      "MemberUpgrade": "อัปเกรดสมาชิก",
      "Member": "ระดับสมาชิก: ",
      "Orders": "คำสั่งที่สามารถใช้ได้ทุกวัน: ",
      "Commission": "เพิ่มกำไร",
      "DedicatedOne": "บริการลูกค้าที่มีการให้บริการเฉพาะ",
      "DedicatedTwo": "บริการลูกค้าที่มีการให้บริการเฉพาะ",
      "Swipe": "เลื่อนไปทางซ้ายและขวาเพื่อดูเพิ่มเติม",
      "One": {
        "Silver": "สมาชิกเงินเงิน",
        "Need": "ต้องเชิญ 0 คน",
        "Withdrawal": "วงเงินถอน: 50,000,000",
        "quantity": "จำนวนคำสั่ง: 10 คำสั่ง/วัน",
        "rate": "อัตรากำไร: 0.30%",
        "fee": "ค่าธรรมเนียมการถอนเงิน: 2.00%",
        "Activated": "เปิดใช้งานแล้ว"
      },
      "Two": {
        "Silver": "สมาชิกทองคำ",
        "Need": "ต้องเชิญ 3 คน",
        "People": "ขาดอีก 3 คน",
        "Withdrawal": "วงเงินถอน: 50,000,000",
        "quantity": "จำนวนคำสั่ง: 35 คำสั่ง/วัน",
        "rate": "อัตรากำไร: 0.35%",
        "fee": "ค่าธรรมเนียมการถอนเงิน: 2.00%",
        "Activated": "ยอดเติมเงินรายวัน: 1,000,000",
        "Invite": "เชิญเพื่อนตอนนี้"
      },
      "Three": {
        "Silver": "สมาชิกแพลตินัม",
        "Need": "ต้องเชิญ 10 คน",
        "People": "ขาดอีก 10 คน",
        "Withdrawal": "วงเงินถอน: 50,000,000",
        "quantity": "จำนวนคำสั่ง: 40 คำสั่ง/วัน",
        "rate": "อัตรากำไร: 0.40%",
        "fee": "ค่าธรรมเนียมการถอนเงิน: 2.00%",
        "Activated": "ยอดเติมเงินรายวัน: 1,500,000",
        "Invite": "เชิญเพื่อนตอนนี้"
      },
      "Four": {
        "Silver": "สมาชิกเพชร",
        "Need": "ต้องเชิญ 30 คน",
        "People": "ขาดอีก 30 คน",
        "Withdrawal": "วงเงินถอน: 50,000,000",
        "quantity": "จำนวนคำสั่ง: 45 คำสั่ง/วัน",
        "rate": "อัตรากำไร: 0.45%",
        "fee": "ค่าธรรมเนียมการถอนเงิน: 2.00%",
        "Activated": "ยอดเติมเงินรายวัน: 2,000,000",
        "Invite": "เชิญเพื่อนตอนนี้"
      }
    },
    "PhoneNew": "โปรดยืนยันว่าคุณได้ทำเสร็จแล้ว",
    "Toast": {
      "One": "บันทึกเรียบร้อยแล้ว",
      "Two": "ไม่สำเร็จ"
    },
    "Button": {
      "One": "ยกเลิก",
      "Two": "ยืนยัน"
    }
  },
  // My: {
  //   download: 'ดาวน์โหลดAPP',
  //   personal: 'ข้อมูลส่วนตัว',
  //   recharge: 'บันทึกการเติมเงิน',
  //   withdrawal: 'เอาเงินออก',
  //   language: 'ตั้งค่าภาษา',
  //   logout: 'ออก',
  //   MemberInformation: {
  //     balance: 'ยอดคงเหลือของบัญชี',
  //     Withdraw: 'เอาเงินออก',
  //     Recharge: 'เติมเงิน',
  //     forzenAll: 'หยุดทั้งหมด'
  //   },
  //   PersonalInformation: {
  //     Page: {
  //       Avatar: 'หัว',
  //       Nickname: 'ชื่อเล่น',
  //       PhoneNumber: 'หมายเลขโทรศัพท์',
  //       // BankAccounts: 'บัตรธนาคาร', // 银行账户
  //       BankAccounts: 'ที่อยู่กระเป๋าสตางค์ของฉัน',
  //       Password: 'รหัสผ่าน',
  //       TransactionPassword: 'รหัสผ่านการทำธุรกรรม', // 交易密码
  //       title: 'ข้อมูลส่วนตัว'
  //     },
  //     portrait: {
  //       SetAvatar: 'ตั้งค่าหัว',
  //       button: 'แก้ไข'
  //     },
  //     Name: {
  //       ModifyNickname: 'แก้ไขชื่อเล่น',
  //       title: 'แก้ไขชื่อเล่น',
  //       name: 'ชื่อเล่น',
  //       Please: 'กรุณาใส่ชื่อเล่น ที่คุณต้องการแก้ไข'
  //     },
  //     Password: {
  //       title: 'แก้ไขรหัสล็อกอิน',
  //       PleaseEnter: 'โปรดป้อนรหัสผ่าน ที่คุณต้องการแก้ไข',
  //       phone: 'โทรศัพท์มือถือ',
  //       NewPhone: 'กรุณาใส่เบอร์มือถือของคุณ',
  //       verificationCode: 'ตรวจสอบรหัส',
  //       verificationCodeNote: 'โปรดป้อนรหัสการตรวจสอบ',
  //       ConfirmPassword: 'รหัสผ่าน',
  //       ConfirmPasswordNote: 'โปรดป้อนข้อมูลเชิงลึก ที่คุณต้องการแก้ไข',
  //       Text: 'โปรดจำรหัสผ่านหากคุณลืมรหัสผ่านโปรดติดต่อฝ่ายบริการลูกค้า。',
  //       Button: 'ยืนยันการแก้ไข',
  //       ButtonCode: 'รับรหัสยืนยัน', // 获取code
  //     },
  //     AccountData: {
  //       title: 'รายละเอียดการซื้อขาย'
  //     }
  //   }
  // },
  newAdd: {
    "AddWalletTitle": "เพิ่มที่อยู่กระเป๋าเงิน",
    all: 'ทั้งหมด',
    Completed: 'เรียบร้อยแล้ว',
    unfinished: 'ยังไม่เสร็จ',
    Income: 'รายได้',
    Expenditure: 'ค่าใช้จ่าย',
    GetCodeBtn: 'กำลังรับรหัสยืนยัน',
    copySuccess: 'การคัดลอกสำเร็จ',
    copyError: 'การคัดลอกล้มเหลว',
    noLoginUserName: 'เข้าสู่ระบบ',
    Withdraw: 'เอาเงินออก',
    Recharge: 'เติมเงิน',
    commission: 'ค่านายหน้า',
    single: 'คำสั่ง',
    missionHall: 'หอปฏิบัติการ',
    videoTutorial: 'บทเรียนวิดีโอ',
    helpCenter: 'ศูนย์ช่วยเหลือ',
    inviteFriends: 'เชิญเพื่อน ๆ',
    help1Title: 'วิธีการรับค่านายหน้าผ่านซีจี',
    help1Context: 'เจ้าของธุรกิจไฟฟ้าจำเป็นต้องเพิ่มยอดขายของร้านดังนั้น เรา\
    เพียงแค่ต้องทำตามคำขอของร้านค้าในซีจีเพื่อให้เสร็จสิ้นการสั่งซื้อเจ้าของจะให้ค่านายหน้าแ\
    ก่เราผู้ใช้ แต่ละคนจะได้รับ 10 ภารกิจต่อวันคุณจะได้ค่านายหน้า 10 % ถึง 30 เปอร์เซ็นต์ภายในหนึ่งวัน',
    help2Title: 'วิธีการเพิ่มจำนวนงาน',
    help2Context: 'เนื่องจากมีงานพาร์ทไทม์มากเกินไป บริษัท จึงกำหนดให้พนักงานทำ\
    งานพาร์ทไทม์ทุกคน ที่ทำงานอินเทอร์เน็ตใช้เพียงคนเดียวในการติดต่อ และรับงาน',
    help3Title: 'จะทำให้งานสำเร็จได้อย่างไร',
    help3Context: 'ช่วยเพิ่มยอดขาย และให้รางวัลตามปริมาณการซื้อขายสำหรับ แต่ล\
    ะคำสั่ง ที่ประสบความสำเร็จคุณได้ค่านายหน้า และผู้ขายได้รับชื่อเสียง',
    referralLink: 'แนะนำลิงก์',
    copy: 'คัดลอก',
    paste: 'Dán', // 粘贴
    blance: 'ยอดคงเหลือ',
    todayOrder: 'คำสั่งซื้อวันนี้',
    provided: 'ข้อมูลนี้ ได้รับการจัดหา',
    Transaction: 'ตกลง',
    Frozen: 'ความไม่สงบ',
    Done: 'เรียบร้อยแล้ว',
    Cancel: 'ยกเลิก',
    ExpectedReturn: 'คาดว่า จะกลับ:',
    ImmediatePayment: 'จ่ายทันที',
    AvailableNum: 'จำนวนครั้ง ที่ใช้',
    OrderImmediately: 'ไปยังโฟลเดอร์ทันที',
    MakeMoneyEveryDay: 'รายได้ของวัน',
    TodayRecord: 'คำอธิบายคำสั่งซื้อ',
    AllocatCommissions: 'การกระจายค่าคอมมิชชั่น',
    FrozenAmount: 'ยอดค้างชำระ',
    TaskMatch: 'สุ่มเลือกงาน',
    AvailableBalance: 'ยอดเงิน ที่มีอยู่',
    WaitDispatch: 'กรุณาติดต่อผู้จัดการร้านเพื่อรอการมอบหมายงาน!',
    OrderDescription: 'คำอธิบายคำสั่งซื้อ',
    CouldMatch: 'งานจะถูกสุ่มจับคู่โดยระบบ:',
    OrderPerDay: 'คำสั่ง。',
    DescriptionRate: 'รวมค่าคอมมิชชั่นเดียวเป็นจำนวนเงิน ที่สั่งซื้อ',
    DescriptionPeriod: '。',
    Description3: 'ระบบมีพื้นฐานมาจากเทคโนโลยีไอบีเอส และเข้าคู่กับสินค้าโดยอัตโนมัติผ่านเมฆ',
    Description4: 'เพื่อหลีกเลี่ยงไม่ให้ถูกควบคุม หากคำสั่งซื้อไม่ได้รับการยืนยันและจัดส่งภายใน 20 นาที คำสั่งซื้อจะถูกระงับ',
    Note: 'หมายเหตุ:',
    DescriptionNote: 'เมื่อแพลตฟอร์มตรงกับผู้ใช้ในคำสั่งซื้อ แลตฟอร์มจะส่งข้อมูลคำสั่งซื้อไปยังแบ็กเอนด์ของผู้ขาย หากผู้ใช้ไม่ส่งคำสั่งซื้อภายใน 20 นาที จำนวนการสั่งซื้อจะถูกระงับโดยระบบ บัญชีผู้ใช้ที่เสร็จสิ้นภารกิจสามารถรับค่าคอมมิชชั่น สิ่งนี้ส่งผลโดยตรงต่อเวลางานถัดไปของผู้ใช้และอัตราส่วนคอมมิชชัน! กรุณาชำระเงินและถอนให้เสร็จสิ้นภายในเวลาหลังจากได้รับคำสั่งงาน!',
    OrderNum: 'หมายเลขคำสั่ง:',
    TotalOrderPrice: 'ราคารวม:',
    SubmitNow: 'ส่งทันที',
    Record: 'การบันทึก',
    MemberAccount: 'บัญชีสมาชิก',
    AccountPlaceholder: 'กรุณากรอกบัญชีของคุณ',
    RechargeAmount: 'ค่าปริยาย',
    RechargeAmountPlaceholder: 'โปรดป้อนจำนวนเงิน ที่ชาร์จ',
    SelectRechargeMethod: 'เลือกรูปแบบการเติมเงิน',
    NotBeenSentOrder: 'ยังไม่ได้ส่งคำสั่งซื้อโปรดติดต่อพนักงาน และส่งรายการ ที่สั่ง!',
    TodayOrderOver: 'สั่งเรียบร้อยแล้วค่ะพรุ่งนี้ ลองใหม่นะคะ',
    NotLogin: 'คุณยังไม่ได้ล็อกอินโปรดลองอีกครั้งหลังจากล็อกอิน!',
    // HaveNoteMethod: 'ไม่มีวิธีชาร์จแบบนี้ ในตอนนี้ โปรดเลือกวิธีการชาร์จอื่น ๆ',
    "HaveNoteMethod": "เปิดจำนวนจำกัด โปรดติดต่อฝ่ายขาย!",
    Login: 'ล็อกอิน',
    AccountRecord: 'การเปลี่ยนแปลงของบัญชี',
    RealName: 'ชื่อจริง',
    IFSCCODE: 'ไอเอสซี',
    BankName: 'ชื่อบัตรธนาคาร',
    BankNum: 'เลขบัตรธนาคาร',
    Mobile: 'มือถือ',
    MobileNum: 'โทรศัพท์มือถือ',
    Email: 'กล่องจดหมาย',
    EmailRequired: 'กล่องจดหมายจะถูกเติม',
    AddBankCardNote: 'บัตรธนาคารเป็นข้อมูลสำคัญกรุณากรอกอย่างระมัดระวัง!',
    Submit: 'ส่งข้อมูล',
    AddBankCardTitle: 'เพิ่มบัตรธนาคาร',
    BankCard: 'บัตรธนาคาร',
    Name: 'ชื่อ?',
    CardNum: 'เลขบัตร',
    Reading: 'กำลังอ่านอยู่...',
    AllBalance: 'ยอดเงินรวม',
    EnterWithdrawalAmount: 'โปรดป้อนจำนวนรวม',
    AllOut: 'ทั้งหมด',
    AskNow: 'เอาเงินออก',
    EnterPwdPlaceholder: 'โปรดป้อนรหัสผ่าน',
    NotHaveCard: 'คุณยังไม่ได้ใช้บัตรเงินคุณต้องการใช้บัตรธนาคาร หรือเปล่า',
    GetBankInfoFail: 'การดึงข้อมูลธนาคารล้มเหลว',
    EnterValidPayPwd: 'โปรดป้อนรหัสผ่านการชำระเงิน ที่ถูกต้อง',
    Balance: 'ยอดคงเหลือ',
    Total: 'ทั้งหมด',
    total: 'ทั้งหมด',
    records: 'การบันทึก',
    DisplayPerPage: 'รูปสี่เหลี่ยมต่อหน้า',
    NullNum: 'แถบ',
    pages: 'หน้า。',
    toAudit: 'หลังจากพิจารณาคดี',
    succeed: 'สำเร็จ',
    RechargeRecordTitle: 'บันทึกการเติมเงิน',
    rests: 'อื่น ๆ',
    Arrive: 'กำลังไป',
    forzenCommission: 'หยุดค่านายหน้า',
    companyIntro: 'รายละเอียด บริษัท',
    companyTitle: 'ทาตามอลล์เป็นส่วนหนึ่งของทาทากรุ๊ป',
    warmTitle: 'คำแนะนำ ที่ดี',
    companyText: 'กลุ่มทาทาเป็นกลุ่มธุรกิจ ที่ใหญ่ ที่สุดในอินเดียก่อตั้งขึ้นในปีค.ศ. 1868 ในมุมไบอินเดีย การดำเนินงานทางธุรกิจ ที่เกี่ยวข้องกับทั้งเจ็ดสาขาเทคโนโลยีการสื่อสาร และข้อมูลวิศวกรรมการบริการพลังงานสินค้าอุปโภคบริโภค และผลิตภัณฑ์เคมี ในฐานะกลุ่มธุรกิจ ที่กำลังพัฒนาอย่างรวดเร็วของอินเดียกลุ่มทาทามี บริษัท มากกว่า 200 บริษัท ในกว่า 80 ประเทศในหกทวีป รายได้รวมในปีงบประมาณ 2021 เท่ากับ 100 ล้านดอลลาร์สหรัฐ และ 58 % นั้น มาจากธุรกิจต่างประเทศ กลุ่มมีพนักงานประมาณ 450,000 คนทั่วโลก ตั้ง แต่ปี 153 ชื่อทาตาได้รับความเคารพอย่างมากในอินเดียเพราะมันยึดมั่นในคุณค่า ที่ดี และศีลธรรมทางธุรกิจ อันดับ ที่ 11 ของ บริษัท ที่ได้รับความนิยมมาก ที่สุดในโลกจากฟอร์บส์',
    depositAddress: 'ที่อยู่เติมเงิน',
    rechargeSteps: 'ขั้นตอนการเติมเงิน',
    completePayment: 'การเติมเต็มเสร็จสมบูรณ์',
    rechargeNow: 'เติมเงินทันที',
    rechargeCancel: 'ยกเลิกการเติม',
    bankName: 'บัญชีธนาคาร',
    payee: 'ชื่อบัญชี',
    cardNum: 'ธนาคาร',
    reviewing: 'ในการตรวจสอบ',
    remittance: 'มีการโอนเงินแล้ว',
    notThrough: 'ล้มเหลว',
    OrderID: 'คำสั่งID',
    "Amount": "จำนวน",
    "toCutScreen": "ตรวจสอบภาพให้เรียบร้อย!",
    "cardTabBank": "ธนาคาร",
    "cardTabUSDT": "USDT",
    "name": "ชื่อ",
    "chainType": "ประเภทโซ่",
    // 这下面是新增 2022-11-30 的
    newNavTitle: 'ส่งคำสั่ง',
    shopMoney: 'จำนวนสินค้า', // 商品金额
    actualMoney: 'ส่งจริง', // 实际提交金额（客户手动填写）
    commitBtnText: 'สร้างคำสั่ง',
    commitHint: 'อินพุตไม่ครบ' // 输入不完整
  },
  // 我的
  "mine": {
    "servier": "บริการ",
    integral: 'อินทิกรัล', // 积分展示
    balance: 'แลกเปลี่ยน', // 兑换
    record: 'บันทึกการแลกเปลี่ยน', // 兑换记录
    wtdAmt: 'แลกคะแนน', // 兑换积分
    avaBlc: 'จำนวนที่แลก', // 兑换数量
    allWtd: 'แลกทั้งหมด', // 全部兑换
    close: 'ยกเลิก',
    commit: 'ยืนยัน',
    wtdHint: 'กรุณาป้อนจำนวนเงินถอนที่ถูกต้อง' // 请输入正确的提现金额
  },
  "add": {
    "Commission": "ค่าคอมมิชชั่น",
    "Yesterday": "เมื่อวานนี้",
    "Cumulative": "ค่าปริยาย",
    "Today": "วันนี้",
    "TeamNum": "ขนาดรวมของ teamspeak",
    "TeamMoney": "ค่าคอมมิชชั่นรวม",
    "Personalcenteraccountchangerecord": "บันทึกการเปลี่ยนบัญชี",
    "lockedbalance": "ยอดค้างชำระ",
    "Cumulativeinvitation": "การเชื้อเพลิง",
    "Validinvitation": "การตรวจสอบ",
    "Accumulatedrebate": "การสะสม"
  },
  "home.btn.wallet": "การเงิน",
  // "home.btn.recharge": "ชาร์จใหม่",
  "home.btn.recharge": "ส่ง",
  "home.btn.withdraw": "การถอน",
  "home.btn.invite": "เชิญ",
  "home.btn.message": "ข้อความ",
  "page.grab": "จับ",
  "orderCommission": "สั่งการ",
  "commission": "ค่าคอมมิชชั่น",
  "todayIncome": "รายได้วันนี้",
  "personalEarnings": "รายได้จากคน",
  "completedOrders": "คอมพลีเมนต์",
  "lockedOrders": "ล็อกเกอร์",
  "unfinishOrder": "ไม่มีใครเทียบได้",
  "frozonAmount": "กองทุนไม่มั่นคง",
  "currentBalance": "สมดุลปัจจุบัน",
  "page.mine": "รายละเอียดบัญชี",
  "mine.invitationCode": "รหัสผู้รับเชิญ",
  "mine.serverCenter": "เซอร์เวอร์เซ็นเตอร์",
  "mine.tools.total": "รวม",
  "mine.tools.financial": "การเงิน",
  "mine.tools.details": "รายละเอียด",
  "mine.tools.freeze": "ยอดค้างชำระ",
  "mine.serviceTools.personal": "ส่วนตัว",
  "mine.serviceTools.teamReport": "รายงาน teamspeak",
  "mine.serviceTools.invite": "บัญชีครอบครัว",
  "mine.serviceTools.message": "ข้อความ",
  "mine.serviceTools.aboutUs": "เกี่ยวกับ",
  "mine.serviceTools.security": "ความปลอดภัย",
  "mine.serviceTools.address": "ที่อยู่",
  "mine.serviceTools.inviteTask": "งานสำหรับใช้",
  "page.team": "ทีม",
  "page.aboutus": "เกี่ยวกับ",
  "page.financial": "การเงิน",
  "page.financialRecord": "บันทึกทางการเงิน",
  "hint.InternetErr": "network ผิดพลาดโปรดลองอีกครั้งในภายหลัง!",
  "totalDeposit": "เงินมัดจำรวม",
  "totalRevenue": "รายได้รวม",
  "btn.transferInto": "โอนเข้า",
  "btn.transferOut": "โปร่งแสง",
  "page.transferInto": "โอนเข้า",
  "page.transferOut": "โปร่งแสง",
  "balanceTransfer": "งบดุลการถ่ายโอน",
  "availableBalance": "ความสมดุล ที่มีอยู่",
  "depositRate": "ผู้รับฝากเงิน",
  "days": "วัน",
  "hint.success": "สำเร็จ",
  "hint.fail": "ล้มเหลว",
  "status.into": "โอนเข้า",
  "status.out": "โปร่งแสง",
  "label.amount": "จำนวน",
  "label.time": "เวลา",
  Button: {
    "cancle": "ยกเลิก",
    "confirm": "ยืนยัน"
  },
  "dialog.title.inputPaypass": "รหัสผ่านของบันทึกข้อมูล",
  "dialog.nopaypass": "คน ที่มีประสบการณ์มากมาย",
  "mine.serviceTools.lan": "ภาษา",
  "checkLans": "รหัสภาษา",
  "mine.tools.commission": "ค่าคอมมิชชั่น",
  "page.commissionRecord": "ค่าคอมมิชชั่น",
  "noticeList.all": "ทั้งหมด",
  "noticeList.unread": "ยังไม่ได้อ่าน",
  "successWithdrawof": "การประหาร ที่ประสบความสำเร็จ",
  "home.title.notice": "แจ้งให้ทราบ",
  "home.title.patener": "จดสิทธิบัตร",
  "login.rememberPassword": "รหัสผ่านจดจำ",
  "page": {
    "cart": "รถเข็น",
    "order": "ลำดับ",
    "financing": "การเงิน",
    "goods": "สินค้า",
    "levelUpgrade": "เลวินซิเวิล",
    "PlatformIntroduction": "- ชานเมือง",
    "RuleDescription": "กฎระเบียบ",
    "AgentCooperation": "ความร่วมมือของชาวเอเชีย",
    "CommonProblem": "ปัญหาสามัญชน",
    "MemberUpgrade": "อัพเกรดเป็นสมาชิก",
    "login": "ล็อกอิน",
    "forgot": "ลืมไปแล้ว",
    "registrasi": "นายทะเบียน",
    "record": "บันทึก",
    "Recharge": "ชาร์จใหม่",
    "inviteFriends": "เพื่อนรับเชิญ",
    "Withdraw": "ถอนตัว",
    "Service": "บริการ",
    "DialoguePage": "หน้าไดอะแกรม",
    "user": "ส่วนตัว",
    "RansferIn": "แรนเฟอร์ริน",
    "PersonalCenter": "ศูนย์ส่วนบุคคล",
    "Portrait": "สัญลักษณ์",
    "Name": "ชื่อ",
    "Account": "บัญชีผู้ใช้",
    "Password": "รหัสผ่าน",
    "PaymentCode": "รหัสผ่านการชำระเงิน",
    "Site": "Site",
    "AccountData": "บันทึกการเปลี่ยนแปลงบัญชี",
    "RechargeRecord": "คำสั่งเติมเงิน",
    "WithdrawalRecord": "ถอนคำสั่ง",
    "InviteFriends": "เชิญชวน",
    "Announcement": "ประกาศ",
    "TheTeamReport": "รายงานทีม",
    "BillRecord": "สั่งซื้อสินค้า",
    "BankCardAdd": "เพิ่มบัตรธนาคาร",
    "NewRecharge": "เติมเงิน",
    "RechargeList": "คำสั่งเติมเงิน",
    "newWithdraw": "ถอน",
    "withdrawList": "ถอนคำสั่ง",
    "SettingPayPwd": "เปลี่ยนรหัสผ่านการชำระเงิน",
    "MakeMoney": "หาเงิน",
    "team": "ทีม",
    "qrPay": "การชำระเงินด้วยรหัส QR",
    "bankPay": "ชำระเงินผ่านธนาคาร",
    "aboutus": "เกี่ยวกับเรา",
    "financial": "การจัดการทางการเงิน",
    "financialRecord": "บันทึกทางการเงิน",
    "TransferInto": "โอนเข้า",
    "TransferOut": "โอนออก",
    "CommissionRecord": "คณะกรรมการ",
    "Security": "ความปลอดภัย",
    "Address": "ที่อยู่",
    "Message": "ข้อมูล",
    "InviteTask": "ขอภารกิจ",
    "Notice": "ประกาศ",
    "modifyPaymentPassword": "เปลี่ยนรหัสผ่านการชำระเงิน"
  },
  copyLink: "คัดลอกลิงค์",
  "Home.WithdrawSu.Balance": "สมดุล",
  "teamReport": {
    title: "รายงานทีม",
    subTitle: "ภาพรวมทีมรอง",
    taskTitle: "เชิญ {num} คนลงทะเบียนและทำการสั่งซื้อให้เสร็จสิ้น",
    bonus: "โบนัส",
    Unfinished: "เลิกทำ",
    Getted: "ได้รับ"
  },
  copy: "สำเนา",
  address: {
    name: "ชื่อ",
    inputPlaceholder: "กรุณาป้อน",
    phone: "โทรศัพท์",
    areaPlaceholer: "ข้อมูลที่อยู่จัดส่ง",
    submit: "บันทึก"
  },
  "My.PersonalInformation.Name.Modify": "ปรับปรุงใหม่",
  "password.old": "รหัสผ่านเก่า",
  "password.old.placeholder": "รหัสผ่านของปูมบันทึก",
  "password.confirmPassword": "คำยืนยัน",
  "password.confirmPassword.placeholder": "ใส่รหัสผ่านใหม่อีกครั้ง",
  "hint.passwordDiff": "การแข่งขันคู่แฝด",
  "loginType": {
    "phone": "โทรศัพท์",
    "email": "อีเมล"
  },
  "Login.Daftar.email": "อีเมล",
  "Login.emailInput": "กรุณาใส่อีเมลของคุณ",
  "My.PersonalInformation.Password.email": "อีเมล",
  "My.PersonalInformation.Password.NewEmail": "กรุณากรอกอีเมล์ของคุณ",
  "mine.serviceTools.certify": "ใบรับรอง",
  "Certification": {
    "type": {
      "id": "ไอดีการ์ด",
      "driver": "ใบอนุญาตขับรถ",
      "passport": "พาสปอร์ต"
    },
    "name": "ชื่อ",
    "license": "หมายเลขใบอนุญาต",
    "upload": "สารคดี",
    "submit": "ส่งข้อมูล",
    "incompleteInformation": "จีบข้อมูล และพยายามอีกครั้ง!",
    "submitSuccess": "การรับรองข้อมูลแฮสเบส",
    "photo": "ID รูปถ่าย",
    "review": "ภายใต้การทบทวน",
    "verified": "ตรวจสอบแล้ว"
  },
  "upload": {
    "uploading": "กำลังอัปโหลด...",
    "failed": "การเพิ่มระดับ"
  },
  "viGrabDesces": {
    "1": "ทุกคำสั่งสามารถเลือกเป็น Lucky Order และค่าคอมมิชชั่นจะเพิ่มขึ้นเป็น {rate}% โปรดติดต่อที่ปรึกษาการขยายงานของคุณเพื่อสมัครรับการมอบหมายงานเพิ่มเติม",
    2: "ช่วยผู้ขาย Shopee สร้างคำสั่งซื้อเสมือนจริง เพิ่มการมองเห็น และรับคอมมิชชั่น",
    3: "โปรดติดต่อครูสอนพิเศษของคุณโดยเร็วที่สุดหลังจากได้รับคำสั่งซื้อ ส่งข้อมูลคำสั่งซื้อและดำเนินการให้เสร็จสิ้น",
    4: "งานมีจำนวนจำกัด โปรดใส่ใจกับคำแนะนำการเลื่อนขั้นของผู้สอนเมื่อรับคำสั่งซื้อ",
    5: "จำนวนเงินที่คืนจะออกให้ภายใน 5-10 นาทีหลังจากเสร็จสิ้นภารกิจ หากคุณมีคำถามใด ๆ โปรดปรึกษาผู้เชี่ยวชาญหรือฝ่ายบริการลูกค้าออนไลน์",
    6: "ทำงานที่ได้รับมอบหมายจาก Shopee ให้สำเร็จ ปรับปรุงระดับ VIP และรับค่าคอมมิชชั่นที่สูงขึ้นสำหรับงาน Fackbook",
    7: "เมื่อเสร็จสิ้นภารกิจ Shopee ที่กำหนด คุณจะได้รับค่าคอมมิชชั่นเพิ่มเติม สำหรับรางวัลเฉพาะ โปรดปรึกษาผู้เชี่ยวชาญด้านโปรโมชั่นหรือฝ่ายบริการลูกค้าออนไลน์"
  },
  "expectedEarningsTitle": "กำไรที่คาดหวัง",
  rechargeDesc: {
    nav: "คำอธิบายการเติมเงิน",
    p1: "ตอนนี้เรามีพนักงานจำนวนมากที่ทำงานเสร็จพร้อมๆ กัน",
    p2: "ผู้ขายรายเดียวไม่สามารถให้บัญชีได้เพียงพอ",
    p3: "ดังนั้นเราจึงโพสต์บัญชีผู้ขายของเราในช่องว่างอื่น",
    p4: "เมื่อคุณต้องการทำงานให้เสร็จสิ้น คุณสามารถติดต่อฝ่ายขายเพื่อลงทะเบียนช่องทางการชำระเงินของคุณ"
  },
  chat: {
    list: {
      nav: "ข้อความ",
      hours: "{h} ชั่วโมง",
      minutes: "{m} นาที",
      ago: "{time}ที่แล้ว",
      image: "รูปภาพ"
    },
    room: {
      msgPlaceholder: "ใส่ข้อความ...",
      send: "ส่ง",
      sender: "ผู้ส่ง",
      sendTime: "ส่งเวลา",
      content: 'เนื้อหา'
    }
  },
  newBank: {
    usdt: {
      name: "ชื่อ",
      currency: "สกุลเงิน",
      chainType: "ประเภทโซ่",
      depositAddress: "ที่อยู่ฝาก",
      submit: "ส่ง",
    },
    "walletList": {
      pageTitle: 'การจัดการกระเป๋าเงิน',
      "name": "นามแฝงกระเป๋าสตางค์:",
      "coinType": "สกุลเงิน: ",
      "chainType": "ประเภทโซ่:",
      "walletAddress": "ที่อยู่:",
      "addDate": "วันที่:",
      "btnText": "เพิ่มที่อยู่กระเป๋าเงิน",
      "deleteConfirmText": "คุณแน่ใจหรือว่าต้องการจะลบที่อยู่กระเป๋าคุมข้อมูล?",
      "btnConfirm": "ลบ",
      "btnCancle": "ยกเลิก",
      "successHint": "ประสบความสำเร็จ"
    },
    withdrawNum: "จำนวนการถอน",
    AllOut: "ทั้งหมด",
    balance: "ความสมดุลโดยรวม",
    handlingFee: "ค่าธรรมเนียมการจัดการ",
    actualAmount: "จำนวนเงินจริง"
  },
  "order.old": "ลำดับรายได้สูง",
  "order.new": "สั่งประจำ",
  "Login.Daftar.username": "ชื่อผู้ใช้",
  "Login.Daftar.referrer": "รหัสเชิญ(ID)",
  "bannerText1": "{name} เสร็จสมบูรณ์ {money} จำนวนงานที่จะได้รับ {money1} ค่าคอมมิชชั่น",
  "bannerText2": "ขอแสดงความยินดี {money} เลือกงานพรีเมี่ยมเพื่อรับค่าคอมมิชชั่น 30%",
  "grap": {
    "btask": "งานโบนัส",
    "wtask": "งานทำงาน"
  },
  "hashPlaceholder": "โปรดป้อนแฮช",
  "recharge.actualAmount": "ยอดเงินจริง",
  "login.userAgreement": "ข้อตกลงผู้ใช้",
  "NetWork": "เครือข่าย",
  "tailNum": "หมายเลขหาง",
  "label.price": "ราคา",
  "label.id": "รหัส",
  "label.level": "ระดับ",
  "login.title1": "ลงทะเบียน",
  "login.title2": "ยินดีต้อนรับกลับ!",
  "registe.title1": "ลงทะเบียน",
  "registe.title2": "ยินดีต้อนรับกลับ!",
  "mine.credit": "เครดิต",
  "thirdRecharge.hint.noGoods": "กรุณาแยกวิเคราะห์รายการก่อนและลองอีกครั้ง!",
  "hint.onlyAgentInvite": "บัญชีครอบครัวยังไม่เปิดใช้งาน",
  "newTab.p2p": "P2P",
  "page.checkin": "เช็คอิน",
  "checkin.submit": "เช็คอิน",
  "checkin.success": "ลงชื่อเข้าใช้สำเร็จ",
  "checkin.fail": "การลงชื่อเข้าใช้ล้มเหลว",
  "todayChecked": "คุณได้ลงชื่อเข้าใช้แล้ว โปรดอย่าดำเนินการซ้ำ!",
  "submit.checked": "ลงชื่อเข้าใช้แล้ว",
  "hint.inviteBanned": "ขออภัย บัญชีของคุณยังไม่ได้เปิดบัญชีครอบครัว โปรดติดต่อฝ่ายบริการลูกค้าหรือที่ปรึกษาของคุณเพื่อสมัครเปิดบัญชี!",
  "newTab.blindBox": "กล่องตาบอด",
  "blindBox.submit": "เริ่มจับสลาก",
  "blindBox.times": "โอกาสในการเปิดหีบ: {__times__} ครั้ง",
  "blindBox.records.title": "สถิติการชนะของฉัน",
  "banEditBankHint": "บัตรธนาคารเป็นข้อมูลสำคัญ โปรดติดต่อฝ่ายบริการลูกค้าหากคุณต้องการแก้ไข!",
  "level.order_num": "ค่าคอมมิชชั่นสกุลเงินดิจิทัล",
  "drawNum": "จำนวนกล่องตาบอด",
  "recharge.good_title": "ผลิตภัณฑ์",
  "recharge.blindBoxIncome": "รายได้จากกล่องตาบอด",
  "recharge.amount": "จำนวน",
  "recharge.bonusRatio": "อัตราส่วนโบนัส",
  "p2p": {
    "trade_method": {
      "buy": "ซื้อ",
      "sell": "ขาย"
    },
    "coinList": {
      "orders": "{} รายการ",
      "label": {
        "amount": "จำนวน",
        "price": "ราคา",
        "limit": "ขีด จำกัด"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "คุณต้องการขาย {} กี่หน่วย?",
      "trade_title_buy": "คุณต้องการซื้อ {} กี่หน่วย?",
      "submit_now": "ซื้อ {} ตอนนี้"
    },
    "orderDetails": {
      "sellTitle": "รอการชำระเงินจากฝ่ายตรงข้าม",
      "buyTitle": "รอการชำระเงินจากคุณ",
      "sellHint": "ฝ่ายตรงข้ามต้องทำการชำระเงินภายใน {} มิฉะนั้นคำสั่งซื้อจะถูกยกเลิกโดยอัตโนมัติ",
      "buyHint": "ทำการชำระเงินภายใน {} เมื่อคุณทำการชำระเงินเรียบร้อยแล้ว คลิกที่ติดต่อผู้ขาย",
      "payInfo_title": "ข้อมูลการชำระเงิน",
      "label": {
        "totalAmount": "จำนวนทั้งหมด",
        "pricePerUnit": "ราคาต่อหน่วย",
        "quantity": "จำนวน",
        "counterpaty": "ฝ่ายตรงข้าม",
        "payMethod": "วิธีการชำระเงิน",
        "fullName": "ชื่อเต็ม",
        "email": "อีเมล",
        "phone": "หมายเลขโทรศัพท์"
      },
      "btn_service": "ติดต่อผู้ขาย"
    }
  },
  "copySuccess": "คัดลอกสำเร็จ",
  "p2p.noMore": "ไม่มีเพิ่มเติม",
  "tabbar.shop": "ช้อปปิ้ง",
  "newAdd.shopTitle": "ตะกร้าสินค้า",
  "shop": {
    "urlplaceholder": "กรุณาเพิ่มลิงค์ผลิตภัณฑ์",
    "uploadText": "อัปโหลดภาพหน้าจอการช็อปปิ้ง",
    "urlNull": "กรุณาเพิ่มลิงค์ผลิตภัณฑ์",
    "uploadNull": "กรุณาเพิ่มภาพหน้าจอการช็อปปิ้ง",
    "btn": "ส่ง",
    "money": "+{__} ยอดคงเหลือ",
    "point": "+{__}ปริพันธ์",
    "moneyPoint": "+{money}ยอดคงเหลือ\n+{point}ปริพันธ์",
    "order": {
      "orderNo": "หมายเลขสั่งซื้อ",
      "link": "การเชื่อมโยง",
      "screenshot": "ภาพหน้าจอ",
      "title": "การกระทำทางประวัติศาสตร์"
    }
  },
  "Login.Daftar.selectCurrency": "เลือกสกุลเงิน",
  "mine.totalBalance": "ยอดคงเหลือทั้งหมด",
  "newAdd.page": "หน้า",
  'newAdd.record': "บันทึก",
  "emailFormatError": "กรุณากรอกที่อยู่อีเมลที่ถูกต้อง!",
  "urlParsePlaceholder": "กรุณาระบุรหัสประจำตัว",
  "btn.Identify": "ระบุทันที",
  "produceStatus.confirming": "กำลังได้รับการยืนยัน",
  "produceStatus.paying": "ต้องชำระ",
  "produceStatus.paid": "ชำระเงินแล้ว",
  "produceStatus.done": "เสร็จสิ้น",
  "produceStatus.cancle": "คืนเงินแล้ว",
  "order.old": "ลำดับรายได้สูง",
  "order.new": "คำสั่งซื้อปกติ",
  "Home": {
    "HallofFame": 'หอเกียรติยศ',
  },
  "hashPlaceholder": "โปรดป้อน Hash",
  "recharge.status.error": "การสร้างคำสั่งเสมือน Walmart ล้มเหลว โปรดส่งค่า Hash และจำนวนสินค้าที่ถูกต้อง",
  "recharge.actualAmount": "จำนวนเงินจริง",
  "login.userAgreement": "ข้อตกลงการใช้งาน",
  "login.title1": "ลงทะเบียน",
  "login.title2": "ยินดีต้อนรับ!",
  "registe.title1": "ลงทะเบียน",
  "registe.title2": "ยินดีต้อนรับ!",
  "mine.credit": "เครดิต",
  "page.checkin": "เช็คอิน",
  "checkin.submit": "เช็คอิน",
  "checkin.success": "เช็คอินสำเร็จ",
  "checkin.fail": "เช็คอินไม่สำเร็จ",
  "todayChecked": "คุณได้ทำการเช็คอินแล้ว โปรดอย่าทำซ้ำ!",
  "submit.checked": "เช็คอินแล้ว",
  "hint.inviteBanned": "ขออภัย บัญชีของคุณยังไม่ได้เปิดบัญชีครอบครัว โปรดติดต่อฝ่ายบริการลูกค้าหรือครูสอนของคุณเพื่อขอเปิดบัญชี!",
  "newTab.blindBox": "กล่องสุ่มรางวัล",
  "blindBox.submit": "เริ่มสลากกล่อง",
  "blindBox.times": "โอกาสเปิดกล่อง: {__times__} ครั้ง",
  "blindBox.records.title": "รายการรางวัลของฉัน",
  "banEditBankHint": "บัตรเครดิตเป็นข้อมูลที่สำคัญ หากต้องการแก้ไขโปรดติดต่อฝ่ายบริการลูกค้า!",
  "drawNum": "จำนวนกล่องสุ่มรางวัล",
  "recharge.good_title": "สินค้า",
  "recharge.blindBoxIncome": "รายได้จากกล่องสุ่มรางวัล",
  "recharge.amount": "จำนวนเงิน",
  "recharge.bonusRatio": "อัตราส่วนโบนัส",
  "submit": {
    "desc": {
      "1": "หลังจากที่คุณเสร็จสินค้าในกิจกรรม คุณจะได้รับโอกาสสุ่มรางวัลกล่องสุ่ม โดยอัตราโบนัสจะอยู่ในช่วง 10-50% ของมูลค่าสินค้า",
      "sub_1": "ตัวอย่างเช่น หากคุณชำระเงินในจำนวนคำสั่งซื้อ R$3000 อัตราโบนัสกล่องสุ่มคือ 10%-50% และช่วงโบนัสจริงคือ R$300-1500 ซึ่งหมายความว่ายอดการถอนขั้นต่ำของคุณคือ R$3300 และยอดถอนสูงสุดคือ R$4500",
      "2": "โดยใช้เทคโนโลยี LBS ระบบจะจับคู่สินค้าและโอกาสสุ่มกล่องสุ่มผ่านระบบคลาวด์อัตโนมัติ",
      "3": "เพื่อป้องกันการตรวจสอบของแพลตฟอร์ม หากคำสั่งไม่ได้รับการยืนยันและส่งภายใน 30 นาที ระบบจะล็อคยอดเงินคำสั่ง",
      "note": "โปรดอ่านเนื้อหาที่เกี่ยวข้องอย่างรอบคอบ:",
      "note_sub": "หลังจากจับคู่คำสั่งสำหรับผู้ใช้ ระบบ Shopee จะส่งข้อมูลคำสั่งไปยังพื้นหลังร้านค้า หากผู้ใช้ไม่ส่งคำสั่งภายใน 30 นาที ยอดเงินคำสั่งจะถูกล็อคโดยระบบ หากต้องการหลีกเลี่ยงการตรวจสอบของระบบ โปรดยืนยันหลังจากคำสั่ง งานทั้งหมดไม่สามารถยกเลิกหรือแทนที่ได้หลังยอมรับ! หากมีปัญหาอื่นที่ต้องการจัดการ โปรดคลิกที่หยุดวางคำสั่งทันทีเพื่อหลีกเลี่ยงการล็อคเวลาเกินกำหนด ขอบคุณสำหรับการสนับสนุนของคุณ"
    }
  },
  "drawNine": {
    "times": "โอกาสเปิดกล่องสมบัติ",
    "submit": "โบนัสกล่องสุ่ม",
    "percentHint": "ผู้ชนะ 100%"
  },
  "hint.nullOfDraw": "คุณไม่มีโอกาสสำหรับการสุ่ม",
  "hint.loginNotRead": "โปรดอ่านข้อตกลงและลองอีกครั้ง!",
  "pages.editPhone": "การแก้ไขหมายเลขโทรศัพท์",
  "editPhone": {
    "label": "หมายเลขโทรศัพท์",
    "placeholder": "หมายเลขโทรศัพท์",
    "hint": "โปรดป้อนหมายเลขโทรศัพท์ของคุณ"
  },
  "Button.submit": "ส่ง",
  "pages.editEmail": "ผูกอีเมล",
  "users.email": "อีเมล",
  "users.userphone": "หมายเลขโทรศัพท์",
  "users.username": "ชื่อผู้ใช้",
  "editEmail": {
    "label": "อีเมล",
    "placeholder": "อีเมล",
    "hintRequire": "โปรดป้อนที่อยู่อีเมล",
    "hintCorrect": "โปรดป้อนอีเมลที่ถูกต้องของคุณ"
  },
  "personal.page.email": "อีเมล",
  "personal.page.phone": "โทรศัพท์",
  "banEditPhone": "หมายเลขโทรศัพท์เป็นข้อมูลที่สำคัญ หากต้องการแก้ไขโปรดติดต่อฝ่ายบริการลูกค้า!",
  "banEditEmail": "อีเมลเป็นข้อมูลที่สำคัญ หากต้องการแก้ไขโปรดติดต่อฝ่ายบริการลูกค้า!",
  "banEditUserInfo": "ข้อมูลที่สำคัญไม่สามารถแก้ไขได้ หากต้องการแก้ไขโปรดติดต่อฝ่ายบริการลูกค้า!",
  "page.certification": "การยืนยันตัวตน",
  "login.forgetPassword": "ลืมรหัสผ่าน โปรดติดต่อฝ่ายบริการลูกค้า!",
  "selectCurrenycy": "เลือกสกุลเงิน",
  "welfareDialogTitle":"การแจ้งเตือนของระบบ"
}