export default {
  "signStr": "1001",
  "tab": {
    "my": "Moje",
    "home": "Dom",
    "record": "Rekord",
    "grab": "Chwytaj",
    "financing": "Finansowanie",
    "make_money": "Sprawdź"
  },
  "newTab": {
    "home": "Dom",
    "money": "Pieniądze",
    "shop": "Sklep",
    "order": "Zamówienie",
    "my": "Moje",
    "team": "Zespół"
  },
  "common": {
    "unrealized": "Robienie",
    "all": "Wszystko",
    "distributionTip": "0 dostawa 0 opłata za dystrybucję",
    "easeBack": "Łagodne powroty",
    "addCarSuccess": "Dodano do koszyka",
    "deleteConfirm": "Czy na pewno chcesz usunąć wybrany element?"
  },
  "My": {
    "download": "Pobierz aplikację",
    "personal": "Informacje osobiste",
    "preOrde": "Rekord zamówienia wstępnego",
    "account": "Szczegóły konta",
    "recharge": "Rekord doładowania",
    "withdrawal": "Wypłata",
    "invite": "Zaproś znajomych",
    "announcement": "Ogłoszenie",
    "service": "Usługa",
    "team": "Raport zespołowy",
    "quit": "Zakończ",
    "logoutEvent": "Potwierdzić wyjście?",
    "language": "Ustawienie języka",
    "languageButton": { "english": "Angielski", "indonesian": "Indonezyjski" },
    "logout": "Wyloguj się",
    "MemberInformation": {
      "SilverMember": "Członek srebrny",
      "Invitation": "ID pracownika",
      "balance": "Saldo konta",
      "Recharge": "Doładowanie",
      "Withdraw": "Wypłata",
      "forzenAll": "Zamrożone środki"
    },
    "PersonalInformation": {
      "Page": {
        "title": "Moje informacje",
        "Avatar": "Awatar",
        "Nickname": "Pseudonim",
        "PhoneNumber": "Numer telefonu",
        "BankAccounts": "Adres mojego portfela",
        "Password": "Hasło",
        "TransactionPassword": "Hasło transakcyjne",
        "ReceivingAddress": "Adres odbioru"
      },
      "portrait": { "SetAvatar": "Ustaw awatar", "button": "Ustaw teraz" },
      "Name": {
        "ModifyNickname": "Zmień pseudonim",
        "title": "Zmień swój pseudonim",
        "name": "Pseudonim",
        "Modify": "Zmień",
        "Please": "Wprowadź pseudonim"
      },
      "BankAccounts": {
        "BankAccounts": "Powiąż kartę bankową",
        "YourInformation": "Twoje informacje",
        "RealName": "Prawdziwe imię",
        "RealNameNote": "Ustaw swoje prawdziwe imię",
        "PhoneNo": "Numer telefonu",
        "PhoneNoNote": "Ustaw numer telefonu",
        "YourBankCard": "Informacje o twojej karcie bankowej",
        "BankAccount": "Konto bankowe",
        "BankAccountNote": "Ustaw konto bankowe",
        "BankName": "Nazwa banku",
        "BankNameData": {
          "option1": "Wybierz bank",
          "option2": "Bank Central Asia",
          "option3": "Bank Negara Indonesia",
          "option4": "Bank Rakyat Indonesia",
          "option5": "Bank Mandiri"
        },
        "BankBranch": "Oddział banku",
        "BankBranchNote": "Ustaw oddział banku",
        "MoreInformation": "Więcej informacji",
        "WhatsApp": "WhatsApp",
        "WhatsAppNote": "Ustaw numer WhatsApp",
        "Text": "Przypomnienie: Aby zagwarantować swoje prawa, powiąż poprawny numer karty i informacje bankowe. W przypadku błędnego wypełnienia platforma nie ponosi odpowiedzialności za brak otrzymania płatności.",
        "Settings": "Ustawienia"
      },
      "Password": {
        "title": "Zmień hasło do logowania",
        "PleaseEnter": "Wprowadź nowe hasło",
        "phone": "telefon",
        "NewPhone": "Wprowadź numer telefonu komórkowego",
        "verificationCode": "kod weryfikacyjny",
        "verificationCodeNote": "Wprowadź kod weryfikacyjny",
        "oldPassword": "stare hasło",
        "oldPasswordNote": "wprowadź swoje stare hasło",
        "ConfirmPassword": "nowe hasło",
        "ConfirmPasswordNote": "wprowadź nowe hasło",
        "Text": "Pamiętaj hasło. Jeśli zapomnisz hasło, skontaktuj się z obsługą klienta.",
        "ButtonCode": "pobierz kod weryfikacyjny",
        "Button": "Zatwierdź zmiany",
        "verifyPhone": "Wprowadź numer telefonu komórkowego",
        "PhoneNew": "Upewnij się, że zakończyłeś"
      },
      "Site": {
        "title": "Modyfikacja adresu odbioru",
        "ReceivingIdentity": "Informacje o tożsamości odbiorcy",
        "ReceivingAddress": "Informacje o adresie odbioru",
        "DetailedAddress": "Szczegółowy adres",
        "DetailedAddressNote": "Wprowadź szczegółowy adres",
        "Set": "Ustaw jako adres domyślny",
        "Button": "Zatwierdź",
        "hint": "Upewnij się, że zakończyłeś"
      },
      "AccountData": {
        "title": "Szczegóły transakcji",
        "To": "Do",
        "search": "szukaj",
        "Cancel": "Anuluj",
        "Semua": "Wszystkie typy",
        "Penarikan": "Zapis wypłaty",
        "IsiUlang": "Zapis doładowania"
      },
      "RechargeRecord": {
        "title": "Rekord doładowania",
        "Recharge": "Doładowanie offline",
        "Online": "Doładowanie online",
        "Amount": "Kwota doładowania:",
        "Order": "Numer zamówienia:",
        "Status": "Status doładowania:"
      },
      "WithdrawalRecord": {
        "title": "Rekord wypłaty",
        "withdrawal": "Wypłata na kartę bankową"
      },
      "InviteFriends": {
        "Invite": "Link zaproszenia: kliknij, aby skopiować",
        "InvitationCode": "Kod zaproszenia:",
        "Click": "Skopiuj link"
      },
      "Announcement": {
        "title": "Ogłoszenie",
        "System": "Ogłoszenie systemowe"
      },
      "TheTeamReport": {
        "title": "Raport zespołu",
        "To": "Do",
        "search": "szukaj",
        "Cancel": "Anuluj",
        "balance": "Kwota zespołu",
        "turnover": "Obrót zespołu",
        "recharge": "Zysk z zamówień zespołu",
        "withdrawal": "Wypłata zespołu",
        "Commission": "Zysk z zamówień zespołu",
        "charge": "Całkowita liczba osób",
        "people": "Liczba użytkowników z doładowaniem",
        "members": "Liczba nowych użytkowników",
        "NewPeople": "Nowe doładowania użytkowników",
        "Active": "Liczba nowych użytkowników",
        "Level1": "Poziom 1",
        "Level2": "Poziom 2",
        "Level3": "Poziom 3"
      }
    },
    "PlatformIntroduction": {
      "PlatformIntroduction": "Wprowadzenie do platformy",
      "Two": "Ta platforma to sojusz składający się z milionów handlowców. Zasada serwisowa to: handlowcy platformy zwiększają wolumen obrotu, a członkowie platformy zarabiają dochód z zysku poprzez realizację zamówień.",
      "Three": "Zewnętrzna inteligentna chmura kontroluje platformę hosta do dopasowywania zamówień. Platforma współpracuje z innymi sprzedawcami platform zakupów online, aby dopasować zamówienia biznesowe użytkowników platformy za pomocą systemu inteligentnej chmury, aby zwiększyć wolumen transakcji i reputację sprzedawcy na głównej platformie. Sposób dostawy zamówienia jest ulepszany. Zamówienie jest automatycznie wysyłane przez zdalny serwer inteligentnej chmury. Użytkownicy platformy muszą jedynie podać pełny adres odbiorcy, numer telefonu i niektóre informacje o zakupach bezpośrednio na platformie, aby wejść do inteligentnej chmury hosta.",
      "Four": "Aby pomóc sprzedawcom skutecznie zwiększyć skuteczność zamówień i uniknąć ryzyka fałszywych zamówień, platforma zoptymalizuje dopasowywanie zamówień na dany dzień w zależności od aktualnego IP i typu urządzenia. W związku z tym dopasowywanie zamówień odbywa się za pomocą algorytmu obliczeń w chmurze. Proszę cierpliwie czekać. Użytkownicy platformy automatycznie stają się agentami platformy poprzez promowanie nowych użytkowników, którzy dołączają do platformy, co pozwala na uzyskanie prowizji za promocję. Nowi użytkownicy również mogą uzyskać dodatkowe nagrody za pomocą różnych promocji."
    },
    "RuleDescription": {
      "RuleDescription": "Opis zasad",
      "One": "1. Członkowie muszą wypełnić adres odbiorcy i zaktualizować dane osobowe przed przyjęciem zamówienia. Prowizje członków platformy różnią się w zależności od zadań. Ilość zamówień generowanych przez system wynosi od 30% do 70% salda konta.",
      "Two": "2. Ponieważ realizacja zamówienia na towar wymaga pewnego czasu, zamówienie może zostać przyjęte przez sprzedawcę dopiero po jego realizacji. W związku z tym platforma, bank tripartitny i członkowie korzystają z systemu T+1. Oznacza to, że pieniądze zostaną wypłacone dzisiaj i zostaną odebrane następnego dnia przed 23:59. Jeśli zamówienie nie zostanie zrealizowane w wyznaczonym czasie, platforma dokona wypłaty zaliczki, rozliczy konto i zrealizuje płatność w wyznaczonym terminie.",
      "Three": "3. Warunki dostępu do sali i stawki prowizji: Członek srebrny: Wszyscy członkowie Srebrni mogą przyjąć 10 zamówień dziennie, a prowizja za te zamówienia wynosi 0,30% wartości towaru.",
      "Four": "Członek złoty: Aby uzyskać członkostwo Złote, musisz zaprosić do sali 3 osoby. Możesz przyjąć 35 zamówień dziennie, a prowizja za zamówienia wynosi 0,35% wartości towaru.",
      "Five": "Członek platynowy: Aby uzyskać członkostwo Platynowe, musisz zaprosić do sali 10 osób. Możesz przyjąć 40 zamówień dziennie, a prowizja za te zamówienia wynosi 0,40% wartości towaru.",
      "Six": "Członek platynowy: Aby uzyskać członkostwo Platynowe, musisz zaprosić do sali 10 osób. Możesz przyjąć 40 zamówień dziennie, a prowizja za te zamówienia wynosi 0,40% wartości towaru.",
      "Seven": "Członek diamentowy: Aby uzyskać członkostwo Diamentowe, musisz zaprosić do sali 30 osób. Możesz przyjąć 45 zamówień dziennie, a prowizja za te zamówienia wynosi 0,45% wartości towaru.",
      "Eight": "4. Proszę sprawdzić numer karty wpłat platformy przed jej uzupełnieniem, ponieważ platforma zmienia numer konta nieregularnie. Platforma nie ponosi odpowiedzialności za straty majątkowe spowodowane błędami operacyjnymi.",
      "Nine": "Uwaga: Przed każdą transakcją prosimy o sprawdzenie, czy informacje o karcie bankowej należą do tej platformy, aby uniknąć strat finansowych.",
      "Ten": "5. W celu ochrony zysków sprzedawcy i platformy każdy IP, numer karty bankowej i numer telefonu może być powiązany tylko z jednym kontem. Wszystkie główne platformy e-commerce posiadają mechanizmy kontroli ryzyka. Jeśli wielu użytkowników w tym samym obszarze i na tym samym IP robi zakupy w tym samym sklepie, spowoduje to usunięcie produktów ze sklepu i wpłynie na jego wiarygodność oraz sprzedaż.",
      "Eleven": "Jeśli ktoś zostanie przyłapany na zakładaniu wielu kont, jego konto zostanie trwale zamrożone, a saldo zamrożone na 30 dni. Aby wypłacić zamrożone saldo po upływie okresu blokady, prosimy o kontakt z obsługą klienta platformy w celu aktywacji konta.",
      "OneData": {
        "One": "Wprowadzenie do zadań:",
        "Two": "Rodzaj zadania             Stawka prowizji",
        "Three": "Lazada                    0,30%",
        "Four": "Bukalapak                 0,35%",
        "Five": "Shopee                    0,40%",
        "Six": "Tokopedia                0,45%"
      },
      "TwoData": {
        "One": "Opis poziomów członkostwa:",
        "Two": "Rodzaj członkostwa            Członek srebrny",
        "Three": "Limit wypłat                    50.000.000",
        "Four": "Ilość zamówień                30/dzień",
        "Five": "Stawka prowizji                0,30%",
        "Six": "Zaproszenia                        -"
      },
      "FourData": {
        "One": "Rodzaj członkostwa            Członek platynowy",
        "Two": "Limit wypłat                    50.000.000",
        "Three": "Ilość zamówień                40/dzień",
        "Four": "Stawka prowizji                0,40%",
        "Five": "Zaproszenia                        10"
      },
      "FiveData": {
        "One": "Rodzaj członkostwa            Członek diamentowy",
        "Two": "Limit wypłat                    50.000.000",
        "Three": "Ilość zamówień                45/dzień",
        "Four": "Stawka prowizji                0,45%",
        "Five": "Zaproszenia                        30"
      },
      "Twelve": "*Różne poziomy członkostwa mogą uzyskiwać różną liczbę zamówień i różne stawki prowizji. Im wyższy poziom członkostwa, tym większa liczba zamówień dziennie i tym wyższa prowizja."
    },
    "AgentCooperation": {
      "AgentCooperation": "Współpraca z agentem",
      "One": "Wprowadzenie trójstopniowego standardu dla pełnomocników",
      "Two": "Członkowie mogą stać się pełnomocnikami platformy, polecając nowych członków, którzy dołączą, aby uzyskać dodatkowe nagrody.",
      "Three": "Promowanie użytkowników pierwszego poziomu bezpośrednio daje bonusową prowizję w wysokości 9% dziennie. Nagroda za użytkowników drugiego poziomu to bonusowa prowizja 6% dziennie. Nagroda za użytkowników trzeciego poziomu to bonusowa prowizja 3% dziennie.",
      "Four": "Przykład:",
      "Five": "Twój pełnomocnik pierwszego poziomu zaprosił 10 osób do dołączenia jako członkowie, pełnomocnik drugiego poziomu zaprosił 20 osób do dołączenia, a pełnomocnik trzeciego poziomu zaprosił 40 osób. Dochód na osobę za jeden dzień wynosi 200.000 INR, więc wyobraź sobie, ile możesz zarobić dziennie.",
      "Six": "Poziom pierwszy: 200.000 x 10 x 9%  = 180.000 INR",
      "Seven": "Poziom drugi: 200.000 x 20 x 6%  = 240.000 INR",
      "Eight": "Poziom trzeci: 200.000 x 40 x 3%  = 240.000 INR",
      "Nine": "Poziom pierwszy  +  Poziom drugi  +  Poziom trzeci  =  660.000 INR",
      "Ten": "Załóżmy, że zaprosisz członka A do rejestracji, A zaprasza B, a B zaprasza C,",
      "Eleven": "Następnie członek A jest użytkownikiem pierwszego poziomu w twoim zespole, B jest użytkownikiem drugiego poziomu, a C jest użytkownikiem trzeciego poziomu.",
      "Twelve": "Suma nagród = prowizja z zamówień A x 9% + prowizja z zamówień B x 6% + prowizja z zamówień C x 3%.",
      "Thirteen": "*Przypomnienie: Nagrody prowizyjne dla wszystkich agentów, którzy są członkami offline, są wydawane przez tę platformę i nie wpływają na prowizję wszystkich agentów i członków offline!"
    },
    "CommonProblem": {
      "CommonProblem": "Często zadawane pytania",
      "One": "P: Dlaczego firmy potrzebują zamówień wirtualnych?",
      "Two": "Zgodnie z zasadami głównych platform e-commerce, im więcej osób kupuje produkty, tym bardziej są one popularne i tym większe szanse, że zostaną promowane i wyświetlane na platformie. Konkurencja w e-commerce jest zażarta. Aby zwiększyć widoczność produktów, sprzedawcy muszą zwiększyć wolumen transakcji towarów, dlatego wybierają generowanie zamówień wirtualnych na platformie.",
      "Three": "P: Jak zrealizować proces przyjmowania zamówień?",
      "Four": "Wybierz kanał e-commerce, kliknij, aby automatycznie przyjąć zamówienie, system inteligentnie dopasowuje produkty do zamówienia. Platforma korzysta z rzeczywistych danych osobowych, adresu dostawy i IP sieci do składania zamówień na platformie e-commerce, symulując zakupy online. Jednak sprzedawcy nie wysyłają faktycznie swoich towarów. Po pomyślnym złożeniu zamówienia kwota zamówienia i prowizja zostaną natychmiast zwrócone na twoje konto.",
      "Five": "P: Dlaczego muszę dokonać wpłaty przed przyjęciem zamówienia?",
      "Six": "Aby zapewnić autentyczność zamówienia, każda symulowana platforma zamówień wymaga rzeczywistej płatności na stronie e-commerce. Aby chronić interesy platformy i sprzedawców, saldo twojego konta zostanie pomniejszone w momencie złożenia zamówienia i zostanie natychmiast zwrócone po zakończeniu zamówienia.",
      "Seven": "P: Jak zwiększyć liczbę przyjmowanych zamówień i prowizję?",
      "Eight": "Zapraszając znajomych do rejestracji, im wyższy poziom, tym większa liczba zamówień dziennie i tym wyższa stawka prowizji. System automatycznie dopasowuje ilość zamówień od 30% do 70% salda konta, dlatego im większe saldo konta, tym większe zamówienie i tym wyższa prowizja.",
      "Nine": "P: Jak zaprosić znajomych do rejestracji?",
      "Ten": "Kliknij \"Zaproś znajomych\", skopiuj link do udostępnienia lub kod QR i wyślij go znajomym, kolegom z klasy, współpracownikom i rodzinie za pomocą oprogramowania społecznościowego. Musisz podać kod zaproszenia podczas rejestracji na tej platformie, a druga strona stanie się członkiem twojego zespołu po zakończeniu rejestracji.",
      "Eleven": "P: Jak wypłacić gotówkę?",
      "Twelve": "Możesz złożyć wniosek o wypłatę gotówki po spełnieniu minimalnej liczby zamówień. Jeśli minimalna liczba zamówień nie została osiągnięta, kontynuuj składanie zamówień, aż spełnisz wymagania. Po zatwierdzeniu wypłaty platforma przekaże środki na powiązane konto bankowe, a czas odbioru zależy od banku."
    },
    "MemberUpgrade": {
      "MemberUpgrade": "Ulepszenie członkostwa",
      "Member": "Poziom członkostwa:",
      "Orders": "Dostępne zamówienia dziennie:",
      "Commission": "Wzrost zysku",
      "DedicatedOne": "Dedykowana obsługa klienta",
      "DedicatedTwo": "Dedykowana obsługa klienta",
      "Swipe": "Przesuń w lewo i prawo, aby zobaczyć więcej",
      "One": {
        "Silver": "Członek srebrny",
        "Need": "Musisz zaprosić 0 osób",
        "Withdrawal": "Limit wypłat: 50.000.000",
        "quantity": "Liczba zamówień: 10/dzień",
        "rate": "Stawka zysku: 0,30%",
        "fee": "Opłata za wypłatę: 2,00%",
        "Activated": "Aktywowany"
      },
      "Two": {
        "Silver": "Członek złoty",
        "Need": "Musisz zaprosić 3 osoby",
        "People": "Brakuje jeszcze 3 osób",
        "Withdrawal": "Limit wypłat: 50.000.000",
        "quantity": "Liczba zamówień: 35/dzień",
        "rate": "Stawka zysku: 0,35%",
        "fee": "Opłata za wypłatę: 2,00%",
        "Activated": "Codzienna kwota doładowania: 1.000.000",
        "Invite": "Zaproś teraz"
      },
      "Three": {
        "Silver": "Członek platynowy",
        "Need": "Musisz zaprosić 10 osób",
        "People": "Brakuje jeszcze 10 osób",
        "Withdrawal": "Limit wypłat: 50.000.000",
        "quantity": "Liczba zamówień: 40/dzień",
        "rate": "Stawka zysku: 0,40%",
        "fee": "Opłata za wypłatę: 2,00%",
        "Activated": "Codzienna kwota doładowania: 1.500.000",
        "Invite": "Zaproś teraz"
      },
      "Four": {
        "Silver": "Członek diamentowy",
        "Need": "Musisz zaprosić 30 osób",
        "People": "Brakuje jeszcze 30 osób",
        "Withdrawal": "Limit wypłat: 50.000.000",
        "quantity": "Liczba zamówień: 45/dzień",
        "rate": "Stawka zysku: 0,45%",
        "fee": "Opłata za wypłatę: 2,00%",
        "Activated": "Codzienna kwota doładowania: 2.000.000",
        "Invite": "Zaproś teraz"
      }
    },
    "PhoneNew": "Proszę potwierdzić, że skończyłeś",
    "Toast": {
      "One": "Zapisano pomyślnie",
      "Two": "Nieudane"
    },
    "Button": {
      "One": "Anuluj",
      "Two": "Potwierdź"
    }
  },
  "Record": {},
  "Grab": {},
  "Financing": {
    "time": "Czas",
    "day": "Dzień",
    "Fina": "Fundusze",
    "assets": "Łączne aktywa",
    "amount": "Kwota finansowa",
    "revenue": "Łączne przychody",
    "earnings": "Zyski z wczoraj",
    "Balance": "Transfer salda",
    "RP": " ",
    "Please": "Proszę wprowadzić tran",
    "Estimated": "Szacowany dochód",
    "Income": "Standard dochodów",
    "TransferOut": "Przelew na zewnątrz",
    "ransferIn": "Przelew do",
    "SukuBung": "Suku bunga",
    "Transfer": "Przelew nieudany",
    "Pleaseint": "Proszę wprowadzić kwotę",
    "Successful": "Udany przelew",
    "RansferInSU": {
      "FinancialAcc": "Rejestr dostępu finansowego",
      "Acc": "Dziennik dostępu"
    },
    "ServiceSu": {
      "Please": "Proszę wprowadzić...",
      "send": "Wyślij",
      "Online": "Usługa online",
      "Service": "Serwis",
      "Customer": "Obsługa klienta",
      "Ifyou": "Jeśli napotkasz problemy i potrzebujesz pomocy, skontaktuj się z obsługą klienta online",
      "Layanana8": "Centrum obsługi klienta nr 8 (WhatsApp)",
      "Layanana9": "Centrum obsługi klienta nr 9 (WhatsApp)",
      "Dedicated": "Dedykowana obsługa klienta",
      "Telegram8": "Obsługa klienta nr 8 (Telegram)",
      "Telegram008": "Telegram008",
      "Telegram9": "Obsługa klienta nr 9 (Telegram)",
      "Telegram009": "Telegram009"
    }
  },
  "Home": {
    "HallofFame": "Galeria Sław",
    "Member": "Wiadomości członkowskie",
    "Mission": "Sala Misji",
    "Learnde": "Ucz się",
    "Hello": "Cześć",
    "Let": "Niech Victory Mall otworzy drogę do bogactwa",
    "total": "Moje łączne aktywa",
    "Withdraw": "Wypłata",
    "Recharge": "Doładowanie",
    "RP": " ",
    "earningsRp": "Zyski z wczoraj",
    "incomeRp": "Łączny dochód",
    "rarningsRp": "Dzisiejsze zyski",
    "Service": "Serwis",
    "Invite": "Zaproś",
    "Lazada": "Lazada",
    "Bukalapak": "Bukalapak",
    "Shopee": "Shopee",
    "Tokopedia": "Tokopedia",
    "silver": "Kanał dla członków srebrnych",
    "gold": "Kanał dla członków złotych",
    "platinum": "Kanał dla członków platynowych",
    "diamond": "Kanał dla członków diamentowych",
    "Agent": "Zysk agenta z zamówienia",
    "Order": "Zysk z zamówienia",
    "RechargeSu": {
      "Recharge": "Doładowanie",
      "Catatan": "Specjalna uwaga:",
      "Next": "Następny",
      "RechargeAm": "Kwota doładowania",
      "Enter": "Wprowadź kwotę doładowania",
      "Quick": "Szybki wybór",
      "nameP": "Proszę podać swoje prawdziwe imię",
      "name": "Imię",
      "Pleaseamount": "Proszę wprowadzić kwotę",
      "Pleaseint": "Proszę wprowadzić kwotę",
      "Pleasename": "Proszę podać swoje imię"
    },
    "WithdrawSu": {
      "Withdraw": "Wypłata",
      "Withdrawal": "Kwota wypłaty",
      "WithdrawalRe": "Stawka wypłaty",
      "Input": "Wprowadź kwotę wypłaty",
      "Balance": "Saldo",
      "WithdrawAll": "Wypłać wszystko",
      "Mobile": "Numer telefonu komórkowego",
      "Bank": "Numer karty bankowej",
      "Owned": "Bank właściciela",
      "Account": "Nazwa konta",
      "Transaction": "Hasło transakcji",
      "Withdrawim": "Wypłać natychmiast",
      "Pleaseamount": "Proszę wprowadzić kwotę",
      "Pleasephone": "Proszę wprowadzić swój numer telefonu",
      "Pleasecard": "Proszę wprowadzić numer swojej karty bankowej",
      "Pleasebank": "Proszę wprowadzić nazwę swojego banku",
      "Pleasename": "Proszę podać swoje imię",
      "Pleasepassword": "Proszę wprowadzić hasło do transakcji",
      "Pleaseint": "Proszę wprowadzić kwotę",
      "PasswordError": "Błędne hasło",
      "Successful": "Wniosek o wypłatę został złożony",
      "Insufficient": "Niewystarczające saldo do wypłaty",
      "Unbound": "Karta bankowa nie została powiązana",
      "Binding": "Powiąż swoją kartę bankową",
      "idCard": "Numer karty identyfikacyjnej"
    }
  },
  "Login": {
    "indexTitle": "Logowanie",
    "remenber": "Zapamiętaj hasło",
    "versions": "W.86",
    "masuk": "Zaloguj się",
    "name": "Pobierz aplikację",
    "forgot": "Zapomniałeś",
    "registrasi": "Rejestracja",
    "loginButton": "Zaloguj się",
    "nameInput": "Wprowadź numer telefonu",
    "pwdInput": "Wprowadź swoje hasło",
    "notLoginHint": "Zaloguj się",
    "goLoginText": "potwierdź",
    "Forgot": {
      "title": "Przywróć hasło",
      "registerTitle": "Rejestracja",
      "phone": "Wprowadź swój numer telefonu",
      "code": "Wprowadź kod weryfikacyjny",
      "area": "Proszę wprowadzić kod kierunkowy",
      "sendCode": "wyślij kod",
      "password": "Hasło",
      "againPassword": "Potwierdź hasło",
      "confirm": "Wyślij",
      "goBack": "Masz już konto, Zaloguj się teraz",
      "register": "Zarejestruj się"
    },
    "Daftar": {
      "username": "Nazwa użytkownika",
      "title": "Rejestracja",
      "area": "Proszę wprowadzić kod kierunkowy",
      "phone": "Wprowadź numer telefonu",
      "code": "Wprowadź kod weryfikacyjny",
      "userName": "Pseudonim",
      "password": "Hasło",
      "againPassword": "Potwierdź hasło",
      "payPassword": "Hasło płatności",
      "referrer": "Kod zapraszający (ID)"
    }
  },
  "Cart": {
    "title": "Sklep z zamówieniami",
    "explain": "Opis",
    "grade": {
      "SliverMember": "Srebrny\nCzłonek",
      "GoldMember": "Złoty\nCzłonek",
      "PlatinumMember": "Platynowy\nCzłonek",
      "DiamondMember": "Diamentowy\nCzłonek"
    },
    "info": {
      "allMoney": "Moje łączne\n aktywa",
      "dayAllOrder": "Dzisiejsze\n łączne zamówienie",
      "dayTrustMoney": "Dzisiejsza\n prowizja",
      "yesterdayMoney": "Wczorajsze\n dochody"
    },
    "text": {
      "rebateAndPrincipal": "Zwrot\n kapitału\n i\n Zysk",
      "residue": "Pozostałe",
      "one": "Zamówienie",
      "rebate": "Zwrot zysku",
      "buyBtn": "Kup teraz",
      "toGet": "Będzie można otrzymać",
      "orderSuccess": "Zamówienie zakończone sukcesem",
      "orderGetMoney": "Płatność zakończona sukcesem",
      "orderWaiting": "Oczekiwanie",
      "orderOver": "Zakończone"
    },
    "state": {
      "all": "Wszystkie",
      "going": "W trakcie",
      "paid": "Opłacone",
      "frozen": "Zamrożone",
      "over": "Zakończone"
    },
    "zzInfo": {
      "buyIng": "Zakup",
      "buySucceedFindDT": "Kliknij status, aby zobaczyć po zakończeniu zakupu",
      "ok": "OK",
      "buyFailed": "Zakup nieudany",
      "notLevel": "Twój poziom VIP jest niewystarczający",
      "buyJurisdiction1": "Aby kupić zamówienie członkostwa złotego",
      "buyJurisdiction2": "Aby kupić zamówienie członkostwa platynowego",
      "buyJurisdiction3": "Aby kupić zamówienie członkostwa diamentowego",
      "meet": "Należy spełnić jeden z poniższych warunków",
      "invite1": "Zaprosić 3 znajomych, aby zarabiać razem",
      "invite4": "Zaprosić 10 znajomych, aby zarabiać razem",
      "invite3": "Zaprosić 30 znajomych, aby zarabiać razem",
      "buySendMoney": "2. Wydaj Rs2000 na zakup członkostwa diamentowego",
      "invite2": "Zapraszaj znajomych",
      "buy": "Kup teraz",
      "myMoney": "Saldo na koncie",
      "under": "Poniżej",
      "notSendMoney": "Nie można przeprowadzić transakcji",
      "goTopUp": "Proszę doładować konto",
      "know": "Rozumiem",
      "account": "Rozliczenie"
    },
    "hint": {
      "p1": "1. Różne poziomy członkostwa mają różne zamówienia do zakupu",
      "p2": "2. Im wyższy poziom członkostwa, tym wyższa kwota zamówienia, którą można zakupić",
      "p3": "3. Dla zakupionych zamówień można monitorować status zamówienia w dowolnym momencie"
    }
  },
  "Hint": {
    "SendCodeSuccess": "Kod weryfikacyjny został pomyślnie wysłany!",
    "closed": "Zamknięte",
    "notTime": "Nie wybrano czasu",
    "notTimeFailed": "Nie udało się wybrać czasu",
    "success": "Sukces",
    "failed": "Niepowodzenie",
    "phoneNot": "Numer telefonu / Numer konta nie może być pusty",
    "pwdInconformity": "Hasło się nie zgadza",
    "notMoney": "Niewystarczająca ilość pieniędzy",
    "notEvent": "Funkcja jest jeszcze niedostępna",
    "notInput": "Niekompletne dane wejściowe",
    "loading": "Ładowanie",
    "notMore": "Brak więcej",
    "notPhone": "Konto nie istnieje",
    "yesPhone": "Konto już istnieje",
    "levelNot": "Poziom niedostępny",
    "serverMaintenance": "Proszę o cierpliwość, gra jest w trakcie konserwacji",
    "invite": "Zaproś znajomych\n do zarabiania\n razem",
    "goodsNumNo": "Niewystarczająca ilość",
    "haveBuy": "Kupiono",
    "depositBtnText1": "Odebrane",
    "depositBtnText2": "Dostępne",
    "depositBtnText3": "Odbierz",
    "commissionMoney": "Zwrot zysku:",
    "bill": "faktura",
    "billRecordTitle": "Rekordy faktur",
    "balance": "saldo",
    "reference": "Wklej kod zaproszenia",
    "updateText": "Proszę zaktualizować do najnowszej wersji"
  },
  "newAdd": {
    "all": "wszystkie",
    "Completed": "ukończone",
    "unfinished": "niedokończone",
    "GetCodeBtn": "pobierz kod weryfikacyjny",
    "copySuccess": "Kopiowanie udane!",
    "copyError": "Błąd kopiowania!",
    "noLoginUserName": "Zaloguj się proszę",
    "Withdraw": "Wypłata",
    "Recharge": "Doładuj",
    "commission": "prowizja z koszyka",
    "single": "pojedyncze",
    "missionHall": "Hala misji",
    "videoTutorial": "Film instruktażowy",
    "helpCenter": "Centrum pomocy",
    "inviteFriends": "Zaproś przyjaciół",
    "play": "Graj teraz",
    "help1Title": "Jak zarabiać prowizje z Mudo",
    "help1Context": "Właściciele sklepów e-commerce muszą zwiększyć sprzedaż w sklepie, więc wystarczy spełnić wymagania sklepu e-commerce i zrealizować zamówienie w oprogramowaniu Mudo. Właściciel przekaże nam zysk. Każdy użytkownik może otrzymać 10 zadań zamówień dziennie. Możesz zarobić od 10% do 30% zysku w ciągu jednego dnia.",
    "help2Title": "Jak zwiększyć liczbę misji",
    "help2Context": "Ponieważ jest zbyt wielu pracowników dorywczych, firma nakazuje, aby wszyscy pracownicy dorywczy kontaktowali się i otrzymywali misje wyłącznie za pośrednictwem WhatsApp.",
    "help3Title": "Jak ukończyć misję",
    "help3Context": "Pomoc głównym sprzedawcom w zwiększaniu sprzedaży, a także nagradzanie cię w zależności od liczby transakcji. Za każde udane zamówienie zarabiasz prowizję, a sprzedawcy zdobywają reputację.",
    "referralLink": "Link polecający:",
    "copy": "skopiuj",
    "paste": "Wklej",
    "blance": "Saldo",
    "todayOrder": "Dzisiejsze zamówienie",
    "provided": "Dane dostarczone przez",
    "Transaction": "Transakcja",
    "Frozen": "Zamrożone",
    "Done": "Zrobione",
    "Cancel": "Anuluj",
    "ExpectedReturn": "Oczekiwany zwrot:",
    "ImmediatePayment": "Natychmiastowa płatność",
    "AvailableNum": "Pozostało",
    "OrderImmediately": "Zobacz zamówienie",
    "MakeMoneyEveryDay": "Zarabiaj codziennie",
    "TodayRecord": "Dzisiejszy rekord",
    "AllocatCommissions": "Przydzielanie prowizji",
    "FrozenAmount": "Kwota zaległa",
    "TaskMatch": "Losowość zadań",
    "AvailableBalance": "Saldo dostępne",
    "WaitDispatch": "Oczekiwanie na wysyłkę, proszę skontaktować się z menedżerem sklepu!",
    "OrderDescription": "Opis zamówienia",
    "CouldMatch": "Zadania są losowo dopasowywane przez system",
    "OrderPerDay": "pojedyncze.",
    "DescriptionRate": "Każde zadanie zamówienia kryptowalut może być losowo wybrane przez system jako zadanie uprzywilejowane, a po zostaniu zadaniem uprzywilejowanym otrzymasz dodatkową prowizję w wysokości 10-30%.",
    "DescriptionPeriod": "kwoty zamówienia.",
    "Description3": "System oparty na technologii IBS automatycznie dopasowuje produkty handlowe za pomocą chmury blockchain. Wpływ na dane dotyczące niedokończonych zadań sprawia, że użytkownicy muszą ukończyć zadania uprzywilejowane przed złożeniem wniosku o wypłatę.",
    "Description4": "Aby zapobiec nadzorowi platformy, jeśli zamówienie nie zostanie potwierdzone i przesłane w ciągu 20 minut, system zamrozi kwotę zamówienia.",
    "Note": "Uwaga:",
    "DescriptionNote": "Proszę dokładnie przeczytać odpowiednie treści: po dopasowaniu zamówień dla użytkowników przez system P2P blockchain, platforma przekaże informacje o zamówieniu do zaplecza sprzedawcy. Na podstawie cech blockchaina, gdy zadanie uprzywilejowane zostanie zaakceptowane, system P2P sprzedawcy automatycznie prześle dane zamówienia uprzywilejowanego do konta rozliczeniowego prowizji Walmart, co nie może być anulowane ani zastąpione. W przypadku innych problemów z niedokończeniem zadania na czas, proszę skontaktować się z obsługą klienta, aby ubiegać się o przedłużenie, aby uniknąć zamrożenia. Dziękujemy za wsparcie!",
    "OrderNum": "Numer zamówienia:",
    "TotalOrderPrice": "Łączna cena zamówienia",
    "SubmitNow": "Złóż teraz",
    "Record": "Rekord",
    "MemberAccount": "Konto członka",
    "AccountPlaceholder": "Proszę wprowadzić swoje konto członka",
    "RechargeAmount": "Kwota doładowania",
    "RechargeAmountPlaceholder": "Proszę wprowadzić kwotę doładowania",
    "SelectRechargeMethod": "Wybierz metodę doładowania",
    "NotBeenSentOrder": "Zamówienie nie zostało wysłane, proszę skontaktować się z pracownikiem, aby wysłać zamówienie!",
    "TodayOrderOver": "Skontaktuj się z obsługą klienta online",
    "NotLogin": "Nie jesteś zalogowany, proszę zalogować się i spróbować ponownie!",
    "HaveNoteMethod": "Ograniczone otwarcie, proszę skontaktować się z zespołem sprzedaży!",
    "Login": "Zaloguj się",
    "AccountRecord": "Dochody i wydatki",
    "RealName": "Prawdziwe imię",
    "IFSCCODE": "Pix CPF",
    "BankName": "Konto Pix",
    "ChainType": "Typ łańcucha",
    "BankNum": "Typ pix",
    "Mobile": "Telefon",
    "MobileNum": "Numer telefonu",
    "Email": "Email",
    "EmailRequired": "Email jest wymagany",
    "AddBankCardNote": "Informacje o wypłacie muszą być wprowadzone poprawnie",
    "AddWalletNote": "Uwaga: Adres portfela jest ważną informacją, proszę wypełnić go dokładnie!",
    "Submit": "Złóż",
    "AddBankCardTitle": "Dodaj Pix",
    "AddWalletTitle": "Dodaj adres portfela",
    "BankCard": "Karta bankowa",
    "WalletInfo": "Informacje o portfelu",
    "Name": "imię",
    "CardNum": "Numer karty (AC ID)",
    "WalletAddress": "Adres",
    "Reading": "jest odczytywane...",
    "AllBalance": "suma sald",
    "EnterWithdrawalAmount": "Proszę wprowadzić kwotę wypłaty",
    "AllOut": "Wypłata całości",
    "AskNow": "Potwierdź",
    "EnterPwdPlaceholder": "Proszę wpisać hasło",
    "NotHaveCard": "Nie ustawiłeś informacji o wypłacie, czy chcesz przejść do ustawień?",
    "NotHaveWallet": "Nie ustawiłeś adresu portfela, czy chcesz przejść do ustawień?",
    "GetBankInfoFail": "Nie udało się pobrać informacji o banku",
    "EnterValidPayPwd": "Proszę wprowadzić prawidłowe hasło płatnicze!",
    "Balance": "Saldo",
    "Total": "Łącznie",
    "total": "całkowity",
    "records": "rekordy",
    "DisplayPerPage": "każda strona wyświetla",
    "NullNum": "",
    "pages": "strony.",
    "toAudit": "do audytu",
    "succeed": "Sukces",
    "RechargeRecordTitle": "Rekord doładowań",
    "rests": "pozostało",
    "Arrive": "Dotarło",
    "Income": "Dochód",
    "Expenditure": "Wydatki",
    "forzenCommission": "nieodebrana prowizja",
    "companyIntro": "Profil firmy",
    "companyTitle": "Tata Mall jest częścią grupy Tata",
    "warmTitle": "Ostrzeżenie",
    "companyText": "Grupa Tata to największa grupa biznesowa w Indiach, założona w 1868 roku z siedzibą w Mumbaju w Indiach. Operacje biznesowe obejmują siedem obszarów: telekomunikację i technologie informacyjne, inżynierię, materiały, usługi, energię, produkty konsumpcyjne i produkty chemiczne. Jako szybko rozwijająca się grupa biznesowa w Indiach, Grupa Tata ma ponad 200 spółek zależnych, działających w ponad 80 krajach na 6 kontynentach, i eksportuje produkty oraz usługi do 85 krajów. Całkowite przychody Grupy Tata za rok finansowy 2020-2021 wyniosły 100,09 miliarda USD, z czego 58% pochodzi z operacji zagranicznych. Liczba pracowników Grupy na całym świecie wynosi około 450 000. Od 153 lat nazwa Tata w Indiach jest szeroko szanowana za przestrzeganie dobrych wartości i etyki biznesowej. W rankingu Forbes zajmuje 11. miejsce wśród najbardziej szanowanych firm na świecie.",
    "depositAddress": "Adres wpłaty",
    "rechargeSteps": "Kroki doładowania",
    "completePayment": "Zakończ płatność",
    "rechargeNow": "Doładuj teraz",
    "rechargeCancel": "Anuluj płatność",
    "bankName": "Nazwa banku",
    "payee": "Odbiorca",
    "cardNum": "Numer karty bankowej",
    "reviewing": "W trakcie przeglądu",
    "remittance": "Udana",
    "notThrough": "Nieudana",
    "OrderID": "ID zamówienia",
    "Amount": "kwota",
    "toCutScreen": "Proszę zrobić zrzut ekranu, jeśli obraz jest niedostępny!",
    "cardTabBank": "Bank",
    "cardTabUSDT": "USDT",
    "name": "Imię",
    "chainType": "Typ łańcucha",
    "newNavTitle": "Złóż zamówienie",
    "shopMoney": "Kwota towaru",
    "actualMoney": "Rzeczywiste złożenie",
    "commitBtnText": "Generuj zamówienia",
    "commitHint": "Niekompletne dane wejściowe"
  },
  "mine": {
    "servier": "Serwer",
    "integral": "Integral",
    "balance": "Wykup",
    "record": "Rekord",
    "wtdAmt": "Punkty wykupu",
    "avaBlc": "Ilość wymiany",
    "allWtd": "Wszystko",
    "close": "Anuluj",
    "commit": "Potwierdź",
    "wtdHint": "Proszę wpisać poprawną kwotę wypłaty"
  },
  "add": {
    "Commission": "Nieuregulowana kwota dzisiaj",
    "Yesterday": "Wczoraj",
    "Cumulative": "Skumulowane",
    "Today": "Dziś",
    "TeamNum": "Całkowity rozmiar zespołu",
    "TeamMoney": "Całkowity zysk zespołu",
    "Personalcenteraccountchangerecord": "Rekord zmiany konta",
    "lockedbalance": "Kwota zaległa",
    "Cumulativeinvitation": "Skumulowane zaproszenia",
    "Validinvitation": "Ważne zaproszenia",
    "Accumulatedrebate": "Skumulowany rabat"
  },
  "home.btn.wallet": "Środki",
  "home.btn.recharge": "Złóż",
  "home.btn.withdraw": "Wypłata",
  "home.btn.invite": "Zaproś",
  "home.btn.message": "Wiadomość",
  "page.grab": "Sprawdź",
  "orderCommission": "Zysk z zamówienia",
  "commission": "Zysk",
  "todayIncome": "Dochód dzisiaj",
  "personalEarnings": "Zarobki osobiste",
  "completedOrders": "Ukończone zamówienia",
  "lockedOrders": "Zamówienia zablokowane",
  "unfinishOrder": "Niedokończone zamówienia",
  "frozonAmount": "Środki do rozliczenia",
  "currentBalance": "Bieżące saldo",
  "page.mine": "Szczegóły konta",
  "mine.invitationCode": "ID pracownika",
  "mine.serverCenter": "Centrum serwisowe",
  "mine.tools.total": "Całkowite",
  "mine.tools.financial": "Środki",
  "mine.tools.details": "Szczegóły",
  "mine.tools.freeze": "Saldo",
  "mine.serviceTools.personal": "Osobiste",
  "mine.serviceTools.teamReport": "Raport zespołu",
  "mine.serviceTools.invite": "Konto rodzinne",
  "mine.serviceTools.message": "Wiadomość",
  "mine.serviceTools.aboutUs": "O nas",
  "mine.serviceTools.security": "Bezpieczeństwo",
  "mine.serviceTools.address": "Adres",
  "mine.serviceTools.inviteTask": "Zadanie zapraszające",
  "page.team": "Zespół",
  "page.aboutus": "O nas",
  "page.financial": "Środki",
  "page.financialRecord": "Rekord finansowy",
  "hint.InternetErr": "Błąd sieci, proszę spróbować później!",
  "totalDeposit": "Łączna wpłata",
  "totalRevenue": "Łączne przychody",
  "btn.transferInto": "Przenieś do",
  "btn.transferOut": "Przenieś z",
  "page.transferInto": "Przenieś do",
  "page.transferOut": "Przenieś z",
  "balanceTransfer": "Transfer salda",
  "availableBalance": "Dostępne saldo",
  "depositRate": "Stawka wpłaty",
  "days": "dni",
  "hint.success": "Sukces",
  "hint.fail": "Nieudane",
  "status.into": "Przeniesiono do",
  "status.out": "Przeniesiono z",
  "label.amount": "Kwota",
  "label.time": "Czas",
  "Button": {
    "cancle": "Anuluj",
    "confirm": "Potwierdź"
  },
  "dialog.title.inputPaypass": "Wprowadź hasło płatności",
  "dialog.nopaypass": "Hasło płatności nie zostało ustawione, kliknij OK, aby przejść do ustawień!",
  "mine.serviceTools.lan": "Język",
  "checkLans": "Zmień język",
  "mine.tools.commission": "Zysk",
  "page.commissionRecord": "Zysk",
  "noticeList.all": "Wszystko",
  "noticeList.unread": "Nieprzeczytane",
  "successWithdrawof": "pomyślna wypłata",
  "home.title.notice": "Ogłoszenie",
  "home.title.patener": "We współpracy z",
  "login.rememberPassword": "Zapamiętaj hasło",
  "page": {
    "cart": "Koszyk",
    "order": "Zamówienie",
    "financing": "Finansowanie",
    "goods": "Towary",
    "levelUpgrade": "Podniesienie poziomu",
    "PlatformIntroduction": "Wprowadzenie do platformy",
    "RuleDescription": "Opis zasad",
    "AgentCooperation": "Współpraca z agentami",
    "CommonProblem": "Najczęstsze pytania",
    "MemberUpgrade": "Uaktualnienie członkostwa",
    "login": "Logowanie",
    "forgot": "Zapomniałeś",
    "registrasi": "Rejestracja",
    "record": "Rekord",
    "Recharge": "Złóż",
    "inviteFriends": "Zaproś znajomych",
    "Withdraw": "Wypłata",
    "Service": "Serwis",
    "DialoguePage": "Strona dialogowa",
    "user": "Osobiste",
    "RansferIn": "Transfer do",
    "PersonalCenter": "Centrum osobiste",
    "Portrait": "Portret",
    "Name": "Imię",
    "Account": "Konto",
    "Password": "Hasło",
    "PaymentCode": "Kod płatności",
    "Site": "Strona",
    "AccountData": "Dane konta",
    "RechargeRecord": "Rekord doładowań",
    "WithdrawalRecord": "Rekord wypłat",
    "InviteFriends": "Zaproś znajomych",
    "Announcement": "Ogłoszenie",
    "TheTeamReport": "Raport zespołu",
    "BillRecord": "Rekord faktur",
    "BankCardAdd": "Dodaj kartę bankową",
    "NewRecharge": "Doładuj",
    "RechargeList": "Lista doładowań",
    "newWithdraw": "Wypłata",
    "withdrawList": "Lista wypłat",
    "SettingPayPwd": "Ustawienie hasła płatności",
    "MakeMoney": "Zarabiaj",
    "team": "zespół",
    "qrPay": "płatność QR",
    "bankPay": "płatność bankowa",
    "aboutus": "O nas",
    "financial": "Środki",
    "financialRecord": "Rekord finansowy",
    "TransferInto": "Transfer do",
    "TransferOut": "Transfer z",
    "CommissionRecord": "Zysk",
    "Security": "Bezpieczeństwo",
    "Address": "Adres",
    "Message": "Wiadomość",
    "InviteTask": "Zadanie zapraszające",
    "Notice": "Ogłoszenie",
    "modifyPaymentPassword": "Zmień hasło płatności"
  },
  "copyLink": "Kopiuj link",
  "teamReport": {
    "title": "Raport zespołu",
    "subTitle": "Przegląd zespołu podległego",
    "taskTitle": "Zaproś {num} osób do zapisania się i ukończenia zamówienia",
    "bonus": "Premia",
    "Unfinished": "Niedokończone",
    "Getted": "Otrzymane"
  },
  "copy": "Kopiuj",
  "address": {
    "name": "Imię",
    "inputPlaceholder": "Proszę wypełnić",
    "phone": "Telefon",
    "areaPlaceholer": "Informacje o adresie dostawy",
    "submit": "Zapisz"
  },
  "password.old": "stare hasło",
  "password.old.placeholder": "wprowadź swoje stare hasło",
  "password.confirmPassword": "potwierdź hasło",
  "password.confirmPassword.placeholder": "wprowadź nowe hasło ponownie",
  "hint.passwordDiff": "Hasła się nie zgadzają",
  "loginType": {
    "phone": "Telefon",
    "email": "Email"
  },
  "Login.Daftar.email": "Email",
  "Login.emailInput": "Wprowadź swój email",
  "My.PersonalInformation.Password.email": "email",
  "My.PersonalInformation.Password.NewEmail": "Proszę wprowadzić nowy email",
  "mine.serviceTools.certify": "Weryfikacja",
  "Certification": {
    "type": {
      "id": "Dowód osobisty ( RG )",
      "driver": "Prawo jazdy",
      "passport": "Paszport"
    },
    "name": "Imię",
    "license": "Numer licencji",
    "upload": "Załącznik dokumentu",
    "submit": "Prześlij",
    "incompleteInformation": "Proszę wypełnić wszystkie informacje i spróbować ponownie!",
    "submitSuccess": "Informacje o certyfikacji zostały przesłane, proszę czekać na recenzję!",
    "photo": "Zdjęcie ID",
    "review": "w trakcie weryfikacji",
    "verified": "zweryfikowane"
  },
  "upload": {
    "uploading": "przesyłanie...",
    "failed": "przesyłanie nie powiodło się"
  },
  "login.forgetPassword": "Zapomniałeś hasła, proszę skontaktuj się z obsługą klienta!",
  "newAdd.BankNum.placeholder": "Przykład: 09XXXXXXXXX",
  "page.certification": "WERYFIKACJA TOŻSAMOŚCI",
  "newAdd.page": "strona",
  "newAdd.record": "rekord",
  "emailFormatError": "Proszę wprowadzić poprawny adres email!",
  "urlParsePlaceholder": "Proszę wkleić identyfikator",
  "btn.Identify": "Identyfikuj natychmiast",
  "produceStatus.confirming": "Do potwierdzenia",
  "produceStatus.paying": "Do zapłaty",
  "produceStatus.paid": "Zapłacono",
  "produceStatus.done": "Zrobione",
  "produceStatus.cancle": "Zwrócone",
  "order.old": "Zamówienie o wysokich dochodach",
  "order.new": "Zamówienie standardowe",
  "welfareDialogTitle": "Powiadomienie systemowe",
  "viGrabDesces": {
    "1": "Każde zamówienie może być wybrane jako szczęśliwe zamówienie, a prowizja zostanie zwiększona do {rate}%. Proszę skontaktować się z mentorem w celu dalszych zadań.",
    "2": "Pomoc sprzedawcom Shopee w tworzeniu zamówień wirtualnych, zwiększanie widoczności i zarabianie prowizji.",
    "3": "Proszę jak najszybciej skontaktować się z mentorem promocyjnym po otrzymaniu zamówienia, przesłać dane zamówienia i ukończyć zadanie.",
    "4": "Liczba zadań jest ograniczona, proszę zwrócić uwagę na sugestie promocyjne instruktora przy przyjmowaniu zamówień.",
    "5": "Kwota zwrotu zostanie wydana w ciągu 5-10 minut po zakończeniu zadania. W przypadku jakichkolwiek pytań proszę skonsultować się z ekspertem lub obsługą klienta online.",
    "6": "Wykonuj zadania przypisane przez Shopee, poprawiaj poziom VIP i uzyskuj wyższe prowizje za zadania na Facebooku.",
    "7": "Kumulatywne wykonanie wyznaczonych zadań Shopee pozwala uzyskać dodatkowe prowizje. W celu uzyskania szczegółowych informacji proszę skonsultować się ze specjalistą ds. promocji lub obsługą klienta online."
  },
  "expectedEarningsTitle": "Oczekiwane zarobki",
  "rechargeDesc": {
    "nav": "Opis doładowania",
    "p1": "Obecnie mamy wielu pracowników wykonujących zadania jednocześnie.",
    "p2": "Jeden sprzedawca nie może zapewnić wystarczającej liczby kont",
    "p3": "więc publikujemy nasze konto sprzedawcy w innym miejscu",
    "p4": "gdy potrzebujesz wykonać zadanie, możesz skontaktować się z obsługą sprzedaży w celu zarejestrowania swojego kanału płatności."
  },
  "chat": {
    "list": {
      "nav": "Wiadomości",
      "hours": "{h} godzin",
      "minutes": "{m} minut",
      "ago": "{time} temu",
      "image": "obraz"
    },
    "room": {
      "msgPlaceholder": "Wprowadź wiadomość...",
      "send": "Wyślij",
      "sender": "Nadawca",
      "sendTime": "Czas wysłania",
      "content": "Treść"
    }
  },
  "Login.Daftar.selectCurrency": "Wybierz walutę",
  "mine.totalBalance": "Łączne saldo",
  "newBank": {
    "usdt": {
      "name": "Nazwa",
      "currency": "Waluta",
      "chainType": "Typ łańcucha",
      "depositAddress": "Adres depozytowy",
      "submit": "prześlij"
    },
    "walletList": {
      "pageTitle": "Zarządzanie wypłatami",
      "name": "Alias portfela:",
      "coinType": "Waluta:",
      "chainType": "Typ łańcucha:",
      "walletAddress": "Adres:",
      "addDate": "Data:",
      "btnText": "Dodaj adres portfela",
      "deleteConfirmText": "Czy na pewno chcesz usunąć adres portfela?",
      "btnConfirm": "Usuń",
      "btnCancle": "Anuluj",
      "successHint": "sukces"
    },
    "withdrawNum": "Liczba wypłat",
    "AllOut": "Wszystkie",
    "balance": "Łączne saldo",
    "handlingFee": "Opłata manipulacyjna",
    "actualAmount": "Kwota rzeczywista"
  },
  "recharge.status.error": "Weryfikacja danych nie powiodła się, proszę przesłać poprawną wartość hasha i kwotę danych.",
  "page.checkin": "Zameldowanie",
  "checkin.submit": "Zameldowanie",
  "checkin.success": "Zameldowanie zakończone sukcesem",
  "checkin.fail": "Zameldowanie nie powiodło się",
  "todayChecked": "Już się zameldowałeś, proszę nie powtarzać operacji!",
  "submit.checked": "zameldowany",
  "hint.inviteBanned": "Przepraszamy, Twoje konto nie ma jeszcze otwartego konta rodzinnego, proszę skontaktować się z obsługą klienta lub mentorem w celu złożenia wniosku o otwarcie!",
  "newTab.blindBox": "Skrzynia niespodzianka",
  "blindBox.submit": "Rozpocznij losowanie",
  "blindBox.times": "Możliwość otwarcia skrzyni: {__times__} razy",
  "blindBox.records.title": "Moje rekordy wygranych",
  "banEditBankHint": "Karta bankowa jest ważną informacją, proszę skontaktować się z obsługą klienta, jeśli trzeba ją zmodyfikować!",
  "level.order_num": "Prowizja za kryptowaluty",
  "drawNum": "Liczba skrzyń niespodzianek",
  "recharge.good_title": "Produkt",
  "recharge.blindBoxIncome": "Dochód z skrzyni niespodzianki",
  "recharge.amount": "Kwota",
  "recharge.bonusRatio": "Stawka bonusu",
  "submit": {
    "desc": {
      "1": "Po ukończeniu produktu wydarzenia otrzymasz możliwość losowania skrzyni niespodzianki, a zakres bonusu wynosi 10-50% wartości produktu.",
      "sub_1": "Na przykład, jeśli zapłacisz kwotę zamówienia R$3000, stosunek bonusu do skrzyni niespodzianki wynosi 10%-50%, a rzeczywisty zakres bonusu to R$300-1500, co oznacza, że Twoje minimalne wypłaty wyniosą R$3300, a maksymalne wypłaty R$4500.",
      "2": "Na podstawie technologii LBS system automatycznie dopasowuje produkty i możliwości losowania skrzyni niespodzianki za pośrednictwem chmury.",
      "3": "Aby zapobiec nadzorowi platformy, jeśli zamówienie nie zostanie potwierdzone i złożone w ciągu 30 minut, system zablokuje kwotę zamówienia.",
      "note": "Proszę dokładnie zapoznać się z poniższymi informacjami:",
      "note_sub": "Po dopasowaniu zamówienia dla użytkownika, Shopee prześle informacje o zamówieniu do tła sprzedawcy. Jeśli użytkownik nie złoży zamówienia w ciągu 30 minut, kwota zamówienia zostanie zablokowana przez system. Aby uniknąć nadzoru systemu, proszę zaakceptować po potwierdzeniu, wszystkie zadania nie mogą być anulowane ani zamieniane po zaakceptowaniu! Jeśli pojawią się inne problemy wymagające rozwiązania, proszę kliknąć, aby zatrzymać składanie zamówienia w odpowiednim czasie, aby uniknąć nadmiernego czasu zamrożenia. Dziękujemy za wsparcie."
    }
  },
  "drawNine": {
    "times": "Szansa na otwarcie skrzyni skarbów",
    "submit": "Bonus ze skrzyni niespodzianki",
    "percentHint": "100% zwycięzca"
  },
  "hint.nullOfDraw": "Nie masz szansy na losowanie",
  "hint.loginNotRead": "Proszę przeczytać umowę i spróbować ponownie!",
  "pages.editPhone": "Zmiana numeru telefonu",
  "editPhone": {
    "label": "Numer telefonu",
    "placeholder": "Numer telefonu",
    "hint": "Proszę wprowadzić numer telefonu"
  },
  "Button.submit": "Prześlij",
  "pages.editEmail": "Powiąż e-mail",
  "users.email": "E-mail",
  "users.userphone": "Telefon",
  "users.username": "Nazwa użytkownika",
  "editEmail": {
    "label": "E-mail",
    "placeholder": "E-mail",
    "hintRequire": "Proszę wprowadzić adres e-mail",
    "hintCorrect": "Proszę wprowadzić ważny adres e-mail"
  },
  "personal.page.email": "E-mail",
  "personal.page.phone": "Telefon",
  "banEditPhone": "Numer telefonu jest ważną informacją, jeśli musisz go zmienić, skontaktuj się z obsługą klienta!",
  "banEditEmail": "E-mail jest ważną informacją, jeśli musisz go zmienić, skontaktuj się z obsługą klienta!",
  "banEditUserInfo": "Ważne informacje nie mogą być zmieniane, jeśli musisz je zmienić, skontaktuj się z obsługą klienta!",
  "bannerText1": "{name} zakończ zadanie o wartości {money}, aby otrzymać prowizję w wysokości {money1}",
  "bannerText2": "Gratulacje {name} Wybierz zadanie premium, aby uzyskać 30% prowizji",
  "grap": {
    "btask": "Bonusowe zadanie",
    "wtask": "Zadanie robocze"
  },
  "hashPlaceholder": "Proszę wprowadzić Hash",
  "recharge.actualAmount": "Rzeczywista kwota",
  "login.userAgreement": "Umowa użytkownika",
  "NetWork": "Sieć",
  "tailNum": "Numer rejestracyjny",
  "label.price": "Cena",
  "label.id": "ID",
  "label.level": "Poziom",
  "login.title1": "Zarejestruj się",
  "login.title2": "Witaj ponownie!",
  "registe.title1": "Zarejestruj się",
  "registe.title2": "Witaj ponownie!",
  "mine.credit": "Kredyt",
  "thirdRecharge.hint.noGoods": "Proszę najpierw sparsować przedmiot i spróbować ponownie!",
  "hint.onlyAgentInvite": "Rodzinne konto nie jest otwarte.",
  "newTab.p2p": "P2P",
  "p2p": {
    "trade_method": {
      "buy": "Kup",
      "sell": "Sprzedaj"
    },
    "coinList": {
      "orders": "{__} zamówienie(a)",
      "label": {
        "amount": "Kwota",
        "price": "Cena",
        "limit": "Limit"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "Ile {__} chcesz sprzedać?",
      "trade_title_buy": "Ile {__} chcesz kupić?",
      "submit_now": "{__} Teraz"
    },
    "orderDetails": {
      "sellTitle": "Oczekiwanie na płatność od drugiej strony",
      "buyTitle": "Oczekiwanie na Twoją płatność",
      "sellHint": "Druga strona musi zakończyć płatność w ciągu {__}, w przeciwnym razie zamówienie zostanie automatycznie anulowane.",
      "buyHint": "Zakończ płatność w ciągu {__}. Po zakończeniu płatności kliknij, aby skontaktować się ze sprzedawcą",
      "payInfo_title": "Informacje o płatności",
      "label": {
        "totalAmount": "Łączna kwota",
        "pricePerUnit": "Cena za jednostkę",
        "quantity": "Ilość",
        "counterpaty": "Druga strona",
        "payMethod": "Metoda płatności",
        "fullName": "Pełne imię i nazwisko",
        "email": "E-mail",
        "phone": "Numer telefonu"
      },
      "btn_service": "Skontaktuj się ze sprzedawcą",
      "btn_sell": "Skontaktuj się z kupującym"
    },
    "limitHint": {
      "notEnough": "Nieudane zamówienie, sprzedawca nie ma wystarczająco USDT",
      "less": "Nieudane zamówienia, minimalny limit transakcji {__}",
      "more": "Nieudane zamówienia, maksymalny limit transakcji {__}"
    }
  },
  "copySuccess": "Kopiowanie zakończone sukcesem",
  "p2p.noMore": "Brak więcej",
  "tabbar.shop": "Zakupy",
  "newAdd.shopTitle": "Koszyk",
  "shop": {
    "urlplaceholder": "Proszę dodać link do produktu",
    "uploadText": "Prześlij zrzuty ekranu zakupów",
    "urlNull": "Proszę dodać link do produktu",
    "uploadNull": "Proszę dodać zrzuty ekranu zakupów",
    "btn": "Wyślij",
    "money": "+{__}saldo",
    "point": "+{__}punkty",
    "moneyPoint": "+{money}saldo\n+{point}punkty",
    "order": {
      "orderNo": "Numer zamówienia:",
      "link": "Link:",
      "screenshot": "Zrzut ekranu",
      "title": "Historyczne zgłoszenia"
    }
  },
  "pullRefresh": {
    "pulling": "Pociągnij w dół, aby odświeżyć...",
    "loosing": "Puść, aby odświeżyć...",
    "loading": "Ładowanie...",
    "success": "Ładowanie zakończone"
  },
  "listStatus": {
    "all": "Wszystkie",
    "progress": "W trakcie",
    "done": "Zakończone",
    "error": "Żądanie danych nie powiodło się",
    "cancel": "Anuluj",
    "won": "Wygrana"
  },
  "selectCurrenycy": "Wybierz walutę"
}