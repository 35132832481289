export default {
  "signStr": "1001",
  "tab": {
    "my": "Δικό μου",
    "home": "Αρχική",
    "record": "Αρχείο",
    "grab": "Αρπάζω",
    "financing": "Χρηματοδότηση",
    "make_money": "Έλεγχος"
  },
  "newTab": {
    "home": "Αρχική",
    "money": "Χρήματα",
    "shop": "Κατάστημα",
    "order": "Παραγγελία",
    "my": "Δικό μου",
    "team": "Ομάδα"
  },
  "common": {
    "unrealized": "Σε εξέλιξη",
    "all": "Όλα",
    "distributionTip": "0 παράδοση 0 τέλος διανομής",
    "easeBack": "Επιστροφή με ευκολία",
    "addCarSuccess": "Επιτυχής προσθήκη στο καλάθι",
    "deleteConfirm": "Είστε σίγουροι ότι θέλετε να διαγράψετε το επιλεγμένο στοιχείο;"
  },
  "My": {
    "download": "Λήψη ΕΦΑΡΜΟΓΗΣ",
    "personal": "Προσωπικές πληροφορίες",
    "preOrde": "Αρχείο προπαραγγελίας",
    "account": "Στοιχεία λογαριασμού",
    "recharge": "Αρχείο επαναφόρτισης",
    "withdrawal": "Ανάληψη",
    "invite": "Πρόσκληση φίλων",
    "announcement": "Ανακοίνωση",
    "service": "Υπηρεσία",
    "team": "Αναφορά ομάδας",
    "quit": "Έξοδος",
    "logoutEvent": "Επιβεβαίωση εξόδου;",
    "language": "Ρύθμιση γλώσσας",
    "languageButton": { "english": "Αγγλικά", "indonesian": "Ινδονησιακά" },
    "logout": "Αποσύνδεση",
    "MemberInformation": {
      "SilverMember": "Ασημένιο μέλος",
      "Invitation": "Αριθμός εργαζομένου",
      "balance": "Υπόλοιπο λογαριασμού",
      "Recharge": "Επαναφόρτιση",
      "Withdraw": "Ανάληψη",
      "forzenAll": "Παγωμένα Χρήματα"
    },
    "PersonalInformation": {
      "Page": {
        "title": "Οι πληροφορίες μου",
        "Avatar": "Άβαταρ",
        "Nickname": "Ψευδώνυμο",
        "PhoneNumber": "Αριθμός τηλεφώνου",
        "BankAccounts": "Η Διεύθυνση του Πορτοφολιού μου",
        "Password": "Κωδικός πρόσβασης",
        "TransactionPassword": "Κωδικός συναλλαγών",
        "ReceivingAddress": "Διεύθυνση παραλαβής"
      },
      "portrait": { "SetAvatar": "Ορισμός άβαταρ", "button": "Ορίστε τώρα" },
      "Name": {
        "ModifyNickname": "Τροποποίηση ψευδωνύμου",
        "title": "Τροποποιήστε το ψευδώνυμό σας",
        "name": "Ψευδώνυμο",
        "Modify": "Τροποποίηση",
        "Please": "Παρακαλώ εισάγετε ένα ψευδώνυμο"
      },
      "BankAccounts": {
        "BankAccounts": "Σύνδεση κάρτας τραπέζης",
        "YourInformation": "Οι πληροφορίες σας",
        "RealName": "Πραγματικό όνομα",
        "RealNameNote": "Παρακαλώ ορίστε το πραγματικό σας όνομα",
        "PhoneNo": "Αριθμός τηλεφώνου",
        "PhoneNoNote": "Παρακαλώ ορίστε τον αριθμό τηλεφώνου",
        "YourBankCard": "Οι πληροφορίες της κάρτας τραπέζης σας",
        "BankAccount": "Αριθμός Λογαριασμού",
        "BankAccountNote": "Παρακαλώ ορίστε τον αριθμό λογαριασμού",
        "BankName": "Όνομα τραπέζης",
        "BankNameData": {
          "option1": "Επιλέξτε την τράπεζα του λογαριασμού",
          "option2": "Τράπεζα Κεντρικής Ασίας",
          "option3": "Τράπεζα Negara Ινδονησίας",
          "option4": "Τράπεζα Rakyat Ινδονησίας",
          "option5": "Τράπεζα Mandiri"
        },
        "BankBranch": "Υποκατάστημα Τράπεζας",
        "BankBranchNote": "Παρακαλώ ορίστε το υποκατάστημα τράπεζας",
        "MoreInformation": "Περισσότερες πληροφορίες",
        "WhatsApp": "WhatsApp",
        "WhatsAppNote": "Παρακαλώ ορίστε τον αριθμό WhatsApp",
        "Text": "Υπενθύμιση: Γεια σας, για να διασφαλίσετε τα δικαιώματά σας, παρακαλώ συνδέστε τον σωστό αριθμό κάρτας και τις πληροφορίες της τράπεζας. Εάν ο λογαριασμός δεν μπορεί να ληφθεί λόγω εσφαλμένης συμπλήρωσης, αυτή η πλατφόρμα δεν φέρει καμία ευθύνη!",
        "Settings": "Ρυθμίσεις"
      },
      "Password": {
        "title": "Τροποποίηση κωδικού πρόσβασης",
        "PleaseEnter": "Παρακαλώ εισάγετε τον νέο σας κωδικό πρόσβασης",
        "phone": "τηλέφωνο",
        "NewPhone": "Παρακαλώ εισάγετε τον αριθμό κινητού σας",
        "verificationCode": "κωδικός επαλήθευσης",
        "verificationCodeNote": "Παρακαλώ εισάγετε τον κωδικό επαλήθευσης",
        "oldPassword": "παλιός κωδικός πρόσβασης",
        "oldPasswordNote": "εισάγετε τον παλιό σας κωδικό πρόσβασης",
        "ConfirmPassword": "νέος κωδικός πρόσβασης",
        "ConfirmPasswordNote": "εισάγετε τον νέο σας κωδικό πρόσβασης",
        "Text": "Παρακαλώ θυμηθείτε τον κωδικό πρόσβασης, αν τον ξεχάσετε, παρακαλώ επικοινωνήστε με την υπηρεσία πελατών.",
        "ButtonCode": "λάβετε κωδικό επαλήθευσης",
        "Button": "ΟΚ για τροποποίηση",
        "verifyPhone": "Παρακαλώ εισάγετε τον αριθμό κινητού σας",
        "PhoneNew": "Παρακαλώ επιβεβαιώστε ότι έχετε τελειώσει"
      },
      "Site": {
        "title": "Τροποποίηση διεύθυνσης παραλαβής",
        "ReceivingIdentity": "Πληροφορίες ταυτότητας παραλαβής",
        "ReceivingAddress": "Πληροφορίες διεύθυνσης παραλαβής",
        "DetailedAddress": "Λεπτομερής διεύθυνση",
        "DetailedAddressNote": "Παρακαλώ εισάγετε λεπτομερή διεύθυνση",
        "Set": "Ορίστε ως κοινή διεύθυνση",
        "Button": "Επιβεβαίωση",
        "hint": "Παρακαλώ επιβεβαιώστε ότι έχετε τελειώσει"
      },
      "AccountData": {
        "title": "Στοιχεία συναλλαγών",
        "To": "Προς",
        "search": "αναζήτηση",
        "Cancel": "Ακύρωση",
        "Semua": "Όλα τα είδη",
        "Penarikan": "Εγγραφή ανάληψης",
        "IsiUlang": "Μεταβολή επαναφόρτισης"
      },
      "RechargeRecord": {
        "title": "Αρχείο επαναφόρτισης",
        "Recharge": "Επαναφόρτιση εκτός σύνδεσης",
        "Online": "Επαναφόρτιση online",
        "Amount": "Ποσό επαναφόρτισης:",
        "Order": "Αριθμός παραγγελίας:",
        "Status": "Κατάσταση επαναφόρτισης:"
      },
      "WithdrawalRecord": {
        "title": "Αρχείο ανάληψης",
        "withdrawal": "Ανάληψη με τραπεζική κάρτα"
      },
      "InviteFriends": {
        "Invite": "Σύνδεσμος πρόσκλησης: κάντε κλικ για αντιγραφή",
        "InvitationCode": "Κωδικός πρόσκλησης:",
        "Click": "Αντιγραφή συνδέσμου"
      },
      "Announcement": {
        "title": "Ανακοίνωση",
        "System": "Ανακοίνωση συστήματος"
      },
      "TheTeamReport": {
        "title": "Αναφορά ομάδας",
        "To": "Προς",
        "search": "αναζήτηση",
        "Cancel": "Ακύρωση",
        "balance": "Ποσό ομάδας",
        "turnover": "Κορυφαία ανανέωση ομάδας",
        "recharge": "Κέρδος παραγγελίας ομάδας",
        "withdrawal": "Ανάληψη ομάδας",
        "Commission": "Κέρδος παραγγελίας ομάδας",
        "charge": "Συνολικός αριθμός ατόμων",
        "people": "Αριθμός χρηστών με ανανέωση",
        "members": "Αριθμός νέων χρηστών",
        "NewPeople": "Νέο χρήστης ανανέωση",
        "Active": "Αριθμός νέων χρηστών",
        "Level1": "Επίπεδο 1",
        "Level2": "Επίπεδο 2",
        "Level3": "Επίπεδο 3"
      }
    },
    "PlatformIntroduction": {
      "PlatformIntroduction": "Εισαγωγή πλατφόρμας",
      "Two": "Αυτή η πλατφόρμα είναι μια συμμαχία που αποτελείται από εκατομμύρια εμπόρους. Η αρχή της υπηρεσίας της είναι: οι έμποροι της πλατφόρμας αυξάνουν τον όγκο συναλλαγών και τα μέλη της πλατφόρμας κερδίζουν έσοδα από κέρδος λαμβάνοντας παραγγελίες.",
      "Three": "Το τρίτο μέρος Intelligent Cloud ελέγχει την πλατφόρμα αντιστοίχισης παραγγελιών κεντρικού υπολογιστή. Η πλατφόρμα συνεργάζεται με άλλους εμπόρους διαδικτυακών αγορών για να αντιστοιχίζει παραγγελίες επιχειρήσεων με χρήστες της πλατφόρμας μέσω του συστήματος έξυπνου cloud, προκειμένου να αυξήσει τον όγκο των συναλλαγών και τη φήμη του πωλητή στην κύρια πλατφόρμα. Η μέθοδος παράδοσης της παραγγελίας αγοράς βελτιώνεται. Η παραγγελία αποστέλλεται αυτόματα από τον απομακρυσμένο διακομιστή έξυπνου cloud. Οι χρήστες της πλατφόρμας χρειάζεται μόνο να παρέχουν τη διεύθυνση παραλήπτη, τον αριθμό τηλεφώνου και μερικές πληροφορίες αγορών απευθείας στην πλατφόρμα για να εισέλθουν στον έξυπνο κεντρικό υπολογιστή cloud.",
      "Four": "Για να βοηθήσει τους πωλητές να βελτιώσουν αποτελεσματικά το ποσοστό επιτυχίας των παραγγελιών και να αποφύγουν τον κίνδυνο ελέγχου ψευδών παραγγελιών από τους πωλητές, η πλατφόρμα θα βελτιστοποιήσει την αντιστοίχιση παραγγελιών της ημέρας σύμφωνα με την τρέχουσα IP λειτουργίας και τον τύπο εξοπλισμού σας. Επομένως, η αντιστοίχιση παραγγελιών πρέπει να πραγματοποιείται μέσω του αλγορίθμου έξυπνης υπολογιστικής cloud. Παρακαλούμε περιμένετε υπομονετικά. Οι χρήστες της πλατφόρμας θα γίνουν αυτόματα αντιπρόσωποι της πλατφόρμας προωθώντας νέους ανθρώπους να συμμετάσχουν στην πλατφόρμα και μπορούν να λάβουν επιπλέον κέρδος.",
      "Five": "Απλά πρέπει να χρησιμοποιήσετε τον ελεύθερο χρόνο σας για να σερφάρετε στο διαδίκτυο και μπορείτε εύκολα να κερδίζετε καθημερινά πλούσια κέρδη. Μετά από κλικ και επιτυχή αποστολή της παραγγελίας, το κεφάλαιο και το κέρδος θα επιστραφούν."
    },
    "RuleDescription": {
      "RuleDescription": "Περιγραφή κανόνων",
      "One": "1. Τα μέλη πρέπει να συμπληρώσουν τη διεύθυνση του παραλήπτη και να ενημερώσουν τα προσωπικά στοιχεία πριν από την παραλαβή των παραγγελιών. Η προμήθεια των μελών της πλατφόρμας διαφέρει ανάλογα με τις διαφορετικές εργασίες. Ο αριθμός των παραγγελιών που δημιουργούνται από το σύστημα βασίζεται στο 30%-70% του υπολοίπου του λογαριασμού σας.",
      "Two": "2. Επειδή οι παραγγελίες εμπορευμάτων χρειάζονται κάποιο χρόνο για να ισχύσουν, οι παραγγελίες μπορούν να γίνουν δεκτές μόνο με τον πωλητή μετά την ισχύ της παραγγελίας, γι' αυτό η εκκαθάριση της προμήθειας της πλατφόρμας και η τριμερής τράπεζα και τα μέλη χρησιμοποιούν το σύστημα T+1. Δηλαδή: σήμερα για ανάληψη και τα χρήματα θα ληφθούν την επόμενη μέρα πριν από τις 23:59. Εάν η παραγγελία δεν ισχύσει μετά τον καθορισμένο χρόνο, η πλατφόρμα θα καταβάλει προκαταβολή, θα ολοκληρώσει τον λογαριασμό και θα εκδώσει την πληρωμή εντός του καθορισμένου χρόνου.",
      "Three": "3. Κατάσταση αίθουσας και ποσοστό προμήθειας Τα μέλη Silver: Όλα τα μέλη Silver μπορούν να λάβουν 10 παραγγελίες ανά ημέρα και η προμήθεια για τις παραγγελίες αυτές είναι 0,30% του ποσού των εμπορευμάτων.",
      "Four": "Μέλη Gold: Για να ανοίξετε τη συνδρομή Gold, πρέπει να προσκαλέσετε έως και 3 άτομα για να εισέλθουν σε αυτήν την αίθουσα. Μπορείτε να λάβετε 35 παραγγελίες ανά ημέρα και η προμήθεια για τις παραγγελίες είναι 0,35% του ποσού των εμπορευμάτων.",
      "Five": "Μέλη Platinum: Για να ανοίξετε τη συνδρομή Platinum, πρέπει να προσκαλέσετε έως και 10 άτομα για να εισέλθουν σε αυτήν την αίθουσα. Μπορείτε να λάβετε 40 παραγγελίες ανά ημέρα και η προμήθεια για τις παραγγελίες αυτές είναι 0,40% του ποσού των εμπορευμάτων.",
      "Six": "Μέλη Platinum: Για να ανοίξετε τη συνδρομή Platinum, πρέπει να προσκαλέσετε έως και 10 άτομα για να εισέλθουν σε αυτήν την αίθουσα. Μπορείτε να λάβετε 40 παραγγελίες ανά ημέρα και η προμήθεια για τις παραγγελίες αυτές είναι 0,40% του ποσού των εμπορευμάτων.",
      "Seven": "Μέλη Diamond: Για να ανοίξετε τη συνδρομή Diamond, πρέπει να προσκαλέσετε 30 άτομα για να εισέλθουν σε αυτήν την αίθουσα. Μπορείτε να λάβετε 45 παραγγελίες ανά ημέρα και η προμήθεια για τις παραγγελίες αυτές είναι 0,45% του ποσού των εμπορευμάτων.",
      "Eight": "4. Παρακαλούμε ελέγξτε τον αριθμό της πλατφόρμας καταθέσεων πριν από τη συμπλήρωση και η πλατφόρμα θα αλλάξει τον αριθμό του λογαριασμού ακανόνιστα. Η πλατφόρμα δεν θα ευθύνεται για τυχόν απώλειες περιουσίας που προκαλούνται από προσωπικό σφάλμα λειτουργίας.",
      "Nine": "Σημείωση: Ελέγξτε τις πληροφορίες της κάρτας τραπέζης της πλατφόρμας αν αυτή είναι η πλατφόρμα για κάθε μεταφορά για να αποφύγετε την οικονομική σας ζημία.",
      "Ten": "5. Για την προστασία των κερδών μεταξύ του πωλητή και της πλατφόρμας, επομένως κάθε IP, αριθμός τραπεζικής κάρτας και αριθμός κινητού τηλεφώνου μπορούν να ισχύουν μόνο για έναν λογαριασμό. Όλες οι μεγάλες πλατφόρμες ηλεκτρονικού εμπορίου έχουν μηχανή ελέγχου κινδύνου, εάν πολλοί χρήστες στην ίδια περιοχή και IP ψωνίζουν στο ίδιο κατάστημα, θα προκαλέσει την κατάργηση των εκρηκτικών προϊόντων του καταστήματος και θα επηρεάσει την αξιοπιστία και τις πωλήσεις του πωλητή.",
      "Eleven": "Εάν διαπιστωθεί ότι κάποιος ζητά πολλούς λογαριασμούς, οι παραβάτες θα παγώσουν τον λογαριασμό τους μόνιμα και θα παγώσουν το υπόλοιπο για 30 ημέρες. Όταν χρειάζεστε να αποσύρετε το παγωμένο υπόλοιπο (το τέλος της περιόδου λήξης), παρακαλούμε επικοινωνήστε με την εξυπηρέτηση πελατών της πλατφόρμας μας για να ενεργοποιήσετε τον λογαριασμό για ανάληψη.",
      "OneData": {
        "One": "Εισαγωγή εργασιών:",
        "Two": "Τύπος εργασιών            Ποσοστό προμήθειας",
        "Three": "Lazada                     0.30%",
        "Four": "Bukalapak               0.35%",
        "Five": "Shopee                  0.40%",
        "Six": "Tokopedia              0.45%"
      },
      "TwoData": {
        "One": "Περιγραφή επιπέδου συνδρομής:",
        "Two": "Τύπος συνδρομής            Μέλη Silver",
        "Three": "Όριο ανάληψης              50.000.000",
        "Four": "Αριθμός παραγγελιών              30/ημέρα",
        "Five": "Ποσοστό προμήθειας              0.30%",
        "Six": "Πρόσκληση                        -"
      },
      "FourData": {
        "One": "Τύπος συνδρομής         Μέλη Platinum",
        "Two": "Όριο ανάληψης            50.000.000",
        "Three": "Αριθμός παραγγελιών            40/ημέρα",
        "Four": "Ποσοστό προμήθειας            0.40%",
        "Five": "Πρόσκληση                      10"
      },
      "FiveData": {
        "One": "Τύπος συνδρομής         Μέλη Diamond",
        "Two": "Όριο ανάληψης            50.000.000",
        "Three": "Αριθμός παραγγελιών            45/ημέρα",
        "Four": "Ποσοστό προμήθειας            0.45%",
        "Five": "Πρόσκληση                      30"
      },
      "Twelve": "*Τα διαφορετικά επίπεδα μπορούν να κερδίσουν διαφορετικά ποσά και ποσοστά προμήθειας. Όσο υψηλότερο είναι το επίπεδο συνδρομής, τόσο περισσότερα ποσά μπορείτε να λάβετε. Όσο μεγαλύτερη είναι η παραγγελία, τόσο υψηλότερη είναι η προμήθεια."
    },
    "AgentCooperation": {
      "AgentCooperation": "Συνεργασία με αντιπροσώπους",
      "One": "Κυκλοφορία τριτοβάθμιου προτύπου για πληρεξουσίους",
      "Two": "Τα μέλη μπορούν να γίνουν πληρεξούσιοι της πλατφόρμας προτείνοντας νέα μέλη να ενταχθούν για να λάβουν επιπλέον επιβράβευση.",
      "Three": "Προώθηση άμεσης επιβράβευσης χρηστών πρώτου επιπέδου για να κερδίσετε 9% ημερήσια προμήθεια μπόνους. Η επιβράβευση χρηστών δεύτερου επιπέδου είναι 6% ημερήσια προμήθεια μπόνους. Η επιβράβευση χρηστών τρίτου επιπέδου είναι 3% ημερήσια προμήθεια μπόνους.",
      "Four": "Για παράδειγμα:",
      "Five": "Ο πληρεξούσιος πρώτου επιπέδου σας έχει προσκαλέσει 10 άτομα να γίνουν μέλη, ο πληρεξούσιος δεύτερου επιπέδου έχει προσκαλέσει 20 άτομα να γίνουν μέλη και ο πληρεξούσιος τρίτου επιπέδου έχει προσκαλέσει 40 άτομα να γίνουν μέλη. Το εισόδημα ανά άτομο για μία ημέρα είναι 200.000 INR, οπότε απλά φανταστείτε τα ημερήσια κέρδη που θα λάβετε.",
      "Six": "Πρώτο επίπεδο: 200.000 x 10 x 9%  = 180.000 INR",
      "Seven": "Δεύτερο επίπεδο: 200.000 x 20 x 6%  = 240.000 INR",
      "Eight": "Τρίτο επίπεδο: 200.000 x 40 x 3%  = 240.000 INR",
      "Nine": "Πρώτο επίπεδο  +  Δεύτερο επίπεδο  +  Τρίτο επίπεδο  =  660.000 INR",
      "Ten": "Ας υποθέσουμε ότι προσκαλείτε το μέλος A να εγγραφεί, το A προσκαλεί το B, και το B προσκαλεί το C,",
      "Eleven": "Τότε το μέλος A είναι χρήστης πρώτου επιπέδου της ομάδας σας, το B είναι χρήστης δεύτερου επιπέδου και το C είναι χρήστης τρίτου επιπέδου.",
      "Twelve": "Συνολικό ποσό επιβράβευσης = προμήθεια παραγγελίας A x 9% + προμήθεια παραγγελίας B x 6% + προμήθεια παραγγελίας C x 3%.",
      "Thirteen": "*Υπενθύμιση: Οι προμήθειες όλων των αντιπροσώπων που είναι offline μέλη εκδίδονται από αυτήν την πλατφόρμα και δεν θα επηρεάσουν τις προμήθειες όλων των αντιπροσώπων και των offline μελών!"
    },
    "CommonProblem": {
      "CommonProblem": "Συχνό πρόβλημα",
      "One": "Ε: Γιατί οι επιχειρήσεις χρειάζονται εικονικές παραγγελίες;",
      "Two": "Σύμφωνα με τους κανόνες των μεγάλων πλατφορμών ηλεκτρονικού εμπορίου, όσο περισσότεροι άνθρωποι αγοράζουν ένα προϊόν, τόσο πιο δημοφιλές γίνεται το προϊόν και τόσο περισσότερες πιθανότητες θα εμφανιστεί και θα προβληθεί από την πλατφόρμα ηλεκτρονικού εμπορίου. Ο ανταγωνισμός στον τομέα του ηλεκτρονικού εμπορίου είναι έντονος. Για να αυξήσουν την προβολή των προϊόντων τους, οι έμποροι χρειάζονται να αυξήσουν τον όγκο των συναλλαγών, γι' αυτό επιλέγουν να δημιουργήσουν εικονικές παραγγελίες στην πλατφόρμα.",
      "Three": "Ε: Πώς μπορώ να ολοκληρώσω τη διαδικασία παραλαβής παραγγελίας;",
      "Four": "Επιλέξτε το κανάλι ηλεκτρονικού εμπορίου, κάντε κλικ για να λάβετε παραγγελίες αυτόματα, το σύστημα αντιστοιχίζει έξυπνα προϊόντα για λήψη παραγγελιών. Η πλατφόρμα χρησιμοποιεί τα πραγματικά σας προσωπικά στοιχεία, τη διεύθυνση αποστολής και τη διεύθυνση IP του δικτύου σας για να στείλει την παραγγελία στην πλατφόρμα ηλεκτρονικού εμπορίου για να προσομοιώσει τις διαδικτυακές αγορές. Ωστόσο, οι έμποροι δεν αποστέλλουν πραγματικά τα προϊόντα. Μετά την επιτυχή αποστολή της παραγγελίας, το κεφάλαιο και η προμήθεια της παραγγελίας θα επιστραφούν αμέσως στον λογαριασμό σας.",
      "Five": "Ε: Γιατί πρέπει να κάνω επαναφόρτιση πριν λάβω παραγγελίες;",
      "Six": "Για να διασφαλιστεί η αυθεντικότητα των παραγγελιών, κάθε πλατφόρμα προσομοίωσης παραγγελιών απαιτεί πραγματική πληρωμή στην πλατφόρμα ηλεκτρονικού εμπορίου. Για να προστατευθούν τα συμφέροντα της πλατφόρμας και των εμπόρων, το υπόλοιπο του λογαριασμού σας θα μειωθεί κατά την αποστολή της παραγγελίας και θα επιστραφεί αμέσως μετά την ολοκλήρωση της παραγγελίας.",
      "Seven": "Ε: Πώς μπορώ να αυξήσω τον αριθμό των παραγγελιών και την προμήθεια;",
      "Eight": "Προσκαλώντας φίλους να εγγραφούν, όσο υψηλότερο είναι το επίπεδο, τόσο περισσότερες παραγγελίες μπορείτε να λάβετε ανά ημέρα και τόσο υψηλότερο είναι το ποσοστό προμήθειας. Το σύστημα αντιστοιχίζει αυτόματα τον αριθμό των παραγγελιών μεταξύ 30% και 70% του υπολοίπου του λογαριασμού, οπότε όσο μεγαλύτερο είναι το υπόλοιπο του λογαριασμού, τόσο μεγαλύτερη είναι η ποσότητα κάθε παραγγελίας και τόσο υψηλότερη είναι η προμήθεια.",
      "Nine": "Ε: Πώς μπορώ να προσκαλέσω φίλους να εγγραφούν;",
      "Ten": "Κάντε κλικ στο \"Προσκαλέστε Φίλους\", αντιγράψτε τον σύνδεσμο ή τον κωδικό QR και στείλτε τον στους φίλους, συμμαθητές, συναδέλφους και την οικογένειά σας μέσω κοινωνικών δικτύων. Πρέπει να συμπληρώσετε τον κωδικό πρόσκλησης για εγγραφή σε αυτήν την πλατφόρμα και το άλλο άτομο θα γίνει μέλος της ομάδας σας μετά την εγγραφή.",
      "Eleven": "Ε: Πώς μπορώ να κάνω ανάληψη;",
      "Twelve": "Μπορείτε να ζητήσετε ανάληψη αφού συμπληρώσετε την ελάχιστη ποσότητα παραγγελιών. Εάν δεν πληρείτε την ελάχιστη ποσότητα παραγγελιών, συνεχίστε μέχρι να πληρούνται οι απαιτήσεις. Μετά την έγκριση της ανάληψης, η πλατφόρμα θα καταθέσει τα χρήματα στον συνδεδεμένο τραπεζικό λογαριασμό σας και ο ακριβής χρόνος λήψης εξαρτάται από την τράπεζά σας."
    },
    "MemberUpgrade": {
      "MemberUpgrade": "Αναβάθμιση μέλους",
      "Member": "Επίπεδο μέλους:",
      "Orders": "Παραγγελίες διαθέσιμες καθημερινά:",
      "Commission": "Αύξηση κέρδους",
      "DedicatedOne": "Αποκλειστική εξυπηρέτηση πελατών",
      "DedicatedTwo": "Αποκλειστική εξυπηρέτηση πελατών",
      "Swipe": "Σαρώστε αριστερά και δεξιά για να δείτε περισσότερα",
      "One": {
        "Silver": "Μέλος Silver",
        "Need": "Πρέπει να προσκαλέσετε 0 άτομα",
        "Withdrawal": "Όριο ανάληψης:50.000.000",
        "quantity": "Ποσότητα παραγγελιών:10/ημέρα",
        "rate": "Ποσοστό κέρδους:0.30%",
        "fee": "Τέλος ανάληψης:2.00%",
        "Activated": "Ενεργοποιήθηκε"
      },
      "Two": {
        "Silver": "Μέλος Gold",
        "Need": "Πρέπει να προσκαλέσετε 3 άτομα",
        "People": "3 άτομα ακόμα",
        "Withdrawal": "Όριο ανάληψης:50.000.000",
        "quantity": "Ποσότητα παραγγελιών:35/ημέρα",
        "rate": "Ποσοστό κέρδους:0.35%",
        "fee": "Τέλος ανάληψης:2.00%",
        "Activated": "Καθημερινό ποσό επαναφόρτισης:1.000.000",
        "Invite": "Προσκαλέστε τώρα"
      },
      "Three": {
        "Silver": "Μέλος Platinum",
        "Need": "Πρέπει να προσκαλέσετε 10 άτομα",
        "People": "10 άτομα ακόμα",
        "Withdrawal": "Όριο ανάληψης:50.000.000",
        "quantity": "Ποσότητα παραγγελιών:40/ημέρα",
        "rate": "Ποσοστό κέρδους:0.40%",
        "fee": "Τέλος ανάληψης:2.00%",
        "Activated": "Καθημερινό ποσό επαναφόρτισης:1.500.000",
        "Invite": "Προσκαλέστε τώρα"
      },
      "Four": {
        "Silver": "Μέλος Diamond",
        "Need": "Πρέπει να προσκαλέσετε 30 άτομα",
        "People": "30 άτομα ακόμα",
        "Withdrawal": "Όριο ανάληψης:50.000.000",
        "quantity": "Ποσότητα παραγγελιών:45/ημέρα",
        "rate": "Ποσοστό κέρδους:0.45%",
        "fee": "Τέλος ανάληψης:2.00%",
        "Activated": "Καθημερινό ποσό επαναφόρτισης:2.000.000",
        "Invite": "Προσκαλέστε τώρα"
      }
    },
    "PhoneNew": "Παρακαλούμε επιβεβαιώστε ότι έχετε ολοκληρώσει",
    "Toast": { "One": "Αποθήκευση επιτυχής", "Two": "Μη επιτυχής" },
    "Button": { "One": "Ακύρωση", "Two": "Επιβεβαίωση" }
  },
  "Record": {},
  "Grab": {},
  "Financing": {
    "time": "Χρόνος",
    "day": "Ημέρα",
    "Fina": "Κεφάλαια",
    "assets": "Σύνολο περιουσιακών στοιχείων",
    "amount": "Ποσό χρηματοδότησης",
    "revenue": "Συνολικά έσοδα",
    "earnings": "Κέρδη χθες",
    "Balance": "Μεταφορά υπολοίπου",
    "RP": " ",
    "Please": "Παρακαλούμε εισάγετε μεταφορά",
    "Estimated": "Εκτιμώμενο εισόδημα",
    "Income": "Πρότυπο εισοδήματος",
    "TransferOut": "Μεταφορά εκτός",
    "ransferIn": "Μεταφορά εντός",
    "SukuBung": "Επιτόκιο",
    "Transfer": "Αποτυχία μεταφοράς εντός",
    "Pleaseint": "Παρακαλούμε εισάγετε το ποσό",
    "Successful": "Επιτυχής μεταφορά",
    "RansferInSU": {
      "FinancialAcc": "Αρχείο χρηματοοικονομικής πρόσβασης",
      "Acc": "Αρχείο πρόσβασης"
    },
    "ServiceSu": {
      "Please": "Παρακαλούμε εισάγετε...",
      "send": "αποστολή",
      "Online": "Διαδικτυακή υπηρεσία",
      "Service": "Υπηρεσία",
      "Customer": "Εξυπηρέτηση πελατών",
      "Ifyou": "Εάν αντιμετωπίζετε προβλήματα και χρειάζεστε βοήθεια, παρακαλούμε επικοινωνήστε με την διαδικτυακή εξυπηρέτηση πελατών",
      "Layanana8": "Κέντρο εξυπηρέτησης πελατών Νο.8 (WhatsApp)",
      "Layanana9": "Κέντρο εξυπηρέτησης πελατών Νο.9 (WhatsApp)",
      "Dedicated": "Αφιερωμένη εξυπηρέτηση πελατών",
      "Telegram8": "Εξυπηρέτηση πελατών Νο.8 (Telegram)",
      "Telegram008": "Telegram008",
      "Telegram9": "Εξυπηρέτηση πελατών Νο.9 (Telegram)",
      "Telegram009": "Telegram009"
    }
  },
  "Home": {
    "HallofFame": "Αίθουσα της Φήμης",
    "Member": "Ειδήσεις μελών",
    "Mission": "Αίθουσα Αποστολών",
    "Learnde": "Μάθηση",
    "Hello": "Γεια σας",
    "Let": "Αφήστε το Victory Mall να ανοίξει το δρόμο προς τον πλούτο",
    "total": "Τα συνολικά μου περιουσιακά στοιχεία",
    "Withdraw": "Ανάληψη",
    "Recharge": "Ανανέωση",
    "RP": " ",
    "earningsRp": "Κέρδη χθεςRs",
    "incomeRp": "Σωρευτικό εισόδημαRs",
    "rarningsRp": "Τα σημερινά κέρδηRs",
    "Service": "Υπηρεσία",
    "Invite": "Πρόσκληση",
    "Lazada": "Lazada",
    "Bukalapak": "Bukalapak",
    "Shopee": "Shopee",
    "Tokopedia": "Tokopedia",
    "silver": "Κανάλι για μέλη Silver",
    "gold": "Κανάλι για μέλη Gold",
    "platinum": "Κανάλι για μέλη Platinum",
    "diamond": "Κανάλι για μέλη Diamond",
    "Agent": "Κέρδος παραγγελίας αντιπροσώπου",
    "Order": "Κέρδος παραγγελίας",
    "RechargeSu": {
      "Recharge": "Ανανέωση",
      "Catatan": "Ειδική σημείωση:",
      "Next": "Επόμενο",
      "RechargeAm": "Ποσό ανανέωσης",
      "Enter": "Εισαγάγετε το ποσό ανανέωσης",
      "Quick": "Γρήγορη επιλογή",
      "nameP": "Παρακαλούμε εισάγετε το πραγματικό σας όνομα",
      "name": "Όνομα",
      "Pleaseamount": "Παρακαλούμε εισάγετε το ποσό",
      "Pleaseint": "Παρακαλούμε εισάγετε το ποσό",
      "Pleasename": "Παρακαλούμε εισάγετε το όνομά σας"
    },
    "WithdrawSu": {
      "Withdraw": "Ανάληψη",
      "Withdrawal": "Ποσό ανάληψης",
      "WithdrawalRe": "Ποσοστό ανάληψης",
      "Input": "Εισαγωγή ποσού ανάληψης",
      "Balance": "Υπόλοιπο",
      "WithdrawAll": "Ανάληψη όλων",
      "Mobile": "Αριθμός κινητού τηλεφώνου",
      "Bank": "Αριθμός τραπεζικής κάρτας",
      "Owned": "Κατεχόμενη τράπεζα",
      "Account": "Όνομα λογαριασμού",
      "Transaction": "Κωδικός συναλλαγής",
      "Withdrawim": "Άμεση ανάληψη",
      "Pleaseamount": "Παρακαλούμε εισάγετε το ποσό",
      "Pleasephone": "Παρακαλούμε εισάγετε τον αριθμό τηλεφώνου σας",
      "Pleasecard": "Παρακαλούμε εισάγετε τον αριθμό της τραπεζικής σας κάρτας",
      "Pleasebank": "Παρακαλούμε εισάγετε το όνομα της τράπεζάς σας",
      "Pleasename": "Παρακαλούμε εισάγετε το όνομά σας",
      "Pleasepassword": "Παρακαλούμε εισάγετε τον κωδικό συναλλαγής",
      "Pleaseint": "Παρακαλούμε εισάγετε το ποσό",
      "PasswordError": "Λάθος κωδικός πρόσβασης",
      "Successful": "Η αίτηση για ανάληψη έχει ήδη υποβληθεί",
      "Insufficient": "Ανεπαρκές υπόλοιπο για ανάληψη",
      "Unbound": "Η τραπεζική κάρτα δεν είναι δεσμευμένη",
      "Binding": "Δέσμευση τραπεζικής κάρτας",
      "idCard": "Αριθμός ταυτότητας"
    }
  },
  "Login": {
    "indexTitle": "Σύνδεση",
    "remenber": "Θυμηθείτε τον κωδικό",
    "versions": "V.86",
    "masuk": "Είσοδος",
    "name": "Λήψη εφαρμογής",
    "forgot": "Ξεχάσατε",
    "registrasi": "Εγγραφή",
    "loginButton": "Σύνδεση",
    "nameInput": "Εισαγάγετε αριθμό κινητού",
    "pwdInput": "Εισαγάγετε τον κωδικό σας",
    "notLoginHint": "Σύνδεση",
    "goLoginText": "επιβεβαίωση",
    "Forgot": {
      "title": "Ανάκτηση κωδικού πρόσβασης",
      "registerTitle": "Εγγραφή",
      "phone": "Εισαγάγετε τον αριθμό τηλεφώνου σας",
      "code": "Εισαγάγετε τον κωδικό επαλήθευσης",
      "area": "Παρακαλούμε εισάγετε τον κωδικό περιοχής",
      "sendCode": "αποστολή κωδικού",
      "password": "Κωδικός πρόσβασης",
      "againPassword": "Επιβεβαίωση κωδικού",
      "confirm": "Υποβολή",
      "goBack": "Έχετε ήδη λογαριασμό; Συνδεθείτε τώρα",
      "register": "Εγγραφή"
    },
    "Daftar": {
      "username": "Όνομα χρήστη",
      "title": "Εγγραφή",
      "area": "Παρακαλούμε εισάγετε τον κωδικό περιοχής",
      "phone": "Εισαγάγετε αριθμό κινητού",
      "code": "Εισαγάγετε τον κωδικό επαλήθευσης",
      "userName": "ψευδώνυμο",
      "password": "Κωδικός πρόσβασης",
      "againPassword": "Επιβεβαίωση κωδικού",
      "payPassword": "Κωδικός πληρωμής",
      "referrer": "Κωδικός πρόσκλησης (ID)"
    }
  },
  "Cart": {
    "title": "Κατάστημα παραγγελιών",
    "explain": "Περιγραφή",
    "grade": {
      "SliverMember": "Μέλος\n Silver",
      "GoldMember": "Μέλος\n Gold",
      "PlatinumMember": "Μέλος\n Platinum",
      "DiamondMember": "Μέλος\n Diamond"
    },
    "info": {
      "allMoney": "Τα συνολικά\n μου περιουσιακά στοιχεία",
      "dayAllOrder": "Συνολική\n παραγγελία της ημέρας",
      "dayTrustMoney": "Προμήθεια της\n ημέρας",
      "yesterdayMoney": "Εισόδημα\n χθες"
    },
    "text": {
      "rebateAndPrincipal": "Επιστροφή\n κεφαλαίου\n και\n κέρδους",
      "residue": "Υπόλοιπο",
      "one": "Παραγγελία",
      "rebate": "Επιστροφή κέρδους",
      "buyBtn": "Αγοράστε τώρα",
      "toGet": "Θα λάβετε",
      "orderSuccess": "Επιτυχής παραγγελία",
      "orderGetMoney": "Επιτυχής πληρωμή",
      "orderWaiting": "Αναμονή",
      "orderOver": "Ολοκληρώθηκε"
    },
    "state": {
      "all": "Όλα",
      "going": "Σε εξέλιξη",
      "paid": "Πληρωμένα",
      "frozen": "Παγωμένα",
      "over": "Ολοκληρώθηκε"
    },
    "zzInfo": {
      "buyIng": "Αγορά σε εξέλιξη",
      "buySucceedFindDT": "Κάντε κλικ στην κατάσταση για προβολή μετά την επιτυχή αγορά",
      "ok": "Εντάξει",
      "buyFailed": "Η αγορά απέτυχε",
      "notLevel": "Το επίπεδο VIP σας είναι ανεπαρκές",
      "buyJurisdiction1": "Εάν θέλετε να αγοράσετε μια παραγγελία μέλους gold",
      "buyJurisdiction2": "Εάν θέλετε να αγοράσετε μια παραγγελία μέλους platinum",
      "buyJurisdiction3": "Εάν θέλετε να αγοράσετε μια παραγγελία μέλους diamond",
      "meet": "Πρέπει να πληρούνται μία από τις παρακάτω προϋποθέσεις",
      "invite1": "Προσκαλέστε 3 φίλους να κερδίσουν χρήματα μαζί",
      "invite4": "Προσκαλέστε 10 φίλους να κερδίσουν χρήματα μαζί",
      "invite3": "Προσκαλέστε 30 φίλους να κερδίσουν χρήματα μαζί",
      "buySendMoney": "2. Δαπανήστε Rs2000 για να αγοράσετε συνδρομή diamond",
      "invite2": "Προσκαλέστε φίλους",
      "buy": "Αγοράστε τώρα",
      "myMoney": "Το υπόλοιπο του λογαριασμού σας",
      "under": "Κάτω",
      "notSendMoney": "Δεν είναι δυνατή η εκτέλεση συναλλαγής",
      "goTopUp": "Παρακαλώ κάντε ανανέωση",
      "know": "Το γνωρίζω",
      "account": "Εκκαθάριση"
    },
    "hint": {
      "p1": "1. Τα διαφορετικά επίπεδα μέλους έχουν διαφορετικές παραγγελίες αγοράς",
      "p2": "2. Όσο υψηλότερο είναι το επίπεδο μέλους σας, τόσο υψηλότερο είναι το ποσό κέρδους της παραγγελίας που μπορεί να αγοραστεί",
      "p3": "3. Για αγορασμένες παραγγελίες, μπορείτε να παρακολουθείτε την κατάσταση της παραγγελίας ανά πάσα στιγμή"
    }
  },
  "Hint": {
    "SendCodeSuccess": "Ο κωδικός επαλήθευσης στάλθηκε επιτυχώς!",
    "closed": "Κλειστό",
    "notTime": "Δεν επιλέχθηκε χρόνος",
    "notTimeFailed": "Η επιλογή χρόνου απέτυχε",
    "success": "Επιτυχία",
    "failed": "Αποτυχία",
    "phoneNot": "Ο αριθμός τηλεφώνου / λογαριασμός δεν μπορεί να είναι κενός",
    "pwdInconformity": "Ο κωδικός δεν ταιριάζει",
    "notMoney": "Ανεπαρκή χρήματα",
    "notEvent": "Η λειτουργία δεν είναι διαθέσιμη ακόμα",
    "notInput": "Ατελής εισαγωγή δεδομένων",
    "loading": "Φόρτωση",
    "notMore": "Δεν υπάρχουν άλλα",
    "notPhone": "Ο λογαριασμός δεν υπάρχει",
    "yesPhone": "Ο λογαριασμός υπάρχει ήδη",
    "levelNot": "Επίπεδο δεν επαρκεί",
    "serverMaintenance": "Παρακαλούμε υπομονή, το παιχνίδι είναι υπό συντήρηση",
    "invite": "Προσκαλέστε φίλους\n για να κερδίσετε χρήματα\n μαζί",
    "goodsNumNo": "Ανεπαρκής ποσότητα",
    "haveBuy": "Αγοράστηκε",
    "depositBtnText1": "Ελήφθη",
    "depositBtnText2": "Διαθέσιμο",
    "depositBtnText3": "Λήψη",
    "commissionMoney": "Επιστροφή κέρδους:",
    "bill": "Λογαριασμός",
    "billRecordTitle": "Αρχεία χρέωσης",
    "balance": "Υπόλοιπο",
    "reference": "Επικόλληση κωδικού πρόσκλησης",
    "updateText": "Παρακαλούμε ενημερώστε στην τελευταία έκδοση"
  },
  "newAdd": {
    "all": "όλα",
    "Completed": "ολοκληρωμένο",
    "unfinished": "ατελές",
    "GetCodeBtn": "λάβετε κωδικό επαλήθευσης",
    "copySuccess": "Αντιγραφή επιτυχής!",
    "copyError": "Η αντιγραφή απέτυχε!",
    "noLoginUserName": "Παρακαλώ συνδεθείτε",
    "Withdraw": "Ανάληψη",
    "Recharge": "Ανανέωση",
    "commission": "προμήθεια καλαθιού αγορών",
    "single": "μονάδα",
    "missionHall": "Αίθουσα αποστολών",
    "videoTutorial": "Βίντεο εκμάθησης",
    "helpCenter": "Κέντρο βοήθειας",
    "inviteFriends": "Πρόσκληση φίλων",
    "play": "Παίξτε τώρα",
    "help1Title": "Πώς να κερδίσετε προμήθειες με το Mudo",
    "help1Context": "Οι ιδιοκτήτες επιχειρήσεων ηλεκτρονικού εμπορίου πρέπει να αυξήσουν τις πωλήσεις του καταστήματος, οπότε πρέπει να ολοκληρώσουμε τις απαιτήσεις του καταστήματος ηλεκτρονικού εμπορίου και να ολοκληρώσουμε την παραγγελία στο λογισμικό Mudo. Ο ιδιοκτήτης θα μας δώσει ένα κέρδος. Κάθε χρήστης μπορεί να λάβει 10 εργασίες παραγγελίας την ημέρα. Μπορείτε να κερδίσετε 10% έως 30% κέρδος σε μία ημέρα.",
    "help2Title": "Πώς να αυξήσετε τον αριθμό των αποστολών",
    "help2Context": "Επειδή υπάρχουν πάρα πολλοί εργαζόμενοι μερικής απασχόλησης, η εταιρεία ορίζει ότι όλοι οι διαδικτυακοί εργαζόμενοι μερικής απασχόλησης χρησιμοποιούν μόνο το whatsapp για να έρχονται σε επαφή και να λαμβάνουν αποστολές.",
    "help3Title": "Πώς να ολοκληρώσετε την αποστολή",
    "help3Context": "Είναι για να βοηθήσουμε τους μεγάλους εμπόρους να αυξήσουν τις πωλήσεις και να σας ανταμείψουμε σύμφωνα με τον αριθμό των συναλλαγών. Για κάθε επιτυχημένη παραγγελία, κερδίζετε προμήθειες και οι πωλητές κερδίζουν φήμη.",
    "referralLink": "Σύνδεσμος παραπομπής:",
    "copy": "αντιγραφή",
    "paste": "Επικόλληση",
    "blance": "Υπόλοιπο",
    "todayOrder": "Σημερινή παραγγελία",
    "provided": "Τα δεδομένα παρέχονται από",
    "Transaction": "Συναλλαγή",
    "Frozen": "Μη διευθετημένα",
    "Done": "Ολοκληρώθηκε",
    "Cancel": "Ακύρωση",
    "ExpectedReturn": "Αναμενόμενη απόδοση:",
    "ImmediatePayment": "Άμεση πληρωμή",
    "AvailableNum": "Υπάρχουν ακόμη",
    "OrderImmediately": "Προβολή της παραγγελίας",
    "MakeMoneyEveryDay": "Κερδίστε χρήματα κάθε μέρα",
    "TodayRecord": "Σημερινή καταγραφή",
    "AllocatCommissions": "Κατανομή προμηθειών",
    "FrozenAmount": "Μη καταβληθέν υπόλοιπο",
    "TaskMatch": "Τυχαιότητα εντολών αποστολής",
    "AvailableBalance": "Διαθέσιμο υπόλοιπο",
    "WaitDispatch": "Αναμονή για αποστολή, παρακαλώ επικοινωνήστε με τον διευθυντή του καταστήματος!",
    "OrderDescription": "Περιγραφή παραγγελίας",
    "CouldMatch": "Οι αποστολές αντιστοιχίζονται τυχαία από το σύστημα",
    "OrderPerDay": "μονάδα.",
    "DescriptionRate": "Κάθε αποστολή παραγγελίας κρυπτονομισμάτων μπορεί να επιλεγεί τυχαία από το σύστημα ως προνομιακή αποστολή και μετά από αυτό θα επιβραβευθείτε με επιπλέον προμήθεια 10-30%.",
    "DescriptionPeriod": "του ποσού της παραγγελίας.",
    "Description3": "Το σύστημα βασίζεται στην τεχνολογία IBS και αντιστοιχίζει αυτόματα τα προϊόντα των εμπόρων μέσω του cloud blockchain. Λόγω των μη ολοκληρωμένων δεδομένων αποστολών, οι χρήστες πρέπει να ολοκληρώσουν προνομιακές αποστολές πριν μπορέσουν να αιτηθούν ανάληψη.",
    "Description4": "Για να αποφευχθεί η εποπτεία της πλατφόρμας, αν η παραγγελία δεν επιβεβαιωθεί και υποβληθεί εντός 20 λεπτών, το σύστημα θα παγώσει το ποσό της παραγγελίας.",
    "Note": "Σημείωση:",
    "DescriptionNote": "Παρακαλούμε διαβάστε προσεκτικά το σχετικό περιεχόμενο: μετά την αντιστοίχιση παραγγελιών των χρηστών από το σύστημα P2P blockchain, η πλατφόρμα θα υποβάλει τις πληροφορίες της παραγγελίας στο γραφείο πίσω του εμπόρου. Με βάση τα χαρακτηριστικά του blockchain, όταν η προνομιακή αποστολή γίνει αποδεκτή, το σύστημα p2p του εμπόρου θα στείλει αυτόματα τα δεδομένα της προνομιακής αποστολής στον λογαριασμό διακανονισμού προμήθειας Walmart, ο οποίος δεν μπορεί να ακυρωθεί ή να αντικατασταθεί. Εάν υπάρχουν άλλα προβλήματα και δεν μπορείτε να ολοκληρώσετε την αποστολή έγκαιρα, παρακαλούμε επικοινωνήστε με την εξυπηρέτηση πελατών για να ζητήσετε επέκταση ώστε να αποφύγετε την παγίωση χρόνου, σας ευχαριστούμε για την υποστήριξή σας!",
    "OrderNum": "Αριθμός παραγγελίας:",
    "TotalOrderPrice": "Συνολική τιμή παραγγελίας",
    "SubmitNow": "Υποβάλετε τώρα",
    "Record": "Αρχείο",
    "MemberAccount": "Λογαριασμός μέλους",
    "AccountPlaceholder": "Παρακαλούμε εισάγετε τον λογαριασμό μέλους σας",
    "RechargeAmount": "Ποσό ανανέωσης",
    "RechargeAmountPlaceholder": "Παρακαλούμε εισάγετε το ποσό ανανέωσης",
    "SelectRechargeMethod": "Επιλέξτε μέθοδο ανανέωσης",
    "NotBeenSentOrder": "Η παραγγελία δεν έχει αποσταλεί, παρακαλώ επικοινωνήστε με το προσωπικό για να στείλει την παραγγελία!",
    "TodayOrderOver": "Επικοινωνήστε με την διαδικτυακή εξυπηρέτηση πελατών",
    "NotLogin": "Δεν έχετε συνδεθεί, παρακαλούμε συνδεθείτε και δοκιμάστε ξανά!",
    "HaveNoteMethod": "Περιορισμένο άνοιγμα, παρακαλώ επικοινωνήστε με την ομάδα πωλήσεων!",
    "Login": "Σύνδεση",
    "AccountRecord": "Έσοδα και δαπάνες",
    "RealName": "Πραγματικό όνομα",
    "IFSCCODE": "Pix CPF",
    "BankName": "Λογαριασμός Pix",
    "ChainType": "Τύπος αλυσίδας",
    "BankNum": "Τύπος Pix",
    "Mobile": "Κινητό",
    "MobileNum": "Αριθμός κινητού",
    "Email": "Email",
    "EmailRequired": "Το email είναι υποχρεωτικό",
    "AddBankCardNote": "Οι πληροφορίες ανάληψης πρέπει να συμπληρωθούν σωστά",
    "AddWalletNote": "Σημείωση: Η διεύθυνση πορτοφολιού είναι σημαντική πληροφορία, παρακαλώ συμπληρώστε την προσεκτικά!",
    "Submit": "Υποβολή",
    "AddBankCardTitle": "Προσθήκη Pix",
    "AddWalletTitle": "Προσθήκη διεύθυνσης πορτοφολιού",
    "BankCard": "Τραπεζική κάρτα",
    "WalletInfo": "Πληροφορίες πορτοφολιού",
    "Name": "Όνομα",
    "CardNum": "Αριθμός κάρτας (AC ID)",
    "WalletAddress": "Διεύθυνση",
    "Reading": "διαβάζεται...",
    "AllBalance": "Συνολικό υπόλοιπο",
    "EnterWithdrawalAmount": "Παρακαλώ εισάγετε το ποσό ανάληψης",
    "AllOut": "Όλα έξω",
    "AskNow": "Επιβεβαίωση",
    "EnterPwdPlaceholder": "Παρακαλώ εισάγετε κωδικό",
    "NotHaveCard": "Δεν έχετε ορίσει πληροφορίες ανάληψης, θέλετε να μεταβείτε στις ρυθμίσεις;",
    "NotHaveWallet": "Δεν έχετε ορίσει τη διεύθυνση του πορτοφολιού σας, θέλετε να μεταβείτε στις ρυθμίσεις;",
    "GetBankInfoFail": "Αποτυχία λήψης τραπεζικών πληροφοριών",
    "EnterValidPayPwd": "Παρακαλώ εισάγετε έγκυρο κωδικό πληρωμής!",
    "Balance": "Υπόλοιπο",
    "Total": "Σύνολο",
    "total": "σύνολο",
    "records": "αρχεία",
    "DisplayPerPage": "εμφάνιση ανά σελίδα",
    "NullNum": "",
    "pages": "σελίδες.",
    "toAudit": "για έλεγχο",
    "succeed": "Επιτυχής",
    "RechargeRecordTitle": "Αρχείο ανανέωσης",
    "rests": "υπόλοιπα",
    "Arrive": "Άφιξη",
    "Income": "Έσοδα",
    "Expenditure": "Δαπάνες",
    "forzenCommission": "ακατάληπτο κέρδος",
    "companyIntro": "Προφίλ εταιρείας",
    "companyTitle": "Το Tata Mall είναι μέρος του Tata Group",
    "warmTitle": "Προειδοποίηση",
    "companyText": "Η Tata Group είναι η μεγαλύτερη επιχειρηματική ομάδα στην Ινδία, ιδρύθηκε το 1868 και έχει την έδρα της στη Μουμπάι, Ινδία. Οι επιχειρηματικές δραστηριότητες περιλαμβάνουν επτά επιχειρηματικούς τομείς: επικοινωνίες και τεχνολογία πληροφοριών, μηχανική, υλικά, υπηρεσίες, ενέργεια, καταναλωτικά προϊόντα και χημικά προϊόντα. Ως ταχέως αναπτυσσόμενη επιχειρηματική ομάδα στην Ινδία, η Tata Group έχει περισσότερες από 200 θυγατρικές, με επιχειρήσεις σε περισσότερες από 80 χώρες σε 6 ηπείρους, και εξαγωγές προϊόντων και υπηρεσιών σε 85 χώρες. Τα συνολικά έσοδα της Tata Group για το οικονομικό έτος 2020-2021 ανέρχονται σε 100,09 δισεκατομμύρια δολάρια, εκ των οποίων το 58% προέρχεται από δραστηριότητες στο εξωτερικό. Ο αριθμός των υπαλλήλων του Ομίλου παγκοσμίως είναι περίπου 450.000. Εδώ και 153 χρόνια, το όνομα Tata στην Ινδία είναι ευρέως σεβαστό για την προσήλωσή του στις καλές αξίες και στην επιχειρηματική ηθική. Κατατάχθηκε ως η 11η πιο σεβαστή εταιρεία στον κόσμο από το Forbes.",
    "depositAddress": "Διεύθυνση κατάθεσης",
    "rechargeSteps": "Βήματα ανανέωσης",
    "completePayment": "Ολοκληρώστε την πληρωμή",
    "rechargeNow": "Ανανέωση τώρα",
    "rechargeCancel": "Ακύρωση πληρωμής",
    "bankName": "Όνομα τράπεζας",
    "payee": "Ο δικαιούχος",
    "cardNum": "Αριθμός τραπεζικής κάρτας",
    "reviewing": "Υπό εξέταση",
    "remittance": "Επιτυχής",
    "notThrough": "Απέτυχε",
    "OrderID": "Αριθμός παραγγελίας",
    "Amount": "ποσό",
    "toCutScreen": "Παρακαλώ τραβήξτε ένα στιγμιότυπο αν η εικόνα δεν είναι διαθέσιμη!",
    "cardTabBank": "Τράπεζα",
    "cardTabUSDT": "USDT",
    "name": "Όνομα",
    "chainType": "Τύπος αλυσίδας",
    "newNavTitle": "Υποβολή Παραγγελίας",
    "shopMoney": "Ποσό αγαθών",
    "actualMoney": "Πραγματική υποβολή",
    "commitBtnText": "Δημιουργία παραγγελιών",
    "commitHint": "Ατελής εισαγωγή"
  },
  "mine": {
    "servier": "Διακομιστής",
    "integral": "Ακέραιο",
    "balance": "Εξαργύρωση",
    "record": "Αρχείο",
    "wtdAmt": "Εξαργύρωση Πόντων",
    "avaBlc": "Ποσότητα ανταλλαγής",
    "allWtd": "Όλα",
    "close": "Ακύρωση",
    "commit": "Επιβεβαίωση",
    "wtdHint": "Παρακαλούμε εισάγετε το σωστό ποσό ανάληψης"
  },
  "add": {
    "Commission": "Ανεξόφλητο ποσό σήμερα",
    "Yesterday": "Χθες",
    "Cumulative": "Σωρευτικό",
    "Today": "Σήμερα",
    "TeamNum": "Συνολικό μέγεθος ομάδας",
    "TeamMoney": "Συνολικό Κέρδος Ομάδας",
    "Personalcenteraccountchangerecord": "Αρχείο αλλαγής λογαριασμού",
    "lockedbalance": "Μη καταβληθέν ποσό",
    "Cumulativeinvitation": "Συνολική πρόσκληση",
    "Validinvitation": "Έγκυρη πρόσκληση",
    "Accumulatedrebate": "Σωρευτικό επιστροφή"
  },
  "home.btn.wallet": "Κεφάλαια",
  "home.btn.recharge": "Υποβολή",
  "home.btn.withdraw": "Ανάληψη",
  "home.btn.invite": "Πρόσκληση",
  "home.btn.message": "Μήνυμα",
  "page.grab": "Έλεγχος",
  "orderCommission": "Κέρδος παραγγελίας",
  "commission": "Κέρδος",
  "todayIncome": "Σημερινό εισόδημα",
  "personalEarnings": "Προσωπικά κέρδη",
  "completedOrders": "Ολοκληρωμένες παραγγελίες",
  "lockedOrders": "Κλειδωμένες παραγγελίες",
  "unfinishOrder": "Ατελείς παραγγελίες",
  "frozonAmount": "Μη διευθετημένα κεφάλαια",
  "currentBalance": "Τρέχον υπόλοιπο",
  "page.mine": "Λεπτομέρειες λογαριασμού",
  "mine.invitationCode": "Αριθμός υπαλλήλου",
  "mine.serverCenter": "Κέντρο διακομιστών",
  "mine.tools.total": "Σύνολο",
  "mine.tools.financial": "Κεφάλαια",
  "mine.tools.details": "Λεπτομέρειες",
  "mine.tools.freeze": "Υπόλοιπο",
  "mine.serviceTools.personal": "Προσωπικά",
  "mine.serviceTools.teamReport": "Αναφορά ομάδας",
  "mine.serviceTools.invite": "Οικογενειακός λογαριασμός",
  "mine.serviceTools.message": "Μήνυμα",
  "mine.serviceTools.aboutUs": "Σχετικά με εμάς",
  "mine.serviceTools.security": "Ασφάλεια",
  "mine.serviceTools.address": "Διεύθυνση",
  "mine.serviceTools.inviteTask": "Αποστολή πρόσκλησης",
  "page.team": "Ομάδα",
  "page.aboutus": "Σχετικά με εμάς",
  "page.financial": "Κεφάλαια",
  "page.financialRecord": "Χρηματοοικονομικό Αρχείο",
  "hint.InternetErr": "Σφάλμα δικτύου, παρακαλώ προσπαθήστε ξανά αργότερα!",
  "totalDeposit": "Συνολική κατάθεση",
  "totalRevenue": "Συνολικά έσοδα",
  "btn.transferInto": "Μεταφορά σε",
  "btn.transferOut": "Μεταφορά από",
  "page.transferInto": "Μεταφορά σε",
  "page.transferOut": "Μεταφορά από",
  "balanceTransfer": "Μεταφορά υπολοίπου",
  "availableBalance": "Διαθέσιμο υπόλοιπο",
  "depositRate": "Ποσοστό κατάθεσης",
  "days": "ημέρες",
  "hint.success": "Επιτυχία",
  "hint.fail": "Αποτυχία",
  "status.into": "Μεταφορά σε",
  "status.out": "Μεταφορά από",
  "label.amount": "Ποσό",
  "label.time": "Χρόνος",
  "Button": {
    "cancle": "Ακύρωση",
    "confirm": "Επιβεβαίωση"
  },
  "dialog.title.inputPaypass": "Εισάγετε τον κωδικό πληρωμής",
  "dialog.nopaypass": "Ο κωδικός πληρωμής δεν έχει οριστεί, κάντε κλικ στο OK για να μεταβείτε στις ρυθμίσεις!",
  "mine.serviceTools.lan": "Γλώσσα",
  "checkLans": "Αλλαγή γλώσσας",
  "mine.tools.commission": "Κέρδος",
  "page.commissionRecord": "Κέρδος",
  "noticeList.all": "Όλα",
  "noticeList.unread": "Αδιάβαστα",
  "successWithdrawof": "επιτυχής ανάληψη ποσού",
  "home.title.notice": "Ειδοποίηση",
  "home.title.patener": "Σε συνεργασία με",
  "login.rememberPassword": "Θυμηθείτε τον κωδικό πρόσβασης",
  "page": {
    "cart": "Καλάθι",
    "order": "Παραγγελία",
    "financing": "Χρηματοδότηση",
    "goods": "Αγαθά",
    "levelUpgrade": "Αναβάθμιση Επιπέδου",
    "PlatformIntroduction": "Εισαγωγή Πλατφόρμας",
    "RuleDescription": "Περιγραφή Κανόνων",
    "AgentCooperation": "Συνεργασία Πρακτόρων",
    "CommonProblem": "Κοινό Πρόβλημα",
    "MemberUpgrade": "Αναβάθμιση Μέλους",
    "login": "Σύνδεση",
    "forgot": "Ξεχάσατε",
    "registrasi": "Εγγραφή",
    "record": "Αρχείο",
    "Recharge": "Υποβολή",
    "inviteFriends": "Πρόσκληση Φίλων",
    "Withdraw": "Ανάληψη",
    "Service": "Υπηρεσία",
    "DialoguePage": "Σελίδα Διαλόγου",
    "user": "Προσωπικά",
    "RansferIn": "Μεταφορά Εντός",
    "PersonalCenter": "Προσωπικό Κέντρο",
    "Portrait": "Πορτραίτο",
    "Name": "Όνομα",
    "Account": "Λογαριασμός",
    "Password": "Κωδικός",
    "PaymentCode": "Κωδικός Πληρωμής",
    "Site": "Τοποθεσία",
    "AccountData": "Δεδομένα Λογαριασμού",
    "RechargeRecord": "Αρχείο Ανανέωσης",
    "WithdrawalRecord": "Αρχείο Αναλήψεων",
    "InviteFriends": "Πρόσκληση Φίλων",
    "Announcement": "Ανακοίνωση",
    "TheTeamReport": "Αναφορά Ομάδας",
    "BillRecord": "Αρχείο Τιμολογίων",
    "BankCardAdd": "Προσθήκη Τραπεζικής Κάρτας",
    "NewRecharge": "Ανανέωση",
    "RechargeList": "Λίστα Ανανέωσης",
    "newWithdraw": "Ανάληψη",
    "withdrawList": "Λίστα Αναλήψεων",
    "SettingPayPwd": "Ορισμός Κωδικού Πληρωμής",
    "MakeMoney": "Κερδίστε Χρήματα",
    "team": "ομάδα",
    "qrPay": "πληρωμή QR",
    "bankPay": "πληρωμή μέσω τράπεζας",
    "aboutus": "Σχετικά με εμάς",
    "financial": "Κεφάλαια",
    "financialRecord": "Χρηματοοικονομικό Αρχείο",
    "TransferInto": "Μεταφορά Εντός",
    "TransferOut": "Μεταφορά Εκτός",
    "CommissionRecord": "Κέρδος",
    "Security": "Ασφάλεια",
    "Address": "Διεύθυνση",
    "Message": "Μήνυμα",
    "InviteTask": "Αποστολή Πρόσκλησης",
    "Notice": "Ειδοποίηση",
    "modifyPaymentPassword": "Τροποποίηση κωδικού πληρωμής"
  },
  "copyLink": "Αντιγραφή συνδέσμου",
  "teamReport": {
    "title": "Αναφορά Ομάδας",
    "subTitle": "Επισκόπηση ομάδας υποταγμένων",
    "taskTitle": "Προσκαλέστε {num} άτομα να εγγραφούν και να ολοκληρώσουν την παραγγελία",
    "bonus": "Μπόνους",
    "Unfinished": "Ατελές",
    "Getted": "Λήφθηκε"
  },
  "copy": "Αντιγραφή",
  "address": {
    "name": "Όνομα",
    "inputPlaceholder": "Παρακαλώ συμπληρώστε",
    "phone": "Τηλέφωνο",
    "areaPlaceholer": "Πληροφορίες διεύθυνσης παράδοσης",
    "submit": "Αποθήκευση"
  },
  "password.old": "παλιός κωδικός πρόσβασης",
  "password.old.placeholder": "εισάγετε τον παλιό σας κωδικό πρόσβασης",
  "password.confirmPassword": "επιβεβαίωση κωδικού πρόσβασης",
  "password.confirmPassword.placeholder": "εισάγετε ξανά τον νέο κωδικό πρόσβασης",
  "hint.passwordDiff": "Οι δύο κωδικοί πρόσβασης δεν ταιριάζουν",
  "loginType": {
    "phone": "Τηλέφωνο",
    "email": "Email"
  },
  "Login.Daftar.email": "Email",
  "Login.emailInput": "Εισάγετε το email σας",
  "My.PersonalInformation.Password.email": "email",
  "My.PersonalInformation.Password.NewEmail": "Παρακαλώ εισάγετε το email σας",
  "mine.serviceTools.certify": "Επαλήθευση",
  "Certification": {
    "type": {
      "id": "Ταυτότητα ( RG )",
      "driver": "Δίπλωμα Οδήγησης",
      "passport": "Διαβατήριο"
    },
    "name": "Όνομα",
    "license": "Αριθμός άδειας",
    "upload": "Συνημμένο έγγραφο",
    "submit": "Υποβολή",
    "incompleteInformation": "Παρακαλώ συμπληρώστε τις πληροφορίες και προσπαθήστε ξανά!",
    "submitSuccess": "Οι πληροφορίες πιστοποίησης υποβλήθηκαν, παρακαλώ περιμένετε για την αναθεώρηση!",
    "photo": "Φωτογραφία ταυτότητας",
    "review": "υπό αναθεώρηση",
    "verified": "επαληθευμένο"
  },
  "upload": {
    "uploading": "μεταφόρτωση...",
    "failed": "η μεταφόρτωση απέτυχε"
  },
  "login.forgetPassword": "Ξεχάσατε τον κωδικό σας, παρακαλώ επικοινωνήστε με την εξυπηρέτηση πελατών!",
  "newAdd.BankNum.placeholder": "Παράδειγμα: 09XXXXXXXXX",
  "page.certification": "ΕΠΑΛΗΘΕΥΣΗ ΤΑΥΤΟΤΗΤΑΣ",
  "newAdd.page": "σελίδα",
  "newAdd.record": "αρχείο",
  "emailFormatError": "Παρακαλώ εισάγετε τη σωστή διεύθυνση email!",
  "urlParsePlaceholder": "Παρακαλώ εισάγετε την επικόλληση ταυτοποίησης",
  "btn.Identify": "Αναγνωρίστε άμεσα",
  "produceStatus.confirming": "Υπό επιβεβαίωση",
  "produceStatus.paying": "Προς πληρωμή",
  "produceStatus.paid": "Πληρωμένο",
  "produceStatus.done": "Ολοκληρωμένο",
  "produceStatus.cancle": "Επιστράφηκε",
  "order.old": "Παραγγελία υψηλού εισοδήματος",
  "order.new": "Κανονική παραγγελία",
  "welfareDialogTitle": "Ειδοποίηση συστήματος",
  "viGrabDesces": {
    "1": "Κάθε παραγγελία μπορεί να επιλεγεί ως τυχερή παραγγελία και η προμήθεια θα αυξηθεί σε {rate}%. Παρακαλώ επικοινωνήστε με τον μέντορα σας για να ζητήσετε επιπλέον αναθέσεις",
    "2": "Βοηθήστε τους πωλητές του Shopee να ολοκληρώσουν τη δημιουργία εικονικών παραγγελιών, να αυξήσουν την προβολή και να κερδίσουν προμήθειες",
    "3": "Παρακαλώ επικοινωνήστε με τον προωθητή σας το συντομότερο δυνατόν μετά τη λήψη της παραγγελίας, υποβάλετε τα δεδομένα της παραγγελίας και ολοκληρώστε την εργασία",
    "4": "Ο αριθμός των εργασιών είναι περιορισμένος, παρακαλώ δώστε προσοχή στις προωθητικές προτάσεις του εκπαιδευτή κατά την αποδοχή των παραγγελιών",
    "5": "Το ποσό επιστροφής θα εκδοθεί εντός 5-10 λεπτών μετά την ολοκλήρωση της εργασίας. Εάν έχετε ερωτήσεις, παρακαλώ συμβουλευτείτε έναν ειδικό ή την διαδικτυακή εξυπηρέτηση πελατών.",
    "6": "Ολοκληρώστε τις ανατεθειμένες εργασίες από το Shopee, βελτιώστε το επίπεδο VIP και κερδίστε υψηλότερες προμήθειες για εργασίες στο Facebook",
    "7": "Συμπληρώστε σωρευτικά τις καθορισμένες εργασίες του Shopee για να κερδίσετε επιπλέον προμήθειες. Για συγκεκριμένες ανταμοιβές, παρακαλώ συμβουλευτείτε τον ειδικό προώθησης ή την διαδικτυακή εξυπηρέτηση πελατών"
  },
  "expectedEarningsTitle": "Αναμενόμενα κέρδη",
  "rechargeDesc": {
    "nav": "Περιγραφή ανανέωσης",
    "p1": "Τώρα έχουμε πολλούς υπαλλήλους που ολοκληρώνουν εργασίες ταυτόχρονα.",
    "p2": "Ένας πωλητής δεν μπορεί να παρέχει αρκετούς λογαριασμούς",
    "p3": "έτσι δημοσιεύουμε τον λογαριασμό του πωλητή μας σε άλλο κενό",
    "p4": "όταν χρειαστεί να ολοκληρώσετε την εργασία, μπορείτε να επικοινωνήσετε με την υποστήριξη πωλήσεων για να καταχωρίσετε το κανάλι πληρωμής σας"
  },
  "chat": {
    "list": {
      "nav": "Μήνυμα",
      "hours": "{h} ώρες",
      "minutes": "{m} λεπτά",
      "ago": "πριν από {time}",
      "image": "εικόνα"
    },
    "room": {
      "msgPlaceholder": "Εισάγετε ένα μήνυμα...",
      "send": "Αποστολή",
      "sender": "Αποστολέας",
      "sendTime": "Χρόνος αποστολής",
      "content": "Περιεχόμενο"
    }
  },
  "Login.Daftar.selectCurrency": "Επιλέξτε νόμισμα",
  "mine.totalBalance": "Συνολικό υπόλοιπο",
  "newBank": {
    "usdt": {
      "name": "Όνομα",
      "currency": "Νόμισμα",
      "chainType": "Τύπος αλυσίδας",
      "depositAddress": "Διεύθυνση κατάθεσης",
      "submit": "υποβολή"
    },
    "walletList": {
      "pageTitle": "Διαχείριση αναλήψεων",
      "name": "Ψευδώνυμο πορτοφολιού:",
      "coinType": "Νόμισμα:",
      "chainType": "Τύπος αλυσίδας:",
      "walletAddress": "Διεύθυνση:",
      "addDate": "ημερομηνία:",
      "btnText": "Προσθήκη διεύθυνσης πορτοφολιού",
      "deleteConfirmText": "Είστε σίγουροι ότι θέλετε να διαγράψετε τη διεύθυνση πορτοφολιού;",
      "btnConfirm": "Διαγραφή",
      "btnCancle": "Ακύρωση",
      "successHint": "επιτυχής"
    },
    "withdrawNum": "Αριθμός αναλήψεων",
    "AllOut": "Όλα",
    "balance": "Συνολικό υπόλοιπο",
    "handlingFee": "Χρέωση διαχείρισης",
    "actualAmount": "Πραγματικό ποσό"
  },
  "recharge.status.error": "Η επαλήθευση δεδομένων απέτυχε, παρακαλώ υποβάλετε την σωστή τιμή hash και το ποσό δεδομένων",
  "page.checkin": "Επιβεβαίωση",
  "checkin.submit": "Επιβεβαίωση",
  "checkin.success": "Επιτυχής σύνδεση",
  "checkin.fail": "Η σύνδεση απέτυχε",
  "todayChecked": "Έχετε ήδη συνδεθεί, παρακαλώ μην επαναλάβετε τη διαδικασία!",
  "submit.checked": "συνδεδεμένος",
  "hint.inviteBanned": "Συγνώμη, ο λογαριασμός σας δεν έχει ανοίξει ακόμη οικογενειακό λογαριασμό, παρακαλώ επικοινωνήστε με την εξυπηρέτηση πελατών ή τον μέντορά σας για να κάνετε αίτηση για άνοιγμα!",
  "newTab.blindBox": "τυφλό κουτί",
  "blindBox.submit": "Ξεκινήστε την κλήρωση",
  "blindBox.times": "Ευκαιρία για άνοιγμα ενός σεντουκιού: {__times__} φορές",
  "blindBox.records.title": "το αρχείο κερδών μου",
  "banEditBankHint": "Η τραπεζική κάρτα είναι σημαντική πληροφορία, παρακαλώ επικοινωνήστε με την εξυπηρέτηση πελατών αν χρειάζεται να την τροποποιήσετε!",
  "level.order_num": "Προμήθεια κρυπτονομίσματος",
  "drawNum": "Αριθμός τυφλών κουτιών",
  "recharge.good_title": "Προϊόν",
  "recharge.blindBoxIncome": "Έσοδα από Τυφλό Κουτί",
  "recharge.amount": "Ποσό",
  "recharge.bonusRatio": "Αναλογία μπόνους",
  "submit": {
    "desc": {
      "1": "Με την ολοκλήρωση του προϊόντος εκδήλωσης, θα λάβετε μια ευκαιρία κλήρωσης μπόνους τυφλού κουτιού, και το εύρος του μπόνους είναι 10-50% της αξίας του προϊόντος.",
      "sub_1": "Για παράδειγμα, αν πληρώσετε ένα ποσό παραγγελίας R$3000, η αναλογία μπόνους τυφλού κουτιού είναι 10%-50%, και το πραγματικό εύρος μπόνους είναι R$300-1500, που σημαίνει ότι η ελάχιστη ανάληψή σας είναι R$3300 και η μέγιστη ανάληψη είναι R$4500.",
      "2": "Με βάση την τεχνολογία LBS, το σύστημα αντιστοιχίζει αυτόματα προϊόντα και ευκαιρίες κλήρωσης τυφλού κουτιού μέσω του cloud.",
      "3": "Για να αποφευχθεί η εποπτεία της πλατφόρμας, αν η παραγγελία δεν επιβεβαιωθεί και υποβληθεί εντός 30 λεπτών, το σύστημα θα παγώσει το ποσό της παραγγελίας.",
      "note": "Παρακαλώ διαβάστε προσεκτικά το σχετικό περιεχόμενο:",
      "note_sub": "Μετά την αντιστοίχιση της παραγγελίας για τον χρήστη, το Shopee θα υποβάλει τις πληροφορίες παραγγελίας στο παρασκήνιο του εμπόρου. Αν ο χρήστης δεν υποβάλει την παραγγελία εντός 30 λεπτών, το ποσό της παραγγελίας θα παγώσει από το σύστημα. Για να αποφευχθεί η εποπτεία του συστήματος, παρακαλώ αποδεχτείτε μετά την επιβεβαίωση, όλες οι εργασίες δεν μπορούν να ακυρωθούν ή να αντικατασταθούν μετά την αποδοχή! Αν υπάρχουν άλλα προβλήματα που πρέπει να αντιμετωπιστούν, παρακαλώ κάντε κλικ για να σταματήσετε την τοποθέτηση της παραγγελίας εγκαίρως για να αποφύγετε την υπερβολική παγίωση, σας ευχαριστούμε για την υποστήριξή σας."
    }
  },
  "drawNine": {
    "times": "Ευκαιρία να ανοίξετε σεντούκι θησαυρού",
    "submit": "μπόνους τυφλού κουτιού",
    "percentHint": "100% κερδισμένος"
  },
  "hint.nullOfDraw": "Δεν έχετε ευκαιρία να κάνετε κλήρωση",
  "hint.loginNotRead": "Παρακαλώ διαβάστε τη συμφωνία και προσπαθήστε ξανά!",
  "pages.editPhone": "τροποποίηση αριθμού τηλεφώνου",
  "editPhone": {
    "label": "αριθμός τηλεφώνου",
    "placeholder": "αριθμός τηλεφώνου",
    "hint": "Παρακαλώ εισάγετε τον αριθμό τηλεφώνου σας"
  },
  "Button.submit": "Υποβολή",
  "pages.editEmail": "Σύνδεση email",
  "users.email": "E-mail",
  "users.userphone": "Τηλέφωνο",
  "users.username": "Όνομα χρήστη",
  "editEmail": {
    "label": "E-mail",
    "placeholder": "E-mail",
    "hintRequire": "Παρακαλώ εισάγετε τη διεύθυνση email",
    "hintCorrect": "Παρακαλώ εισάγετε έγκυρο email"
  },
  "personal.page.email": "E-mail",
  "personal.page.phone": "Τηλέφωνο",
  "banEditPhone": "Ο αριθμός τηλεφώνου είναι σημαντική πληροφορία, αν χρειάζεται να τον τροποποιήσετε, παρακαλώ επικοινωνήστε με την εξυπηρέτηση πελατών!",
  "banEditEmail": "Το email είναι σημαντική πληροφορία, αν χρειάζεται να το τροποποιήσετε, παρακαλώ επικοινωνήστε με την εξυπηρέτηση πελατών!",
  "banEditUserInfo": "Σημαντική πληροφορία δεν μπορεί να τροποποιηθεί, αν χρειάζεται να την τροποποιήσετε, παρακαλώ επικοινωνήστε με την εξυπηρέτηση πελατών!",
  "bannerText1": "{name} ολοκληρώστε την εργασία ποσού {money} για να λάβετε προμήθεια {money1}",
  "bannerText2": "Συγχαρητήρια {name} επιλέξτε την premium εργασία για να λάβετε προμήθεια 30%",
  "grap": {
    "btask": "Εργασία Μπόνους",
    "wtask": "Εργασία Εργασίας"
  },
  "hashPlaceholder": "Παρακαλώ εισάγετε Hash",
  "recharge.actualAmount": "Πραγματικό ποσό",
  "login.userAgreement": "Συμφωνία Χρήστη",
  "NetWork": "Δίκτυο",
  "tailNum": "τελευταίο ψηφίο",
  "label.price": "Τιμή",
  "label.id": "ID",
  "label.level": "Επίπεδο",
  "login.title1": "Εγγραφή",
  "login.title2": "Καλώς ήρθατε πίσω!",
  "registe.title1": "Εγγραφή",
  "registe.title2": "Καλώς ήρθατε πίσω!",
  "mine.credit": "Πίστωση",
  "thirdRecharge.hint.noGoods": "Παρακαλώ αναλύστε το προϊόν πρώτα και προσπαθήστε ξανά!",
  "hint.onlyAgentInvite": "Οικογενειακός λογαριασμός δεν έχει ανοίξει.",
  "newTab.p2p": "P2P",
  "p2p": {
    "trade_method": {
      "buy": "Αγορά",
      "sell": "Πώληση"
    },
    "coinList": {
      "orders": "{__} παραγγελία(ες)",
      "label": {
        "amount": "Ποσό",
        "price": "Τιμή",
        "limit": "Όριο"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "Πόσα {__} θέλετε να πουλήσετε;",
      "trade_title_buy": "Πόσα {__} θέλετε να αγοράσετε;",
      "submit_now": "{__} Τώρα"
    },
    "orderDetails": {
      "sellTitle": "Αναμονή Πληρωμής από το Άλλο Μέρος",
      "buyTitle": "Αναμονή της Δικής σας Πληρωμής",
      "sellHint": "Το άλλο μέρος πρέπει να ολοκληρώσει την πληρωμή εντός {__}, αλλιώς η παραγγελία θα ακυρωθεί αυτόματα.",
      "buyHint": "Ολοκληρώστε την πληρωμή εντός {__}. Μετά την ολοκλήρωση της πληρωμής, κάντε κλικ στο επικοινωνία με τον πωλητή",
      "payInfo_title": "Πληροφορίες Πληρωμής",
      "label": {
        "totalAmount": "Συνολικό Ποσό",
        "pricePerUnit": "Τιμή Ανά Μονάδα",
        "quantity": "Ποσότητα",
        "counterpaty": "Αντίθετο μέρος",
        "payMethod": "Μέθοδος Πληρωμής",
        "fullName": "Ονοματεπώνυμο",
        "email": "Email",
        "phone": "Αριθμός Τηλεφώνου"
      },
      "btn_service": "επικοινωνήστε με τον πωλητή",
      "btn_sell": "Επικοινωνήστε με τον Αγοραστή"
    },
    "limitHint": {
      "notEnough": "Αποτυχία παραγγελίας, ο πωλητής δεν έχει αρκετό USDT",
      "less": "Αποτυχία παραγγελίας, ελάχιστο όριο συναλλαγής {__}",
      "more": "Αποτυχία παραγγελίας, μέγιστο όριο συναλλαγής {__}"
    }
  },
  "copySuccess": "Αντιγραφή επιτυχής",
  "p2p.noMore": "Δεν υπάρχουν άλλα",
  "tabbar.shop": "αγορές",
  "newAdd.shopTitle": "Καλάθι Αγορών",
  "shop": {
    "urlplaceholder": "Παρακαλώ προσθέστε σύνδεσμο προϊόντος",
    "uploadText": "Μεταφορτώστε στιγμιότυπα αγορών",
    "urlNull": "Παρακαλώ προσθέστε σύνδεσμο προϊόντος",
    "uploadNull": "Παρακαλώ προσθέστε στιγμιότυπα αγορών",
    "btn": "υποβολή",
    "money": "+{__}υπόλοιπο",
    "point": "+{__}μονάδα",
    "moneyPoint": "+{money}υπόλοιπο\n+{point}μονάδα",
    "order": {
      "orderNo": "Αριθμός Παραγγελίας:",
      "link": "Σύνδεσμος:",
      "screenshot": "Στιγμιότυπο",
      "title": "Ιστορικές δεσμεύσεις"
    }
  },
  "pullRefresh": {
    "pulling": "Τραβήξτε προς τα κάτω για ανανέωση...",
    "loosing": "Απελευθέρωση για ανανέωση...",
    "loading": "φόρτωση...",
    "success": "Η φόρτωση ολοκληρώθηκε"
  },
  "listStatus": {
    "all": "Όλα",
    "progress": "Σε εξέλιξη",
    "done": "Ολοκληρωμένα",
    "error": "Αίτημα δεδομένων απέτυχε",
    "cancel": "Ακύρωση",
    "won": "Κερδισμένα"
  },
  "selectCurrenycy": "Επιλέξτε Νόμισμα"
}