export default {
  "signStr": "1001",
  "tab": {
    "my": "My",
    "home": "Home",
    "record": "Record",
    "grab": "Grab",
    "financing": "Financing",
    "make_money": "Check"
  },
  "newTab": {
    "home": "Home",
    "money": "Money",
    "shop": "Shop",
    "order": "Order",
    "my": "My",
    "team": "Team"
  },
  "common": {
    "unrealized": "Doing",
    "all": "All",
    "distributionTip": "0 delivery 0 distribution fee",
    "easeBack": "Ease back",
    "addCarSuccess": "Join shopping successfully",
    "deleteConfirm": "Are you sure to delete the selected item ?"
  },
  "My": {
    "download": "Download APP",
    "personal": "Personal information",
    "preOrde": "Pre-order record",
    "account": "Account details",
    "recharge": "Top-up record",
    "withdrawal": "Withdrawal",
    "invite": "Invite friends",
    "announcement": "Announcement",
    "service": "Service",
    "team": "Team report",
    "quit": "Quit",
    "logoutEvent": "Confirm to exit?",
    "language": "Language setting",
    "languageButton": { "english": "English", "indonesian": "Indonesian" },
    "logout": "Logout",
    "MemberInformation": {
      "SilverMember": "Silver member",
      "Invitation": "Employee ID",
      "balance": "Account balance",
      "Recharge": "Top-up",
      "Withdraw": "Withdraw",
      "forzenAll": "Frozen Money"
    },
    "PersonalInformation": {
      "Page": {
        "title": "My information",
        "Avatar": "Avatar",
        "Nickname": "Nickname",
        "PhoneNumber": "Phone number",
        "BankAccounts": "My Wallet Address",
        "Password": "Password",
        "TransactionPassword": "Transaction password",
        "ReceivingAddress": "Receiving address"
      },
      "portrait": { "SetAvatar": "Set avatar", "button": "Set now" },
      "Name": {
        "ModifyNickname": "Modify nickname",
        "title": "Modify your nickname",
        "name": "Nickname",
        "Modify": "Modify",
        "Please": "Please enter a nickname"
      },
      "BankAccounts": {
        "BankAccounts": "Binding bank card",
        "YourInformation": "Your information",
        "RealName": "Real name",
        "RealNameNote": "Please set your real name",
        "PhoneNo": "Phone number",
        "PhoneNoNote": "Please set Phone number",
        "YourBankCard": "Your bank card information",
        "BankAccount": "Bank Account",
        "BankAccountNote": "Please set Bank Account",
        "BankName": "Bank name",
        "BankNameData": {
          "option1": "Choose account bank",
          "option2": "Bank Central Asia",
          "option3": "Bank Negara Indonesia",
          "option4": "Bank Rakyat Indonesia",
          "option5": "Bank Mandiri"
        },
        "BankBranch": "Bank Branch",
        "BankBranchNote": "Please set bank branch",
        "MoreInformation": "More information",
        "WhatsApp": "WhatsApp",
        "WhatsAppNote": "Please set WhatsApp number",
        "Text": "Reminder: Hello, in order to guarantee your rights, please bind the correct card number and bank information. If the account cannot be received due to incorrect filling, this platform does not take any responsibility!",
        "Settings": "Settings"
      },
      "Password": {
        "title": "Modify login password",
        "PleaseEnter": "Please enter your new password",
        "phone": "phone",
        "NewPhone": "Please enter your cell phone number",
        "verificationCode": "code verifikasi",
        "verificationCodeNote": "Please enter a verification code",
        "oldPassword": "old password",
        "oldPasswordNote": "enter your old password",
        "ConfirmPassword": "new password",
        "ConfirmPasswordNote": "enter your new password",
        "Text": "Please remember the password, if you forget the password, please contact customer service.",
        "ButtonCode": "get verification code",
        "Button": "OK to modify",
        "verifyPhone": "Please enter your cell phone number",
        "PhoneNew": "Please confirm that you have finished"
      },
      "Site": {
        "title": "Receiving address modification",
        "ReceivingIdentity": "Receiving identity information",
        "ReceivingAddress": "Receiving address information",
        "DetailedAddress": "Detailed address",
        "DetailedAddressNote": "Please enter detailed address",
        "Set": "Set as common address",
        "Button": "Confirm",
        "hint": "Please confirm that you have finished"
      },
      "AccountData": {
        "title": "Transaction details",
        "To": "To",
        "search": "search",
        "Cancel": "Cancel",
        "Semua": "Semua jenis",
        "Penarikan": "Catatan penarikan",
        "IsiUlang": "Mutasi Top-up"
      },
      "RechargeRecord": {
        "title": "Top-up record",
        "Recharge": "Top-up offline",
        "Online": "Online Top-up",
        "Amount": "Top-up amount  :",
        "Order": "Order number:",
        "Status": "Top-up status:"
      },
      "WithdrawalRecord": {
        "title": "Withdrawal record",
        "withdrawal": "Bank card withdrawal"
      },
      "InviteFriends": {
        "Invite": "Invite link：click copy",
        "InvitationCode": "Invitation code:",
        "Click": "Copy link"
      },
      "Announcement": {
        "title": "Announcement",
        "System": "System announcement"
      },
      "TheTeamReport": {
        "title": "Team report",
        "To": "To",
        "search": "search",
        "Cancel": "Cancel",
        "balance": "Team amount",
        "turnover": "Team Top-up",
        "recharge": "Team order Profit",
        "withdrawal": "Team withdrawal",
        "Commission": "Team order Profit",
        "charge": "Total number of people",
        "people": "Number of users with Top-up",
        "members": "Number of new users",
        "NewPeople": "New user Top-up",
        "Active": "Number of new users",
        "Level1": "Level 1",
        "Level2": "Level 2",
        "Level3": "Level 3"
      }
    },
    "PlatformIntroduction": {
      "PlatformIntroduction": "Platform introduction",
      "Two": "This platform is an alliance composed of millions of traders. Its service principle is: the platform traders increase the trading volume, and the platform members earn Profit income by receiving orders.",
      "Three": "The third-party Intelligent Cloud controls the host order matching platform. The platform cooperates with other online shopping platform merchants to match business orders with platform users through intelligent cloud system, so as to increase the transaction volume and seller reputation on the main platform. The delivery method of the purchase order is improved. The order is automatically sent by the remote intelligent cloud server. The platform users only need to provide the complete receiver address, telephone number and some shopping information directly on the platform to enter the smart cloud host.",
      "Four": "In order to help sellers effectively improve the success rate of orders and avoid the risk of sellers checking false orders, the platform will optimize the order matching of the day according to your current operating IP and equipment type. Therefore, order matching needs to be realized by intelligent cloud computing algorithm. Please wait patiently. The platform users will automatically become the platform agent by promoting new people to join the platform, and they can get extra Profit.",
      "Five": "You just need to use your spare time to surf the Internet, and you can easily earn rich Profit every day. After you click and successfully send the order, the principal and Profit will be refunded."
    },
    "RuleDescription": {
      "RuleDescription": "Rule description",
      "One": "1.  Anggota harus mengisi alamat penerima dan meningkatkan informasi pribadi sebelum mengambil pesanan. Komisi anggota platform berbeda sesuai dengan tugas yang berbeda .  Jumlah pesanan yang dihasilkan oleh sistem berdasarkan 30% -70% dari saldo rekening anda. ",
      "Two": "2.  Karena pesanan barang memerlukan waktu tertentu untuk berlaku, pesanan hanya dapat diterima dengan penjual setelah pesanan berlaku, oleh karena itu penyelesaian komisi platform dan bank tripartit dan anggota menggunakan sistem T + 1. Yaitu: hari ini untuk menarik dan uang akan diterima pada keesokan hari sebelum 23:59. Jika pesanan gagal berlaku setelah waktu yang ditentukan, platform akan membayar di uangmuka, menyelesaikan akun dan mengeluarkan pembayaran dalam waktu yang ditentukan.",
      "Three": "3.  Kondisi aula yang sesuai dan rasio komisi    Anggota perak: Semua anggota Silver dapat mengambil 10 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,30% dari jumlah barang dagangan.",
      "Four": "Anggota emas: Untuk membuka anggota emas, Anda perlu mengundang hingga 3 orang untuk masuk ke aula ini. Anda dapat mengambil 35 pesanan per hari, dan komisi untuk pesanan adalah 0,35% dari jumlah barang dagangan.",
      "Five": "Anggota platinum: Untuk membuka anggota platinum, Anda perlu mengundang hingga 10 orang untuk memasuki aula ini.Anda bisa mendapatkan 40 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,40% dari jumlah merchandise.",
      "Six": "Anggota platinum: Untuk membuka anggota platinum, Anda perlu mengundang hingga 10 orang untuk memasuki aula ini.Anda bisa mendapatkan 40 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,40% dari jumlah merchandise.",
      "Seven": "Anggota berlian: Untuk membuka anggota Diamond, Anda perlu mengundang 30 orang untuk memasuki aula ini. Anda dapat memperoleh 45 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,45% dari jumlah barang dagangan.",
      "Eight": "4.  Harap periksa nomor kartu deposit platform sebelum mengisi dan platform akan mengubah nomor akun secara tidak teratur. Platform tidak akan bertanggung jawab atas kerugian properti yang disebabkan oleh kesalahan operasional pribadi.",
      "Nine": "Catatan: Silakan periksa informasi kartu bank dari platform apakah itu platform ini untuk setiap transfer untuk menghindari kerugian ekonomi Anda.",
      "Ten": "5.  Untuk melindungi profit antara penjual dan platform, oleh karena itu setiap ip, nomor kartu bank dan nomor ponsel hanya dapat berlaku untuk satu akun. Semua platform e-niaga utama memiliki mesin kontrol risiko, Jika banyak pengguna di area yang sama dan ip yang sama yang berbelanja di toko yang sama, akan menyebabkan produk bahan peledak toko dihilangkan dan mempengaruhi kredibilitas dan penjualan penjual tidak valid.",
      "Eleven": "Jika ditemukan seseorang mengajukan permohonan untuk beberapa akun, pelanggar akan membekukan akun secara permanen dan membekukan saldo selama 30 hari. Saat Anda perlu menarik saldo yang dibekukan (akhir periode berakhir), silakan hubungi layanan pelanggan online platform kami untuk mengaktifkan akun untuk ditarik.",
      "OneData": {
        "One": "Pengenalan tugas:",
        "Two": "Jenis tugas            Tarif komisi",
        "Three": "Lazada                   0.30%",
        "Four": "Bukalapak              0.35%",
        "Five": "Shopee                  0.40%",
        "Six": "Tokopedia              0.45%"
      },
      "TwoData": {
        "One": "Deskripsi tingkat keanggotaan:",
        "Two": "Jenis keanggotaan           Anggota perak",
        "Three": "Batas penarikan               50.000.000",
        "Four": "Jumlah pesanan               30/hari",
        "Five": "Tingkat komisi                  0.30%",
        "Six": "Undang                            -"
      },
      "FourData": {
        "One": "Jenis keanggotaan        Anggota platinum",
        "Two": "Batas penarikan           50.000.000",
        "Three": "Jumlah pesanan          40/hari",
        "Four": "Tingkat komisi              0.40%",
        "Five": "Undang                        10"
      },
      "FiveData": {
        "One": "Jenis keanggotaan        Anggota berlian",
        "Two": "Batas penarikan           50.000.000",
        "Three": "Jumlah pesanan           45/hari",
        "Four": "Tingkat komisi              0.45%",
        "Five": "Undang                        30"
      },
      "Twelve": "*Level yang berbeda dapat memperoleh angka ganjil dan rasio komisi. Semakin tinggi level keanggotaan, semakin banyak angka ganjil yang bisa Anda ambil. Semakin besar urutannya, semakin tinggi komisi."
    },
    "AgentCooperation": {
      "AgentCooperation": "Agent cooperation",
      "One": "Peluncuran Standar Tingkat Ketiga untuk Proxy",
      "Two": "Anggota dapat menjadi proxy platform dengan merekomendasikan anggota baru yang bergabung untuk mendapatkan hadiah tambahan.",
      "Three": "Promosikan langsung hadiah pengguna tingkat pertama untuk mendapatkan komisi bonus 9% setiap hari .Untuk hadiah pengguna tingkat kedua adalah komisi bonus 6% setiap hari Untuk hadiah pengguna tingkat ketiga adalah komisi bonus 3% setiap hari.",
      "Four": "Sebagai contoh:",
      "Five": "Proksi tingkat pertama Anda telah mengundang 10 orang untuk bergabung menjadi anggota, proxy tingkat kedua mengundang 20 orang untuk bergabung menjadi anggota dan proxy tingkat ketiga telah mengundang 40 orang untuk bergabung menjadi anggota. Pendapatan per orang untuk sehari adalah 200.000 INR, jadi bayangkan saja penghasilan harian yang akan Anda dapatkan.",
      "Six": "Level pertama: 200.000 x 10 x 9%  = 180.000 INR",
      "Seven": "Tingkat kedua: 200.000 x 20 x 6%  = 240.000 INR",
      "Eight": "Tingkat ketiga: 200.000 x 40 x 3%  = 240.000 INR",
      "Nine": "Level pertama  +  Tingkat kedua  +  Tingkat ketiga  =  660.000 INR",
      "Ten": "Misalkan Anda mengundang anggota A untuk mendaftar, A mengundang B, dan B mengundang C,",
      "Eleven": "Kemudian anggota A adalah pengguna tingkat pertama tim Anda, B adalah pengguna tingkat kedua Anda, dan C adalah pengguna tingkat ketiga Anda.",
      "Twelve": "Jumlah hadiah = komisi pesanan A x 9% + komisi pesanan B x 6% + komisi pesanan C x 3%.",
      "Thirteen": "*Pengingat: Hadiah komisi untuk semua agen yang merupakan anggota offline dikeluarkan oleh platform ini, dan tidak akan memengaruhi komisi semua agen dan anggota offline!"
    },
    "CommonProblem": {
      "CommonProblem": "Common problem",
      "One": "Q:Mengapa bisnis membutuhkan pesanan virtual?",
      "Two": "Menurut aturan platform e-commerce utama, semakin banyak orang membeli produk, semakin populer produk tersebut, dan semakin banyak peluang akan didorong dan ditampilkan oleh platform e-commerce. Bisnis e-commerce sedang sengit. Untuk meningkatkan eksposur produk, pedagang perlu meningkatkan volume transaksi komoditas, sehingga mereka memilih untuk menghasilkan pesanan virtual di platform.",
      "Three": "Q:Bagaimana cara menyelesaikan proses pengambilan pesanan?",
      "Four": "Pilih saluran e-niaga, klik untuk mengambil pesanan secara otomatis, sistem dengan cerdas mencocokkan produk untuk mendapatkan pesanan. Platform menggunakan informasi pribadi Anda yang sebenarnya, alamat pengiriman, dan IP jaringan untuk mengirimkan pesanan ke platform e-commerce untuk mensimulasikan belanja online. Namun, pedagang tidak benar-benar mengirimkan barangnya. Setelah pesanan berhasil dikirim, pokok dan komisi pesanan akan segera dikembalikan ke akun Anda.",
      "Five": "Q:Mengapa saya perlu mengisi ulang sebelum menerima pesanan?",
      "Six": "Untuk memastikan keaslian pesanan, setiap platform pemesanan yang disimulasikan memerlukan pembayaran nyata ke situs web e-niaga. Untuk melindungi kepentingan platform dan pedagang, saldo akun Anda akan dikurangi saat pesanan dikirim, dan akan segera dikembalikan setelah pesanan selesai.",
      "Seven": "Q:Bagaimana cara meningkatkan jumlah pesanan ambil dan meningkatkan komisi?",
      "Eight": "Dengan mengundang teman untuk mendaftar, semakin tinggi levelnya, semakin banyak jumlah total pesanan per hari, dan semakin tinggi rasio komisi;Sistem secara otomatis mencocokkan jumlah pesanan antara 30% dan 70% dari saldo akun, oleh karena itu, semakin besar saldo akun, semakin tinggi jumlah setiap pesanan dan semakin tinggi komisi.",
      "Nine": "Q:Bagaimana cara mengundang teman untuk mendaftar?",
      "Ten": "Klik \"Undang Teman\", salin tautan berbagi atau kode QR, dan kirimkan ke teman, teman sekelas, kolega, dan keluarga Anda melalui perangkat lunak sosial. Anda harus mengisi kode undangan untuk pendaftaran di platform ini, dan pihak lain akan menjadi anggota tim Anda setelah pendaftaran.",
      "Eleven": "Q:Bagaimana cara menarik uang tunai?",
      "Twelve": "Anda dapat mengajukan penarikan tunai setelah memenuhi kuantitas minimum order. Jika syarat kuantitas minimum order tidak terpenuhi, lanjutkan saja hingga persyaratan terpenuhi. Setelah penarikan disetujui, platform akan mendepositkan dana ke rekening bank terikat Anda, dan waktu penerimaan yang tepat tergantung pada bank Anda."
    },
    "MemberUpgrade": {
      "MemberUpgrade": "Member upgrade",
      "Member": "Member level：",
      "Orders": "Orders available every day: ",
      "Commission": "Profit increase",
      "DedicatedOne": "Dedicated customer service",
      "DedicatedTwo": "Dedicated customer service",
      "Swipe": "Swipe left and right to see more",
      "One": {
        "Silver": "Silver member",
        "Need": "Need to invite 0 people",
        "Withdrawal": "Withdrawal limit:50.000.000",
        "quantity": "Order quantity:10/day",
        "rate": "Profit rate:0.30%",
        "fee": "Withdrawal fee:2.00%",
        "Activated": "Activated"
      },
      "Two": {
        "Silver": "Gold member",
        "Need": "Need to invite 3 people",
        "People": "3 people still short",
        "Withdrawal": "Withdrawal limit:50.000.000",
        "quantity": "Order quantity:35/day",
        "rate": "Profit rate:0.35%",
        "fee": "Withdrawal fee:2.00%",
        "Activated": "Daily Top-up amount:1.000.000",
        "Invite": "Invite now"
      },
      "Three": {
        "Silver": "Platinum member",
        "Need": "Need to invite 10 people",
        "People": "10 people still short",
        "Withdrawal": "Withdrawal limit:50.000.000",
        "quantity": "Order quantity:40/day",
        "rate": "Profit rate:0.40%",
        "fee": "Withdrawal fee:2.00%",
        "Activated": "Daily Top-up amount:1.500.000",
        "Invite": "Invite now"
      },
      "Four": {
        "Silver": "Diamond member",
        "Need": "Need to invite 30 people",
        "People": "30 people still short",
        "Withdrawal": "Withdrawal limit:50.000.000",
        "quantity": "Order quantity:45/day",
        "rate": "Profit rate:0.45%",
        "fee": "Withdrawal fee:2.00%",
        "Activated": "Daily Top-up amount:2.000.000",
        "Invite": "Invite now"
      }
    },
    "PhoneNew": "Please confirm that you have finished",
    "Toast": { "One": "Save successfully", "Two": "Not Successful" },
    "Button": { "One": "Cancel", "Two": "Confirm" }
  },
  "Record": {},
  "Grab": {},
  "Financing": {
    "time": "Time",
    "day": "Day",
    "Fina": "Funds",
    "assets": "Total assets  ",
    "amount": "Financial amount  ",
    "revenue": "Total revenue",
    "earnings": "Yesterday's earnings",
    "Balance": "Balance transfer",
    "RP": " ",
    "Please": "Please enter tran",
    "Estimated": "Estimated income",
    "Income": "Income Standard",
    "TransferOut": "Transfer out",
    "ransferIn": "Transfer in",
    "SukuBung": "Suku bunga",
    "Transfer": "Transfer in failed",
    "Pleaseint": "Please enter the amount",
    "Successful": "Successful transfer",
    "RansferInSU": {
      "FinancialAcc": "Financial Access Record",
      "Acc": "Access log"
    },
    "ServiceSu": {
      "Please": "Please enter...",
      "send": "send out",
      "Online": "Online Service",
      "Service": "Service",
      "Customer": "Pelayanan pelanggan",
      "Ifyou": "If you encounter problems and need help, please contact online cunstomer servic",
      "Layanana8": "Customer service center No.8 (WhatsApp)",
      "Layanana9": "Customer service center No.9 (WhatsApp)",
      "Dedicated": "Dedicated cunstomer service",
      "Telegram8": "Layanana Pelanggan No.8 (Telegram)",
      "Telegram008": "Telegram008",
      "Telegram9": "Layanana Pelanggan No.9 (Telegram)",
      "Telegram009": "Telegram009"
    }
  },
  "Home": {
    "HallofFame": "Hall of Fame",
    "Member": "Member Nwes",
    "Mission": "Mission Hall",
    "Learnde": "Learnde",
    "Hello": "Hello",
    "Let": "Let Victory Mall open the way to wealth",
    "total": "My total assets",
    "Withdraw": "Withdraw",
    "Recharge": "Top-up",
    "RP": " ",
    "earningsRp": "Yesterday's earningsRs",
    "incomeRp": "Cumulative incomeRs",
    "rarningsRp": "Today's earningsRs",
    "Service": "Service",
    "Invite": "Invite",
    "Lazada": "Lazada",
    "Bukalapak": "Bukalapak",
    "Shopee": "Shopee",
    "Tokopedia": "Tokopedia",
    "silver": "Channel for silver members",
    "gold": "Channel for gold members",
    "platinum": "channel for platinum members",
    "diamond": "channel for diamond members",
    "Agent": "Agent order Profit",
    "Order": "Order Profit",
    "RechargeSu": {
      "Recharge": "Top-up",
      "Catatan": "Catatan khusus:",
      "Next": "Next",
      "RechargeAm": "Top-up amount",
      "Enter": "Enter the Top-up amount",
      "Quick": "Quick selection",
      "nameP": "Please enter your real name",
      "name": "Name",
      "Pleaseamount": "Please enter the amount",
      "Pleaseint": "Please enter the amount ",
      "Pleasename": "Please enter your name"
    },
    "WithdrawSu": {
      "Withdraw": "Withdraw",
      "Withdrawal": "Withdrawal amount",
      "WithdrawalRe": "Withdrawal rate",
      "Input": "Input withdrawal amount",
      "Balance": "Balance",
      "WithdrawAll": "Withdraw all",
      "Mobile": "Mobile phone number",
      "Bank": "Bank card number",
      "Owned": "Owned bank",
      "Account": "Account name",
      "Transaction": "Transaction password",
      "Withdrawim": "Withdraw immediately",
      "Pleaseamount": "Please enter the amount",
      "Pleasephone": "Please enter your phone number",
      "Pleasecard": "Please enter your bank card number",
      "Pleasebank": "Please enter your bank name",
      "Pleasename": "Please enter your name",
      "Pleasepassword": "Please enter transaction password",
      "Pleaseint": "Please enter the amount",
      "PasswordError": "Wrong password",
      "Successful": "Already applied for withdrawal",
      "Insufficient": "Insufficient withdrawal balance",
      "Unbound": "The bank card has not been bound",
      "Binding": "Bound your bank card",
      "idCard": "ID card No"
    }
  },
  "Login": {
    "indexTitle": "Login in",
    "remenber": "Remember the password",
    "versions": "V.86",
    "masuk": "Sign in",
    "name": "App download",
    "forgot": "Forgot",
    "registrasi": "Registration",
    "loginButton": "Sign in",
    "nameInput": "Enter mobile number",
    "pwdInput": "Enter your password",
    "notLoginHint": "Sign in",
    "goLoginText": "confirm",
    "Forgot": {
      "title": "Retrieve password",
      "registerTitle": "Register",
      "phone": "Enter your phone number",
      "code": "Enter verification code",
      "area": "Please enter area code",
      "sendCode": "send code",
      "password": "Password",
      "againPassword": "Confirm password",
      "confirm": "Submit",
      "goBack": "Had an account,Sign in now",
      "register": "Daftar"
    },
    "Daftar": {
      "username": "Username",
      "title": "Register",
      "area": "Please enter area code",
      "phone": "Enter Mobile number",
      "code": "Enter verification code",
      "userName": "nickname",
      "password": "Password",
      "againPassword": "Confirm password",
      "payPassword": "Payment password",
      "referrer": "Invitation code(ID)"
    }
  },
  "Cart": {
    "title": "Order shop",
    "explain": "Description",
    "grade": {
      "SliverMember": "Sliver\nMember",
      "GoldMember": "Gold\nMember",
      "PlatinumMember": "Platinum\nMember",
      "DiamondMember": "Diamond\nMember"
    },
    "info": {
      "allMoney": "My total\n Aset  ",
      "dayAllOrder": "Today's\n total order",
      "dayTrustMoney": "Today's\n commision",
      "yesterdayMoney": "Yesterday's\n income"
    },
    "text": {
      "rebateAndPrincipal": "Return\n capital\n and\n Profit",
      "residue": "Remaining",
      "one": "Order",
      "rebate": "Return Profit",
      "buyBtn": "Buy now",
      "toGet": "Akan mendapat",
      "orderSuccess": "Order successful",
      "orderGetMoney": "Payment successful",
      "orderWaiting": "Waiting",
      "orderOver": "Done"
    },
    "state": {
      "all": "All",
      "going": "On proccess",
      "paid": "Paid",
      "frozen": "Frozen",
      "over": "Done"
    },
    "zzInfo": {
      "buyIng": "Purchasing",
      "buySucceedFindDT": "Click the status view after the purchase\n is successful",
      "ok": "Okay",
      "buyFailed": "Purchase failed",
      "notLevel": "Your VIP level is insufficient",
      "buyJurisdiction1": "If want purchase a gold membership orde",
      "buyJurisdiction2": "If want purchase a platinum membership order",
      "buyJurisdiction3": "If want purchase a diamond membership order",
      "meet": "One of the following conditions must be fulfilled",
      "invite1": "Invite 3 friends to make money together",
      "invite4": "Invite 10 friends to make money together",
      "invite3": "Invite 30 friends to make money together",
      "buySendMoney": "2. Spend Rs2000 to buy membership diamond",
      "invite2": "Invite friends",
      "buy": "Buy now",
      "myMoney": "Your account balance",
      "under": "Below",
      "notSendMoney": "Cant do transaction",
      "goTopUp": "Please go top-up",
      "know": "I know",
      "account": "Settlement"
    },
    "hint": {
      "p1": "1. Different membership levels have different purchase orders",
      "p2": "2. The higher your membership level, the higher the order Profit amount that can be purchased",
      "p3": "3. For purchased orders, you can monitor the order status any time"
    }
  },
  "Hint": {
    "SendCodeSuccess": "The verification code is sent successfully!",
    "closed": "Closed",
    "notTime": "No time selected",
    "notTimeFailed": "Time selection failed",
    "success": "Successful",
    "failed": "Failed",
    "phoneNot": "No hp/No akun tidak bisa kosong",
    "pwdInconformity": "Password not same",
    "notMoney": "Money insufficent",
    "notEvent": "Feature cant use yet",
    "notInput": "Incomplete data input",
    "loading": "Loading",
    "notMore": "No more",
    "notPhone": "Account doesn't exist",
    "yesPhone": "Account already exists",
    "levelNot": "LevelNot",
    "serverMaintenance": "Please be patient,The game is under maintenance",
    "invite": "Invite friends\n to make money\n together",
    "goodsNumNo": "Insufficient quantity",
    "haveBuy": "Bought",
    "depositBtnText1": "Diterima",
    "depositBtnText2": "Available",
    "depositBtnText3": "Receive",
    "commissionMoney": "Return Profit  :",
    "bill": "bill",
    "billRecordTitle": "Billing records",
    "balance": "balance",
    "reference": "Paste invitation code",
    "updateText": "Please update to the latest version"
  },
  "newAdd": {
    "all": "all",
    "Completed": "completed",
    "unfinished": "unfinished",
    "GetCodeBtn": "get verification code",
    "copySuccess": "Copy success!",
    "copyError": "Copy the failure!",
    "noLoginUserName": "Login Please",
    "Withdraw": "Withdraw",
    "Recharge": "Top-up",
    "commission": "shopping cart commission",
    "single": "single",
    "missionHall": "Mission hall",
    "videoTutorial": "Video tutorial",
    "helpCenter": "Help center",
    "inviteFriends": "Invite friends",
    "play": "Play now",
    "help1Title": "How to earn commissions with Mudo",
    "help1Context": "E-commerce business owners need to increase the sales of the store, so we only need to complete the requirements of E-commerce store and complete the order on the Mudo software. The owner will give us a Profit. Each user can receive 10 order tasks per day. You can earn 10% to 30% Profit in one day.",
    "help2Title": "How to increase the number of mission",
    "help2Context": "Because there are too many part-time employees, the company stipulates that all online part-time employees use the only whatsapp to get in touch and receive mission.",
    "help3Title": "How to complete the mission",
    "help3Context": "it is to help major merchants to increase sales, and to reward you according to the number of transactions. For every successful order, you earn commissions and sellers earn reputation.",
    "referralLink": "Referral link:",
    "copy": "copy",
    "paste": "Paste",
    "blance": "Balance",
    "todayOrder": "Today's Order",
    "provided": "The data is provided by",
    "Transaction": "Transaction",
    "Frozen": "Unsettlement",
    "Done": "Done",
    "Cancel": "Cancel",
    "ExpectedReturn": "Expected return:",
    "ImmediatePayment": "Immediate payment",
    "AvailableNum": "There are still",
    "OrderImmediately": "View the order",
    "MakeMoneyEveryDay": "Make money every day",
    "TodayRecord": "Today's record",
    "AllocatCommissions": "Allocation of commissions",
    "FrozenAmount": "Outstanding Balance",
    "TaskMatch": "Task order randomness",
    "AvailableBalance": "Available Balance",
    "WaitDispatch": "Waiting for dispatch,Please contact the store manager!",
    "OrderDescription": "Order description",
    "CouldMatch": "Tasks are randomly matched by the system",
    "OrderPerDay": "single.",
    "DescriptionRate": "Each cryptocurrency order task may be randomly selected by the system as a privileged task, and after becoming a privileged task you will be rewarded with an additional 10-30% commission.",
    "DescriptionPeriod": "of the order amount.",
    "Description3": "The system is based on IBS technology and automatically matches merchant products through the blockchain cloud. Affected by unfinished task data, users need to complete privileged tasks before they can apply for withdrawal.",
    "Description4": "In order to prevent the supervision of the platform, if the order     is not confirmed and submitted within 20 minutes, the system will     freeze the order amount .",
    "Note": "Note:",
    "DescriptionNote": "Please read the relevant content carefully: after the P2P blockchain system matches orders for users, the platform will submit the order information to the merchant's back office. Based on the characteristics of the blockchain, when the privileged task is accepted, the merchant p2p system will automatically send the privileged task order data to the Walmart commission settlement account account, which can not be cancelled or replaced task.  If there are other problems can not complete the task in time, please contact customer service to apply for an extension to avoid timeout freeze, thank you for your support!",
    "OrderNum": "Order number:",
    "TotalOrderPrice": "Total order price",
    "SubmitNow": "Submit now",
    "Record": "Record",
    "MemberAccount": "Member account",
    "AccountPlaceholder": "Please enter your member account",
    "RechargeAmount": "Top-up amount",
    "RechargeAmountPlaceholder": "Please enter the amount of Top-up",
    "SelectRechargeMethod": "Select the Top-up method",
    "NotBeenSentOrder": "The order has not been sent, please contact the staff to send the order!",
    "TodayOrderOver": "Contact online customer service",
    "NotLogin": "You are not logged in, Please log in and try again!",
    "HaveNoteMethod": "Limited opening, please contact the sales team!",
    "Login": "Login",
    "AccountRecord": "Income and expenditure",
    "RealName": "Real name",
    "IFSCCODE": "Pix CPF",
    "BankName": "Pix account",
    "ChainType": "Chain type",
    "BankNum": "Type of pix",
    "Mobile": "Mobile",
    "MobileNum": "Mobile Number",
    "Email": "Email",
    "EmailRequired": "Email is required",
    "AddBankCardNote": "Withdrawal information must be filled out correctly",
    "AddWalletNote": "Note: Wallet address is important information, please fill it out carefully!",
    "Submit": "Submit",
    "AddBankCardTitle": "Add Pix",
    "AddWalletTitle": "Add wallet address",
    "BankCard": "Bank card",
    "WalletInfo": "Wallet info",
    "Name": "name",
    "CardNum": "Card number (AC ID)",
    "WalletAddress": "Address",
    "Reading": "is being reading...",
    "AllBalance": "overall balance",
    "EnterWithdrawalAmount": "Please enter the withdrawal amount",
    "AllOut": "All out",
    "AskNow": "Confirm",
    "EnterPwdPlaceholder": "Please input a password",
    "NotHaveCard": "You have not set up withdrawal information, do you want to go to settings?",
    "NotHaveWallet": "You have not set up your wallet address, do you want to go to the setting?",
    "GetBankInfoFail": "Failed to retrieve bank information",
    "EnterValidPayPwd": "Please enter a valid payment password!",
    "Balance": "Balance",
    "Total": "Total",
    "total": "total",
    "records": "records",
    "DisplayPerPage": "each page displays",
    "NullNum": "",
    "pages": "pages.",
    "toAudit": "to audit",
    "succeed": "Successful",
    "RechargeRecordTitle": "Top-up-record",
    "rests": "rests",
    "Arrive": "Arrive",
    "Income": "Income",
    "Expenditure": "Expenditure",
    "forzenCommission": "unclaimed Profit",
    "companyIntro": "Company profile",
    "companyTitle": "Tata Mall is part of Tata Group",
    "warmTitle": "Warning",
    "companyText": "Tata Group is India's largest business group, founded in 1868 and headquartered in Mumbai, India. Business operations involve seven business areas: communications and information technology, engineering, materials, services, energy, consumer products and chemical products. As a rapidly growing business group in India, the Tata Group has more than 200 subsidiaries, with operations in more than 80 countries on 6 continents, and exports of products and services to 85 countries. Tata Group's total revenue for the 2020-2021 fiscal year is US$100.09 billion, of which 58% comes from overseas operations. The number of employees of the Group around the world is approximately 450,000. Since 153 years, the name Tata in India has been widely respected for its adherence to good values and business ethics. Ranked as the 11th most respected company in the world by Forbes.",
    "depositAddress": "Deposit address",
    "rechargeSteps": "Top-up steps",
    "completePayment": "Complete payment",
    "rechargeNow": "Top-up now",
    "rechargeCancel": "Cancel payment",
    "bankName": "Bank name",
    "payee": "The payee",
    "cardNum": "Bank card number",
    "reviewing": "Under review",
    "remittance": "Successful",
    "notThrough": "Failed",
    "OrderID": "OrderID",
    "Amount": "amount",
    "toCutScreen": "Please take a screenshot if the image is not available!",
    "cardTabBank": "Bank",
    "cardTabUSDT": "USDT",
    "name": "Name",
    "chainType": "Chain type",
    "newNavTitle": "Submit Order",
    "shopMoney": "Amount of goods",
    "actualMoney": "Actual submission",
    "commitBtnText": "Generate orders",
    "commitHint": "Incomplete input"
  },
  "mine": {
    "servier": "Server",
    "integral": "Integral",
    "balance": "Redeem",
    "record": "Record",
    "wtdAmt": "Redeem Points",
    "avaBlc": "Exchange quantity",
    "allWtd": "All",
    "close": "Cancel",
    "commit": "Confirm",
    "wtdHint": "Please enter the correct withdrawal amount"
  },
  "add": {
    "Commission": "Unsettled amount today",
    "Yesterday": "Yesterday",
    "Cumulative": "Cumulative",
    "Today": "Today",
    "TeamNum": " Total team size",
    "TeamMoney": " Total Team Profit",
    "Personalcenteraccountchangerecord": "Account change record",
    "lockedbalance": "outstanding amount",
    "Cumulativeinvitation": "Cumulative invitation", //
    "Validinvitation": "Valid invitation",
    "Accumulatedrebate": "Accumulated rebate"
  },
  "home.btn.wallet": "Funds",
  "home.btn.recharge": "Submit",
  "home.btn.withdraw": "Withdraw",
  "home.btn.invite": "Invite",
  "home.btn.message": "Message",
  "page.grab": "Check",
  "orderCommission": "Order Profit",
  "commission": "Profit",
  "todayIncome": "Today Income",
  "personalEarnings": "Personal earnings",
  "completedOrders": "Completed orders",
  "lockedOrders": "Locked orders",
  "unfinishOrder": "Unfinished orders",
  "frozonAmount": "Unsettled funds",
  "currentBalance": "Current balance",
  "page.mine": "Account Details",
  "mine.invitationCode": "Employee ID",
  "mine.serverCenter": "Server center",
  "mine.tools.total": "Total",
  "mine.tools.financial": "Funds",
  "mine.tools.details": "Details",
  "mine.tools.freeze": "Balance",
  "mine.serviceTools.personal": "Personal",
  "mine.serviceTools.teamReport": "Team report",
  "mine.serviceTools.invite": "Family account",
  "mine.serviceTools.message": "Message",
  "mine.serviceTools.aboutUs": "About Us",
  "mine.serviceTools.security": "Security",
  "mine.serviceTools.address": "Address",
  "mine.serviceTools.inviteTask": "Invite Task",
  "page.team": "Team",
  "page.aboutus": "About Us",
  "page.financial": "Funds",
  "page.financialRecord": "Financial Record",
  "hint.InternetErr": "Network error, please try again later!",
  "totalDeposit": "Total deposit",
  "totalRevenue": "Total revenue",
  "btn.transferInto": "Transfer into",
  "btn.transferOut": "Transfer out",
  "page.transferInto": "Transfer Into",
  "page.transferOut": "Transfer Out",
  "balanceTransfer": "Balance Transfer",
  "availableBalance": "Available Balance",
  "depositRate": "Deposit rate",
  "days": "days",
  "hint.success": "Success",
  "hint.fail": "Failed",
  "status.into": "Transfer Into",
  "status.out": "Transfer Out",
  "label.amount": "Amount",
  "label.time": "Time",
  "Button": {
    "cancle": "Cancel",
    "confirm": "Confirm"
  },
  "dialog.title.inputPaypass": "Enter payment password",
  "dialog.nopaypass": "The payment password has not been set, click OK to go to the setting!",
  "mine.serviceTools.lan": "Language",
  "checkLans": "Switch Language",
  "mine.tools.commission": "Profit",
  "page.commissionRecord": "Profit",
  "noticeList.all": "All",
  "noticeList.unread": "Unread",
  "successWithdrawof": "successful withdrawal of",
  "home.title.notice": "Notice",
  "home.title.patener": "In partnership with",
  "login.rememberPassword": "Remember the password",
  "page": {
    "cart": "Cart",
    "order": "Order",
    "financing": "Financing",
    "goods": "Goods",
    "levelUpgrade": "Level Upgrade",
    "PlatformIntroduction": "Platform Introduction",
    "RuleDescription": "Rule Description",
    "AgentCooperation": "Agent Cooperation",
    "CommonProblem": "Common Problem",
    "MemberUpgrade": "Member Upgrade",
    "login": "Login",
    "forgot": "Forgot",
    "registrasi": "Registrasi",
    "record": "Record",
    "Recharge": "Submit",
    "inviteFriends": "Invite Friends",
    "Withdraw": "Withdraw",
    "Service": "Service",
    "DialoguePage": "Dialogue Page",
    "user": "Personal",
    "RansferIn": "RansferIn",
    "PersonalCenter": "Personal Center",
    "Portrait": "Portrait",
    "Name": "Name",
    "Account": "Account",
    "Password": "Password",
    "PaymentCode": "PaymentCode",
    "Site": "Site",
    "AccountData": "AccountData",
    "RechargeRecord": "Top-up Record",
    "WithdrawalRecord": "Withdrawal Record",
    "InviteFriends": "Invite Friends",
    "Announcement": "Announcement",
    "TheTeamReport": "The Team Report",
    "BillRecord": "Bill Record",
    "BankCardAdd": "Bank Card Add",
    "NewRecharge": "Top-up",
    "RechargeList": "Top-up List",
    "newWithdraw": "Withdraw",
    "withdrawList": "withdraw List",
    "SettingPayPwd": "Setting Paypass",
    "MakeMoney": "Make Money",
    "team": "team",
    "qrPay": "qrcode-pay",
    "bankPay": "bank-pay",
    "aboutus": "About Us",
    "financial": "Funds",
    "financialRecord": "Financial Record",
    "TransferInto": "Transfer Into",
    "TransferOut": "Transfer Out",
    "CommissionRecord": "Profit",
    "Security": "Security",
    "Address": "Address",
    "Message": "Message",
    "InviteTask": "InviteTask",
    "Notice": "Notice",
    "modifyPaymentPassword": "Modify payment password"
  },
  "copyLink": "Copy Link",
  "teamReport": {
    "title": "Team Report",
    "subTitle": "Subordinate team overview",
    "taskTitle": "Invite {num} people to sign up and complete the order",
    "bonus": "Bonus",
    "Unfinished": "Unfinished",
    "Getted": "Getted"
  },
  "copy": "Copy",
  "address": {
    "name": "Name",
    "inputPlaceholder": "Please fill in",
    "phone": "Phone",
    "areaPlaceholer": "Delivery address infomation",
    "submit": "Save"
  },
  "password.old": "old password",
  "password.old.placeholder": "enter your old password",
  "password.confirmPassword": "confirm password",
  "password.confirmPassword.placeholder": "enter new password again",
  "hint.passwordDiff": "The two passwords do not match",
  "loginType": {
    "phone": "Phone",
    "email": "Email"
  },
  "Login.Daftar.email": "Email",
  "Login.emailInput": "Enter your email",
  "My.PersonalInformation.Password.email": "email",
  "My.PersonalInformation.Password.NewEmail": "Please enter your cell email",
  "mine.serviceTools.certify": "Verification",
  "Certification": {
    "type": {
      "id": "Identidade ( RG )",
      "driver": "Driver's License",
      "passport": "Passport"
    },
    "name": "Name",
    "license": "License number",
    "upload": "Document Attachment",
    "submit": "Submit",
    "incompleteInformation": "Please fill in the information and try again!",
    "submitSuccess": "The certification information has been submitted, please wait for the review!",
    "photo": "ID Photo",
    "review": "under review",
    "verified": "verified"
  },
  "upload": {
    "uploading": "uploading...",
    "failed": "upload failed"
  },
  "login.forgetPassword": "Forgot your password, please contact customer service!",
  "newAdd.BankNum.placeholder": "Example: 09XXXXXXXXX",
  "page.certification": "IDENTITY VERIFICATION",
  "newAdd.page": "page",
  "newAdd.record": "record",
  "emailFormatError": "Please enter the correct email address!",
  "urlParsePlaceholder": "Please enter paste identification",
  "btn.Identify": "Identify immediately",
  "produceStatus.confirming": "To be confirmed",
  "produceStatus.paying": "To be paid",
  "produceStatus.paid": "Paid",
  "produceStatus.done": "Done",
  "produceStatus.cancle": "Refunded",
  "order.old": "High income order",
  "order.new": "Regular order",
  "welfareDialogTitle": "System notification",
  "viGrabDesces": {
    "1": "Every order can be selected as a lucky order and the commission will be increased to {rate}%. Please contact your outreach mentor to apply for further outreach assignments",
    "2": "Help Shopee sellers complete virtual order creation, increase visibility and earn commissions",
    "3": "Please contact your promotion tutor as soon as possible after receiving the order, submit the order data and complete the task",
    "4": "The number of tasks is limited, please pay attention to the promotion suggestions of the instructor when accepting orders",
    "5": "The refund amount will be issued within 5-10 minutes after completing the task. If you have any questions, please consult an expert or online customer service.",
    "6": "Complete the tasks assigned by Shopee, improve the VIP level, and get higher commissions for Fackbook tasks",
    "7": "Accumulatively complete designated Shopee tasks to get additional commissions. For specific rewards, please consult the promotion specialist or online customer service"
  },
  "expectedEarningsTitle": "Expected earnings",
  "rechargeDesc": {
    "nav": "Recharge description",
    "p1": "now we have a lot of employees completing tasks at the same time.",
    "p2": "One seller cannot provide enough accounts",
    "p3": "so we post our seller account in another blank",
    "p4": "when you need to complete the task you can contact sales support to register your payment channel"
  },
  "chat": {
    "list": {
      "nav": "Message",
      "hours": "{h} hours",
      "minutes": "{m} minutes",
      "ago": "{time} ago",
      "image": "image"
    },
    "room": {
      "msgPlaceholder": "Enter a message...",
      "send": "Send",
      "sender": "Sender",
      "sendTime": "Send time",
      "content": "Content"
    }
  },
  "Login.Daftar.selectCurrency": "Select currency",
  "mine.totalBalance": "Total balance",
  "newBank": {
    "usdt": {
      "name": "Name",
      "currency": "Currency",
      "chainType": "Chain type",
      "depositAddress": "Deposit address",
      "submit": "submit"
    },
    "walletList": {
      "pageTitle": "Withdrawal management",
      "name": "Wallet alias:",
      "coinType": "Currency: ",
      "chainType": "Chain type:",
      "walletAddress": "Address:",
      "addDate": "date:",
      "btnText": "Add wallet address",
      "deleteConfirmText": "Are you sure you want to delete the wallet address?",
      "btnConfirm": "Delete",
      "btnCancle": "Cancle",
      "successHint": "successful"
    },
    "withdrawNum": "Number of withdrawals",
    "AllOut": "All",
    "balance": "Overall balance",
    "handlingFee": "Handling fee",
    "actualAmount": "Actual amount"
  },
  "recharge.status.error": "Data verification failed, please submit the correct hash value and data amount,",
  "page.checkin": "Check in",
  "checkin.submit": "Check in",
  "checkin.success": "Sign in successfully",
  "checkin.fail": "Sign in failed",
  "todayChecked": "You have signed in, please do not repeat the operation!",
  "submit.checked": "signed in",
  "hint.inviteBanned": "Sorry, your account has not yet opened a family account, please contact customer service or your mentor to apply for opening!",
  "newTab.blindBox": "blind box",
  "blindBox.submit": "Start lottery",
  "blindBox.times": "Opportunity to open a chest: {__times__} times",
  "blindBox.records.title": "my winning record",
  "banEditBankHint": "The bank card is important information, please contact customer service if you need to modify it!",
  "level.order_num": "Cryptocurrency commission",
  "drawNum": "Number of blind boxes",
  "recharge.good_title": "Product",
  "recharge.blindBoxIncome": "Blind Box Revenue",
  "recharge.amount": "Amount",
  "recharge.bonusRatio": "Bonus ratio",
  "submit": {
    "desc": {
      "1": "After completing the event product, you will get a blind box draw bonus opportunity, and the bonus range is 10-50% of the product value.",
      "sub_1": "For example, if you pay an order amount of R$3000, the blind box bonus ratio is 10%-50%, and the actual bonus range is R$300-1500, which means that your minimum withdrawal is R$3300 and the maximum withdrawal is R$4500.",
      "2": "Based on LBS technology, the system automatically matches products and blind box lottery opportunities through the cloud.",
      "3": "In order to prevent platform supervision, if the order is not confirmed and submitted within 30 minutes, the system will freeze the order amount.",
      "note": "Please read the relevant content carefully:",
      "note_sub": "After matching the order for the user, Shopee will submit the order information to the merchant background. If the user does not submit an order within 30 minutes, the order amount will be frozen by the system. In order to avoid system supervision, please accept after confirmation, all tasks cannot be canceled or replaced after acceptance! If there are other problems that need to be dealt with, please click to stop placing an order in time to avoid overtime freezing, thank you for your support."
    }
  },
  "drawNine": {
    "times": "Chance to open treasure chest",
    "submit": "blind box bonus",
    "percentHint": "100% winner"
  },
  "hint.nullOfDraw": "You have no chance to draw",
  "hint.loginNotRead": "Please read the agreement and try again!",
  "pages.editPhone": "phone number modification",
  "editPhone": {
    "label": "telephone number",
    "placeholder": "telephone number",
    "hint": "Please enter your phone number"
  },
  "Button.submit": "Submit",
  "pages.editEmail": "Bind email",
  "users.email": "E-mail",
  "users.userphone": "Telephone",
  "users.username": "User name",
  "editEmail": {
    "label": "E-mail",
    "placeholder": "E-mail",
    "hintRequire": "Please enter email address",
    "hintCorrect": "please enter your valid email"
  },
  "personal.page.email": "E-mail",
  "personal.page.phone": "Telephone",
  "banEditPhone": "The phone number is important information, if you need to modify it, please contact customer service!",
  "banEditEmail": "The email is important information, if you need to modify it, please contact customer service!",
  "banEditUserInfo": "Important information cannot be modified, if you need to modify, please contact customer service!",
  "bannerText1": "{name} complete {money} amount task to get {money1} commission",
  "bannerText2": "Congratulations {name} Select the premium task to get 30%commission",
  "grap": {
    "btask": "Bonus Duty",
    "wtask": "Work Duty"
  },
  "hashPlaceholder": "Please enter Hash",
  "recharge.actualAmount": "Actual account",
  "login.userAgreement": "User Agreement",
  "NetWork": "Network",
  "tailNum": "tail number",
  "label.price": "Price",
  "label.id": "ID",
  "label.level": "Level",
  "login.title1": "Sign up",
  "login.title2": "Welcome back!",
  "registe.title1": "Sign up",
  "registe.title2": "Welcome back!",
  "mine.credit": "Credit",
  "thirdRecharge.hint.noGoods": "Please parse the item first and try again!",
  "hint.onlyAgentInvite": "Family account not opened.",
  "newTab.p2p": "P2P",
  "p2p": {
    "trade_method": {
      "buy": "Buy",
      "sell": "Sell"
    },
    "coinList": {
      "orders": "{__} order(s)",
      "label": {
        "amount": "Amount",
        "price": "Price",
        "limit": "Limit"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "How many {__} do you want to sell?",
      "trade_title_buy": "How many {__} do you want to buy?",
      "submit_now": "{__} Now"
    },
    "orderDetails": {
      "sellTitle": "Awaiting Payment from the Other Party",
      "buyTitle": "Awaiting Your Payment",
      "sellHint": "The other party must complete the payment within {__}, otherwise the order will be automatically canceled.",
      "buyHint": "Complete the payment within {__}. After you complete the payment, click on contact seller",
      "payInfo_title": "Payment Infomation",
      "label": {
        "totalAmount": "Total Amount",
        "pricePerUnit": "Price Per Unit",
        "quantity": "Quantity",
        "counterpaty": "Counterpaty",
        "payMethod": "Payment Method",
        "fullName": "Full Name",
        "email": "Email",
        "phone": "Phone Number"
      },
      "btn_service": "contact seller",
      "btn_sell": "Contact Buyer"
    },
    "limitHint": {
      "notEnough": "Failed order, seller doesn't have enough USDT",
      "less": "Failed orders, minimum trade limit {__}",
      "more": "Failed orders, maximum trade limit {__}"
    }
  },
  "copySuccess": "Copy successfully",
  "p2p.noMore": "No more",
  "tabbar.shop": "shopping",
  "newAdd.shopTitle": "Shopping Cart",
  "shop": {
    "urlplaceholder": "Please add product link",
    "uploadText": "Upload shopping screenshots",
    "urlNull": "Please add product link",
    "uploadNull": "Please add shopping screenshots",
    "btn": "submit",
    "money": "+{__}balance",
    "point": "+{__}integral",
    "moneyPoint": "+{money}balance\n+{point}integral",
    "order": {
      "orderNo": "Order Number:",
      "link": "Link:",
      "screenshot": "Screenshot",
      "title": "Historical commits"
    }
  },
  "pullRefresh": {
    "pulling": "Pull down to refresh...",
    "loosing": "Release to refresh...",
    "loading": "loading...",
    "success": "Loading completed"
  },
  "listStatus": {
    "all": "All",
    "progress": "In progress",
    "done": "Completed",
    "error": "Data request failed",
    "cancel": "Cancel",
    "won": "Won"
  },
  "selectCurrenycy": "Select Currency"
}