<template>
  <div class="tabbar_wrap">
    <div class="placeholder">
      <router-view />
    </div>
    <keep-alive>
      <!-- verConfig.tabbar.selectedColor ||  -->
      <Tabbar v-model="active" active-color="var(--themes)"
        :inactive-color="theme == 'dark' ? 'rgba(255,255,255,.6)' : 'rgba(0,0,0,.6)'" route>
        <TabbarItem v-for="(item, index) in verConfig.tabbar.pages" :key="index" :name="index"
          :to="`/tabbar/${item.pagePath}`">
          <span :class="item.isSpecial ? 'textClass' : lan== 'ar' ? 'lanSize':''">{{ $t(item.text) }}</span>
          <template #icon="props">
            <!-- <div class="icons" v-if="item.isSpecial">
              <img class="img" :src="props.active ? item.selectedIconPath : item.iconPath" alt="" />
            </div> -->
            <div class="imgs" v-if="item.type == 'image'">
              <img class="img"
                :src="props.active ? require('@/assets/newTabbar/' + item.selectedIconPath + '_' + theme + '.png') : require('@/assets/newTabbar/' + item.iconPath + '_' + theme + '.png')"
                alt="" />
            </div>
            <i class="iconfont" v-else :class="item.selectedIconPath
              ? props.active
                ? item.selectedIconPath
                : item.iconPath
              : item.iconPath
              "></i>
          </template>
        </TabbarItem>
      </Tabbar>
    </keep-alive>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { mapGetters } from "vuex";
export default {
  components: {
    Tabbar,
    TabbarItem,
  },
  data: () => ({
    // active: 0,
  }),
  created() { },
  methods: {
    handleClick() { },
  },
  computed: {
    ...mapGetters(["theme"]),
    active: {
      get() {
        return this.$store.state.tabbar.active;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
};
</script>

<style lang="less" scoped>
@bg: var(--bg);

.tabbar_wrap {
  background: @bg;

  /deep/ .van-tabbar {
    max-width: 750px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background-color: @bg;
  }
}

.placeholder {
  margin-bottom: calc(50rem / 16);
}

.iconfont {
  font-size: calc(22rem / 16);
}

// .van-tabbar-item{
//   position: relative;
// }
.van-tabbar-item--active {
  background-color: transparent;
}

/deep/ .van-tabbar-item__icon {
  width: 100%;
}

.icons {
  position: absolute;
  top: calc(-30rem / 16);
  left: 0;
  right: 0;
  z-index: 10;
  width: calc(50rem / 16);
  height: calc(50rem / 16);
  border-radius: 50%;
  background-color: var(--bg);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px var(--bg);

}

.img {
  height: calc(38rem / 16);
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgs {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: calc(25rem / 16);
  }
}

.textClass {
  margin-top: calc(22rem / 16);
  display: block;
}
.lanSize{
  font-size: 14px;
}
</style>