<template>
  <div id="app" :class="'theme_' + theme" :style="{
    '--font': isApple
      ? `'Myriad', 'Bogle', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'`
      : 'Myriad',
    'fontSize': lan == 'ar' ? 'calc(16rem / 16)':''
  }">
    <!-- '--theme': verConfig.theme || '',
    '--nav-background': navBg || '',
    '--bg': '#201F24',
    '--btnColor': verConfig.btnColor || '',
    // '--bg': '#424242',
    '--sizeColor': '#fff',
    '--greenColor': '#07c160',
    '--blueColor': '#00F0FF', -->
    <router-view />
    <WelfareDialog ref="dialogRef" v-model="welfareDialogShow" :content="welfareDialogContent" :msgId="messageInfo.id"
      @read="read" :next="nextmsg" />
    <Floatball :size="60" @click="toChatroom" v-if="showFloatBall">
      <div class="float_ball_wrap">
        <img src="./assets/icon/float_ball_msg.png" alt="" />
      </div>
    </Floatball>
    <div v-show="false">{{ systemConfig }}</div>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
import Tabbar from "./components/newTabbar";
import Floatball from "./components/floatBall.vue";
import WelfareDialog from "./components/welfareDialog.vue";
import { $get, $post } from "@/utils/request";
import qs from "qs";
import { mapGetters } from "vuex";
import { cookie } from "./utils/tools";
import { generateRandomString,baseEncode,setCookieValue,getCookieValue } from "@/utils/common";
export default {
  data() {
    return {
      active: 0,
      welfareDialogShow: false,
      welfareDialogContent: "",
      messageInfo: {},
      msgList: [],
      nextmsg: false,
      showFloatBall: false,
      thirdToken: "",
      isApple: false,
    };
  },
  components: {
    Tabbar,
    WelfareDialog,
    Floatball,
  },
  created() {
    // if (!localStorage.getItem('user-info')) {
    //   if (this.$router.history.current.name !== 'login') {
    //     // this.$router.replace('login')
    //     this.$router.push('login')
    //   }
    // }
    if(!getCookieValue('channel')){
      setCookieValue('channel',baseEncode());
    }
    const arrs = window.location.href.split('code=');
    if (arrs[1]) {
      const arrValue = arrs[1] && arrs[1].split('&platform=')
      this.$store.dispatch('GETINIT', arrValue[0] || '');
      this.$store.dispatch('GETObj', { platform: arrValue[1] || { platform: '' } })
    }
    const version =
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    this.isApple = version;
    sessionStorage.setItem("version-dialog-show", true);
    if (!localStorage.getItem("version")) {
      localStorage.setItem("version", "1.0.7");
    }
    if (!cookie.get('theme')) {
      this.$store.dispatch('setTheme', 'light')
    }
  },
  computed: {
    navBg() {
      const navBGConfig = this.verConfig.navBg;
      if (Array.isArray(navBGConfig)) {
        return `linear-gradient(to ${this.verConfig.navBGToBottom ? "bottom" : "right"
          }, ${navBGConfig[0]}, ${navBGConfig[1]})`;
      }
      return navBGConfig;
    },
    ...mapGetters(["systemConfig", "theme"]),
  },
  methods: {
    toChatroom() {
      this.$router.push("/ChatList");
    },
    onChange() {
    },
    goto(path = ``) {
      this.$router.push(path);
    },

    read(next = false, nextParams = {}) {
      if (nextParams && Object.keys(nextParams).length)
        this.messageInfo = nextParams;

      // this.msgList.shift();
      if (!!this.msgList[0].nextmsg && !next) {
        this.welfareDialogContent = this.msgList[0].nextmsg;
        if (!next) {
          this.nextmsg = true;
        } else {
          this.nextmsg = false;
        }
        this.welfareDialogShow = true;
      }
      // if (this.msgList.length) {
      //   this.welfareDialogContent = this.msgList[0].content;
      //   this.messageInfo = this.msgList[0];
      //   this.welfareDialogShow = true;
      // }
    },
    async getMessage() {
      Toast.loading();
      try {
        const res = await $get("/users/message", {
          perPage: 999,
          status: "unread",
          notify: 1,
        });
        Toast.clear();
        const { ret, msg, data } = res.data;
        if (!data.total) return;
        if (!!data.lists.length) {
          this.welfareDialogShow = true;
          if (!!this.messageInfo.id && this.messageInfo.id == data.lists[0].id)
            return;
          this.welfareDialogContent = data.lists[0].content;
          this.messageInfo = data.lists[0];
          this.msgList = data.lists;
        }
      } catch (error) {
        Toast.clear();
      }
    },
  },
  watch: {
    $route(to, from) {
      const pages = this.verConfig.tabbar
        ? this.verConfig.tabbar.pages || []
        : [];
      const currentPage = this.$route;
      const currentPath = currentPage.path;
      const pathArr = currentPath.split("/");
      const pageIndex = pages.findIndex(
        (item) => item.pagePath === pathArr[pathArr.length - 1]
      );
      // this.$store.commit("SET_ACTIVE", pageIndex);
      /** -----------------------上面是tabbar---------------------------- */
      const token = localStorage.getItem("token");
      if (
        to.path == "/ChatList" ||
        to.path == "/ChatRoom" ||
        !token ||
        !this.verConfig.hasChat
      ) {
        this.showFloatBall = false;
      } else {
        this.showFloatBall = true;
      }
      if (
        (to.path === "/registrasi") |
        (to.path === "/login") |
        !localStorage.getItem("token")
      )
        return;
      this.getMessage();
    },
    systemConfig(val) {
      if (!val.appurl_android) return;
      const { v } = qs.parse(val.appurl_android.split("?")[1]);
      if (v > this.verConfig.appVersion) {
        Dialog.alert({
          title: "",
          message: this.$t("Hint.updateText"),
          // cancelButtonText: this.$t("Button.cancle"),
          confirmButtonText: this.$t("Button.confirm"),
        })
          .then(() => {
            // on confirm
            location.href = val.appurl_android;
          })
          .catch(() => {

          });
      }
    },
  },
};

window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>
<style>
#app {
  width: 100%;
  max-width: 750px;
  height: 100%;
  margin: 0 auto;
}

* {
  /* font-family: 'Bogle','Helvetica Neue','Helvetica','Arial','sans-serif'; */
  /* font-family: 'Myriad' !important; */
  letter-spacing: 1px;
  font-weight: 400;
}
@font-face {
  font-family: own-font-rg;
  src: url("./assets/fonts/AmazonEmber_Rg.ttf");
}

@font-face {
  font-family: own-font-th;
  src: url("./assets/fonts/AmazonEmber_Th.ttf");
}

@font-face {
  font-family: own-font-normal;
  src: url("./assets/fonts/AmazonEmber_Lt.ttf");
}

@font-face {
  font-family: own-font-blod;
  src: url("./assets/fonts/AmazonEmber_Bd.ttf");
}
@font-face {
  font-family: Arial;
  src: url("./assets/fonts/Arial.ttf");
}


*:not([class*="icon"]):not([class*="iconfont"]):not(.fa):not(.fas):not(i) {
  font-family: var(--font, "Myriad");
}

body {
  font-size: 16px;
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  /* font-family: Arial; */
}

/* :root {
  --theme: #1e7eee;
  --nav-background: linear-gradient(to right,
      rgb(109, 202, 184),
      rgb(58, 120, 203));
  --trade-green: #01BD8D;
  --trade-red: #F55555;
} */

.theme_dark {
  --themes: #FFD700;
  --theme: #FFD700;
  --nav-background: #000;
  --bg: #201F24;
  --btnColor: #424242;
  --btnsizeColor: #fff;
  --sizeColor: #fff;
  --greenColor: #07c160;
  --blueColor: #00F0FF;
  --moduleColor: #FFD700;
  --marqueeColor: transparent;
  --marqueeItemColor: #fff;

  --newTheme: #1989fa;
  --newBtnBg: linear-gradient(90deg, #6eeed9 0%, #1989fa 100%);
  --newBtnSizecolor: #fff;
  --iconColor: #535353;
  --levelColor: #592b01;
}

.theme_light {
  --themes: #1989fa;
  --theme: #090100;
  --nav-background: #f7f8fc;
  --bg: #FFF;
  --btnColor: #1989fa;
  --btnsizeColor: #fff;
  --sizeColor: #1F1F1F;
  --greenColor: #07c160;
  --blueColor: #00F0FF;
  --moduleColor: #1989FA;
  --marqueeColor: #F1F3F4;
  --marqueeItemColor: #201F24;
  --newTheme: #1989fa;
  --newBtnBg: linear-gradient(90deg, #6eeed9 0%, #1989fa 100%);
  --newBtnSizecolor: #fff;
  --iconColor: #535353;
  --levelColor: #592b01;
  --newmoduleColor: #928bbd;
}

/* a {
  position: fixed;
  top: -1000px;
} */
.van-nav-bar {
  background: var(--nav-background);
  max-width: 750px;
  margin: 0 auto;
  left: 50% !important;
  transform: translateX(-50%);
}

.van-nav-bar .van-icon {
  /* color: #fff !important; */
  color: var(--theme) !important;
}

.van-nav-bar__content {
  background: var(--nav-background);
}

.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0;
}

.van-sticky--fixed {
  width: 100%;
  max-width: 750px;
  left: 50% !important;
  transform: translateX(-50%);
}

.van-nav-bar__title {
  /* color: #fff !important; */
  color: var(--theme) !important;
}

.wrap {
  min-height: 100vh;
  background-color: var(--nav-background);
  overflow: hidden;
  max-width: 750px;
}

.van-dialog {
  /* background-color: #201F24;

    .van-button--default {
      background-color: #201F24;
    }

    .van-dialog__header {
      color: #fff;
    }

    .van-dialog__message--has-title {
      color: #FFD700;
    }

    .van-dialog__cancel {
      color: #fff;
    }

    .van-dialog__confirm {
      color: #FFD700;
    } */

  [class*=van-hairline]::after {
    border-color: transparent;
  }
}
</style>
<style lang="less" scoped>
.float_ball_wrap {
  @border_size: 4px;
  border: @border_size solid #758fa0;
  background: linear-gradient(0deg, #0023ff 0%, #00b4ff 100%),
    linear-gradient(#074669, #074669);
  height: calc(var(--size) - @border_size * 2); // 这个size是来自floatBall组件的
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  >img {
    width: 60%;
    height: 60%;
  }
}
</style>
