import { INIT } from "@/api";
// import Cookies from "js-cookie";
import { cookie } from '@/utils/tools'
export const init = {
  state: {
    initData: {},
    urlObj:{}
  },
  getters: {
    initData(state) {
      return state.initData;
    },
    setUrlObj(state) {
      return state.urlObj
    }
  },
  mutations: {
    GETINIT(state, data) {
      console.log(data);
      state.initData = data
    },
    GETObj(state, data) {
      state.urlObj = data
    }
  },
  actions: {
    async GETINIT({ commit }, key) {
      try {
        const res = await INIT(key);
        cookie.set("userInfo", JSON.stringify(res.data))
        cookie.set("token", res.data?.token)
        commit('GETINIT', res.data)
      } catch (error) {
        commit('GETINIT', error)
        console.error(error, 'GETINIT');
      }
    },
    GETObj({ commit }, key) {
      commit('GETObj', key)
    }
  }
}