export const ver = 'T7386'
import { router } from "@/router"
import { Toast } from "vant"

export const verData = {
  T7386: {
    title: 'Walmart',
    loginIcon: "T7399_login_icon",
    moneyType: 'USDT',
    needBank: true,
    // areaCode: 966,
    areaCode:48,
    lan: 'ar',
    needCode: true,
    url: 'https://walapp.vip/',
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    allowedMoreCard: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    theme: "#FFD700",
    themes: 'dark',
    navBg: "#000",
    btnColor: '#424242',
    noticeIconColor: "#000",
    selectCurrency: true,
    homeShowTradeBar: true,
    homeShowAboutBar: true,
    useIngegrante: true,
    homeShowInvite: true,
    mineHasCommissionRecords: true,
    hasManyBank: true,
    tabbar: {
      selectedColor: "#FFD700",
      // type: "image",
      pages: [
        {
          type: 'image',
          text: 'newTab.home',
          pagePath: 'goods',
          component: () => import("@/view/goods"),
          // iconPath: "icon-shouye",
          // selectedIconPath: "icon-shouye-dianjihou",
          iconPath: 'home',
          selectedIconPath: 'home_active',
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'page.Recharge'}`,
          pagePath: 'third-recharge-n',
          component: () => import("@/view/bank/thirdRechargeNew"),
          // iconPath: "icon-chongzhi",
          // selectedIconPath: "icon-tixianchongzhi",
          iconPath: 'submit',
          selectedIconPath: 'submit_active',
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'tab.make_money'}`,
          pagePath: 'make-money',
          component: () => import('@/view/new/make-money'),
          // iconPath: "icon-tedianshandianfahuo",
          // selectedIconPath: "icon-a-28Cdianji",
          // iconPath: require('@/assets/Img/tabber/tabbar_3.gif'),
          // selectedIconPath: require('@/assets/Img/tabber/tabbar_3.gif'),
          iconPath: 'grab',
          selectedIconPath: 'grab_active',
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'newTab.order'}`,
          pagePath: 'cart',
          component: () => import('@/view/cart'),
          // iconPath: "icon-dingdanyichenggong",
          // selectedIconPath: "icon-complete-fill",
          iconPath: 'order',
          selectedIconPath: 'order_active',
          isSpecial: false,
        },
        // {
        //   type: 'image',
        //   text: 'tabbar.shop',
        //   // pagePath: 'Submit',
        //   pagePath: 'shop',
        //   component: () => import('@/view/shop/index'),
        //   iconPath: 'shopping',
        //   selectedIconPath: 'shopping_active',
        //   isSpecial: false,
        // },
        // {
        //   text: 'newTab.p2p',
        //   pagePath: 'p2p',
        //   component: () => import('@/view/p2p'),
        //   iconPath: "icon-tubiao-",
        //   selectedIconPath: "icon-zhuanqian",
        //   isSpecial: false,
        // },
        {
          type: 'image',
          text: `${'newTab.my'}`,
          pagePath: 'user',
          component: () => import('@/view/user'),
          // iconPath: "icon-wode",
          // selectedIconPath: "icon-wodexiao",
          iconPath: 'my',
          selectedIconPath: 'my_active',
          isSpecial: false,
        },
      ]
    },
    nullPng: require("@/assets/Img/null/T7399.png"),
    unreadBg: "#fcc400",
    unreadColor: "#000",
    homeTheme: "#0073e4",
    loginSetLans: true,
    mineShowAboutBar: true
  },
  T7386_test: {
    title: 'Walmart',
    loginIcon: "T7399_login_icon",
    moneyType: 'USDT',
    needBank: true,
    areaCode: 966,
    lan: 'ar',
    needCode: true,
    url: 'https://gt7386.gtzs.cc/',
    bankInput: true,
    vipHide: false,
    bankCardLimit: 11,
    levelType: 'recharge',
    newRecharge: true, // 新版本修改后新增参数
    useNewMine: true,
    allowedMoreCard: true,
    pathNewRecharge: true, // 跳转新的充值页面
    homeProfitInterval: [10, 5000],
    homeProfitIntervalMax: [11500, 11999],
    useNewWithdraw: true,
    theme: "#FFD700",
    themes: 'dark',
    navBg: "#000",
    btnColor: '#424242',
    noticeIconColor: "#000",
    selectCurrency: true,
    homeShowTradeBar: true,
    homeShowAboutBar: true,
    useIngegrante: true,
    homeShowInvite: true,
    mineHasCommissionRecords: true,
    hasManyBank: true,
    tabbar: {
      selectedColor: "#FFD700",
      // type: "image",
      pages: [
        {
          type: 'image',
          text: 'newTab.home',
          pagePath: 'goods',
          component: () => import("@/view/goods"),
          // iconPath: "icon-shouye",
          // selectedIconPath: "icon-shouye-dianjihou",
          iconPath: 'home',
          selectedIconPath: 'home_active',
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'page.Recharge'}`,
          pagePath: 'third-recharge-n',
          component: () => import("@/view/bank/thirdRechargeNew"),
          // iconPath: "icon-chongzhi",
          // selectedIconPath: "icon-tixianchongzhi",
          iconPath: 'submit',
          selectedIconPath: 'submit_active',
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'tab.make_money'}`,
          pagePath: 'make-money',
          component: () => import('@/view/new/make-money'),
          // iconPath: "icon-tedianshandianfahuo",
          // selectedIconPath: "icon-a-28Cdianji",
          // iconPath: require('@/assets/Img/tabber/tabbar_3.gif'),
          // selectedIconPath: require('@/assets/Img/tabber/tabbar_3.gif'),
          iconPath: 'grab',
          selectedIconPath: 'grab_active',
          isSpecial: false,
        },
        {
          type: 'image',
          text: `${'newTab.order'}`,
          pagePath: 'cart',
          component: () => import('@/view/cart'),
          // iconPath: "icon-dingdanyichenggong",
          // selectedIconPath: "icon-complete-fill",
          iconPath: 'order',
          selectedIconPath: 'order_active',
          isSpecial: false,
        },
        // {
        //   type: 'image',
        //   text: 'tabbar.shop',
        //   // pagePath: 'Submit',
        //   pagePath: 'shop',
        //   component: () => import('@/view/shop/index'),
        //   iconPath: 'shopping',
        //   selectedIconPath: 'shopping_active',
        //   isSpecial: false,
        // },
        // {
        //   text: 'newTab.p2p',
        //   pagePath: 'p2p',
        //   component: () => import('@/view/p2p'),
        //   iconPath: "icon-tubiao-",
        //   selectedIconPath: "icon-zhuanqian",
        //   isSpecial: false,
        // },
        {
          type: 'image',
          text: `${'newTab.my'}`,
          pagePath: 'user',
          component: () => import('@/view/user'),
          // iconPath: "icon-wode",
          // selectedIconPath: "icon-wodexiao",
          iconPath: 'my',
          selectedIconPath: 'my_active',
          isSpecial: false,
        },
      ]
    },
    nullPng: require("@/assets/Img/null/T7399.png"),
    unreadBg: "#fcc400",
    unreadColor: "#000",
    homeTheme: "#0073e4",
    loginSetLans: true,
    mineShowAboutBar: true
  }
}

export default verData[ver]
